import icons from '../assets/images/icons';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../components/common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import { dialogAlert, ToastAlert } from './fnDialogs';
import BackendErrorDialog from '../components/common/Dialog/BackendErrorDialog';
import { EMPTY_WORD_STRING } from './Globals';

export const renderAdminLockIcon = () => {
    const toolTipText = `The object is currently locked and can’t be edited. Contact the Tenant Admin to unlock it.`;
    return renderTooltip(
        <SVGInline key={`locked`} width={10} src={icons.adminLockRed} />,
        tooltipTypes.TEXT,
        toolTipText || '',
        tooltipPositions.TOP
    );
};

export const renderAdminLockedWarningAlert = (objectName: string) => {
    const values = {
        title: 'Object is locked',
        text: (
            <>
                It looks like <strong>”{objectName || EMPTY_WORD_STRING}”</strong> is locked and can’t be edited. Contact the Tenant Admin
                to unlock it.
            </>
        )
    };

    return ToastAlert('critical_warning', values.title, values.text, icons.adminLockAlert, undefined, () => {
        dialogAlert('', false, values, null, false, icons.adminLockAlert, null, true);
    });
};

export const renderAdminLockedError = (error: any) => {
    const values = {
        title: 'Object is locked',
        text: (
            <>
                It looks like <strong>”{error.data?.name || EMPTY_WORD_STRING}”</strong> is locked and can’t be edited. Contact the Tenant
                Admin to unlock it.
            </>
        )
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};

export const renderAdminLockedListActionError = (error: any) => {
    const values = {
        title: 'Delete multiple objects is not allowed!',
        text: 'You are not able to delete this list because there are admin locked objects within it. Please exclude the admin locked objects before deleting the list!'
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};
