import { TableBody, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { useNavigate } from 'react-router';
import icons from '../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { PermissionsState, setUserPermissions } from '../../../redux/slices/permissionsSlice';
import {
    createUser,
    deleteUser,
    disableUser,
    enableUser,
    fetchUsers,
    inviteUser,
    updateUser,
    UsersState
} from '../../../redux/slices/usersSlice';
import { MainContentWrapper } from '../../../style/styled-components/reusable.css';
import { User, userStates } from '../../../types/User';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../../utils/fnDialogs';
import { DEFAULT_SORT_CONFIG, ISortConfig } from '../../../utils/fnSort';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../../common/Table/Table';
import { ProjectPreviewForDeletion } from '../../Projects/Projects.css';
import NewUserDialog from './Dialogs/NewUser';
import { TenantAdminLabel, TenantIcon, TenantRow, UserDescription, UserFullName, UserName, UserRow, UserStatus } from './Users.css';
import { API_ERROR_CODES, USERROLES } from '../../../utils/Globals';
import { Loader } from '../../common/Loader/Loader';
import useScreenSize from '../../../hooks/useScreenSize';
import { WidthTableCell } from '../../common/Table/Table.css';
import { PageRoutes } from '../../../types/RouteTypes';
import { TenantName } from '../Groups/Groups.css';
import { UsersTableSizes } from '../../../types/TableSizes';
import Labels from '../../common/Labels/Labels';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';

export const Users: React.FC<{ onGroupNameClick: any }> = ({ onGroupNameClick }) => {
    const { users: storeUsers, error, loading }: UsersState = useAppSelector((state) => state.users);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { userProfile } = useAppSelector((state) => state.profile);

    const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);
    const [userToEdit, setUserToEdit] = useState<User | null>(null);
    const [users, setUsers] = useState<User[]>([]);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<ISortConfig | undefined>(undefined);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const orderBy = sortConfig && `${sortConfig.field}[${sortConfig.direction}]`;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile, isDesktop } = useScreenSize();

    const states = [
        {
            text: 'Resend',
            icon: icons.inactiveIcon
        },
        {
            text: 'Active',
            icon: icons.activeIcon
        },
        {
            text: 'Disabled',
            icon: icons.disabledIcon
        }
    ];

    useEffect(() => {
        loadUsers(true, orderBy, searchTerm).then((response) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
    }, []);

    useEffect(() => {
        if (loading || error) return;
        if (storeUsers) {
            setUsers(storeUsers);
        }
    }, [loading, storeUsers]);

    const loadUsers = async (addPermissions?: boolean, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchUsers({ addPermissions, orderBy, searchTerm })).unwrap();
    };

    const removeUser = async (id: string) => {
        await dispatch(deleteUser(id)).unwrap();
        loadUsers(false, orderBy, searchTerm);
    };

    const addUser = async (user: User) => {
        await dispatch(createUser(user)).unwrap();
        loadUsers();
        setSearchTerm(undefined);
        setActiveSortingKey(null);
        setSortConfig(DEFAULT_SORT_CONFIG);
    };

    const modifyUser = async (user: User) => {
        await dispatch(updateUser(user)).unwrap();
        loadUsers(false, orderBy, searchTerm);
    };

    const deactivateUser = async (id: string) => {
        await dispatch(disableUser(id)).unwrap();
        loadUsers(false, orderBy, searchTerm);
    };
    const activateUser = async (id: string) => {
        await dispatch(enableUser(id)).unwrap();
        loadUsers(false, orderBy, searchTerm);
    };

    const sendUserActivationEmail = async (id: string) => {
        const result = await dispatch(inviteUser(id)).unwrap();
        if (result.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.INVITE_USER);
        }
    };

    const onCloseDialog = () => {
        setOpenAddUserDialog(false);
        setUserToEdit(null);
    };

    const onSaveDialog = (user: User) => {
        if (userToEdit) {
            modifyUser(user);
        } else {
            addUser(user);
        }
        setOpenAddUserDialog(false);
        setUserToEdit(null);
    };

    const onDeleteUser = (id: string) => {
        const user = users.find((user) => user._id === id);
        const values = {
            title: 'Delete User',
            text: (
                <>
                    <p>
                        You are in the process of deleting the following user <strong>{user?.fullName}</strong>. Are you sure you want to
                        permanently delete the selected user?
                    </p>
                    <p>Are you sure you want to delete?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                removeUser(id);
            },
            values,
            <ProjectPreviewForDeletion>
                <p>{user?.fullName}</p>
            </ProjectPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            }
        );
    };

    const toggleUserState = (state: userStates, id: string) => {
        const user = users.find((user) => user._id === id);

        if (state === userStates.DISABLED) {
            const values = {
                title: 'Activate User',
                text: (
                    <>
                        <div>
                            <strong>Are you sure you want to activate: </strong>
                        </div>
                        <span>{user?.fullName}</span>
                    </>
                )
            };
            dialogConfirm(
                '',
                () => {
                    activateUser(id);
                },
                values,
                null,
                {
                    noButton: {
                        label: 'Cancel'
                    },
                    confirmButton: {
                        label: 'Activate'
                    }
                },
                { successIcon: true }
            );
        } else {
            const values = {
                title: 'Disable User',
                text: (
                    <>
                        <div>
                            <strong>Are you sure you want to disable: </strong>
                        </div>
                        <span>{user?.fullName}</span>
                    </>
                )
            };
            dialogConfirm(
                '',
                () => {
                    deactivateUser(id);
                },
                values,
                null,
                {
                    noButton: {
                        label: 'Cancel'
                    },
                    confirmButton: {
                        label: 'Disable'
                    }
                },
                { failIcon: true }
            );
        }
    };

    const handleStateIconClick = (state: userStates, id: string) => {
        switch (state) {
            case userStates.INACTIVE:
                sendUserActivationEmail(id);
                break;
            case userStates.ACTIVE:
                toggleUserState(state, id);
                break;
            case userStates.DISABLED:
                toggleUserState(state, id);
                break;
            default:
                null;
        }
    };

    const handleOnSearch = (searchTerm: string) => {
        loadUsers(false, orderBy, searchTerm);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadUsers(false, orderBy, searchTerm);
    };

    const renderColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'User Name'}
                hideArrow={!showSortArrows && activeSortingKey !== 'fullName'}
                onClick={() => handleSortIconClick('fullName')}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={UsersTableSizes.fullName}
                direction={(sortConfig?.field === 'fullName' && sortConfig.direction) || 'asc'}
            />,
            <HeaderTableCell key={`tenant_cell`} text={'Tenant'} columnSize={UsersTableSizes.tenants} />,
            <HeaderTableCell key={'projects_cell'} text={'Project'} columnSize={UsersTableSizes.Project} />,
            <HeaderTableCell key={'users-group_cell'} text={'Users-Group'} columnSize={UsersTableSizes['User-Group']} />
        ];

        !isMobile && columns.unshift(<TableCell key={'first_cell_configurations'} />);
        return columns;
    };

    const buildTableBody = () => {
        const rows = users?.map((user, index) => {
            const { fullName, tenants, state, icon } = user;
            const actions = [tableActions.EDIT];
            if (user.role?.name !== USERROLES.SUPER_ADMIN) actions.push(tableActions.REMOVE);
            return (
                <TableRow key={index}>
                    {!isMobile && <ImageTableCell width={48} um={'px'} src={icon || icons.avatarIcon} shape="round" />}

                    {/* USERNAME TABLE CELL */}
                    <WidthTableCell {...UsersTableSizes.fullName}>
                        <UserName>
                            <UserDescription>
                                <UserFullName
                                    onClick={() => {
                                        if (user._id === userProfile._id) return; // User cannot edit him/herself
                                        setOpenAddUserDialog(true);
                                        setUserToEdit(user);
                                    }}
                                >
                                    {fullName}
                                </UserFullName>
                                {user.role?.name !== USERROLES.SUPER_ADMIN && (
                                    <UserStatus>
                                        {state === userStates.INACTIVE && 'Invite Sent'}
                                        <span
                                            onClick={() => {
                                                if (user._id === userProfile._id) return; // User cannot change state of him/herself
                                                handleStateIconClick(state, user._id);
                                            }}
                                        >
                                            {states[state].text}
                                        </span>
                                        <InlineSVG src={states[state].icon} />
                                    </UserStatus>
                                )}
                            </UserDescription>
                        </UserName>
                    </WidthTableCell>

                    {/* TENANTS TABLE CELL */}
                    <WidthTableCell {...UsersTableSizes.tenants}>
                        {tenants?.map((tenant) => {
                            return (
                                <UserRow key={tenant._id}>
                                    <TenantRow
                                        onClick={() => {
                                            navigate(PageRoutes.TENANTS, { state: { selectedTenantId: tenant._id } });
                                        }}
                                    >
                                        <TenantIcon>
                                            <img src={tenant?.logo || icons.tenantIcon} alt="" />
                                        </TenantIcon>
                                        <TenantName>{tenant?.name || 'Tenant name'}</TenantName>
                                    </TenantRow>
                                </UserRow>
                            );
                        })}
                    </WidthTableCell>

                    {/* PROJECTS TABLE CELL */}
                    <WidthTableCell {...UsersTableSizes.Project}>
                        {tenants?.map((tenant) => {
                            const projectsPerUser = user?.projects?.filter((project) => {
                                return project?.tenantId?.includes(tenant._id);
                            });

                            return (
                                <UserRow key={tenant._id}>
                                    {tenant.tenantAdminIds.includes(user._id) ? (
                                        <TenantAdminLabel>{isMobile ? 'Admin' : 'Tenant Admin'}</TenantAdminLabel>
                                    ) : (
                                        <Labels
                                            values={projectsPerUser || []}
                                            type={MoreInfoTypes.PROJECTS}
                                            noOfLabels={isDesktop ? 2 : 1}
                                            onClickLabel={(project) => {
                                                navigate(PageRoutes.PROJECTS, { state: { selectedProjectId: project._id } });
                                            }}
                                        />
                                    )}
                                </UserRow>
                            );
                        })}
                    </WidthTableCell>

                    {/* USER-GROUPS TABLE CELL */}
                    <WidthTableCell {...UsersTableSizes['User-Group']}>
                        {tenants?.map((tenant) => {
                            const groupsPerUser = user.userGroups?.filter((group) => {
                                return group?.tenantId?.includes(tenant._id);
                            });

                            return (
                                <UserRow key={tenant._id}>
                                    <Labels
                                        values={groupsPerUser || []}
                                        type={MoreInfoTypes.GROUPS}
                                        noOfLabels={isDesktop ? 2 : 1}
                                        onClickLabel={(group) => {
                                            onGroupNameClick && onGroupNameClick(group._id);
                                        }}
                                    />
                                </UserRow>
                            );
                        })}
                    </WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...UsersTableSizes.actions}>
                        <ActionsTableCell
                            actions={user._id !== userProfile._id ? actions : []} // The User cannot edit him/herself
                            onRemove={() => {
                                onDeleteUser(user._id);
                            }}
                            onEdit={() => {
                                setOpenAddUserDialog(true);
                                setUserToEdit(user);
                            }}
                            tooltipTexts={{ edit: 'users_icon_edit', delete: 'users_icon_delete' }}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    if (!loading && !userPermissions) {
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    return (
        <>
            {error && (
                <BackendErrorDialog
                    error={error}
                    customValues={
                        error.code === API_ERROR_CODES.UNAUTHORIZED_USER_UPDATE
                            ? { title: 'Unauthorized!', text: error.message }
                            : undefined
                    }
                />
            )}
            <MainContentWrapper>
                <ScreenTitle
                    loading={loading}
                    withoutSearch
                    title="Users"
                    withAddButton
                    withProfile
                    addLabel="Create User"
                    onAdd={() => setOpenAddUserDialog(true)}
                    circlesSlugOptions={{ default: CIRCLE_SLUGS.users, onboarding: ONBOARDING_CIRCLE_SLUGS.users }}
                />
                <SearchBarContainer>
                    <SearchBar
                        title={'Search'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'users_icon_search'}
                    />
                </SearchBarContainer>

                {loading ? (
                    <Loader title={'Users'} />
                ) : users.length > 0 ? (
                    <GenericTable body={buildTableBody()} columns={renderColumns()} />
                ) : (
                    'Users not loaded/non existent'
                )}
            </MainContentWrapper>
            <NewUserDialog
                open={openAddUserDialog}
                onClose={onCloseDialog}
                user={userToEdit}
                onSave={(user) => {
                    onSaveDialog(user);
                }}
            />
        </>
    );
};
