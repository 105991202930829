import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SidebarState = {
    sidebarOpen: boolean;
    engageSidebarOpen: boolean;
    subsectionOpen: string;
};

export const initialState: SidebarState = {
    sidebarOpen: true,
    engageSidebarOpen: true,
    subsectionOpen: ''
};

const slice = createSlice({
    name: 'sidebarOpen',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        toggleEngageSidebar: (state) => {
            state.engageSidebarOpen = !state.engageSidebarOpen;
        },
        setSubsectionOpen: (state, action: PayloadAction<string>) => {
            state.subsectionOpen = action.payload;
        },
        closeSideBar: (state) => {
            state.sidebarOpen = false;
        },
        openSideBar: (state) => {
            state.sidebarOpen = true;
        }
    }
});

export const { toggleSidebar, closeSideBar, openSideBar, toggleEngageSidebar, setSubsectionOpen } = slice.actions;
export default slice.reducer;
