import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { ObjectNameLabel, TruncatedText } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

// USERNAME TABLE CELL
export const UserName = styled.div``;

export const UserDescription = styled.div`
    width: 100%;
`;

export const UserFullName = styled(TruncatedText)`
    color: ${theme.palette.primary.main};
    ${fontSizesCSS.Body1};
    cursor: pointer;
`;

export const UserStatus = styled.div`
    color: ${theme.palette.text.main};
    ${fontSizesCSS.Caption3};

    span {
        color: ${theme.palette.primary.main};
        margin: 0 4px;
        cursor: pointer;
    }
`;

// TENANT TABLE CELL
export const UserRow = styled.div`
    margin: 16px 0;
    height: 28px;
    width: 100%;
`;

export const TenantRow = styled.div`
    ${fontSizesCSS.Caption1};
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 2px 0;
`;

export const TenantIcon = styled.div`
    width: 24px;
    height: 24px;

    img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
`;

export const TenantAdminLabel = styled(ObjectNameLabel)`
    border-color: ${theme.palette.error.main};
    color: ${theme.palette.error.main};
    height: 28px;
`;

export const RoleWrapper = styled.div`
    label {
        margin-top: -4px;
    }
`;
