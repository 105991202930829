import React, { useState } from 'react';
import {
    AbTestingGroupSelectWrapper,
    ButtonsWrapper,
    MobileButtonsWrapper,
    MobilePageButtons,
    pageOptionsButtonStyleDesktop,
    pageOptionsButtonStyleMobile,
    PagePreviewLabel,
    PageValuesWrapper,
    ToggleViewButton,
    ToggleViewButtonsWrapper,
    WarningContainer
} from './PageEdit.css';
import { DialogDropdownSingle, DialogTextField } from '../common/Dialog/GenericDialog';
import { icons } from '../../style';
import Button from '../Buttons/Button/Button';
import InlineSVG from 'react-inlinesvg';
import SVGInline from 'react-inlinesvg';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { useAppSelector } from '../../hooks/redux';
import { pagesState } from '../../redux/slices/pagesSlice';
import { PageIntents } from '../../types/Page';
import useScreenSize from '../../hooks/useScreenSize';
import { TargetConditionsSelect } from '../common/Select/TargetConditionsSelect';
import { WarningLabel, WarningLabelPlaceHolder, WarningLabelsWrapper, WarningTooltip } from '../TargetGroups/GroupEdit.css';
import { dialogAlert, DIALOG_NAMES } from '../../utils/fnDialogs';
import { ABTestingGroupSelect } from '../common/Select/ABTestingGroupSelect';
import { ButtonIcon } from '../Buttons/Button/Button.css';

export enum PAGE_VIEWS {
    EDITOR_VIEW,
    LIST_VIEW
}

export type pageValues = {
    name?: string;
    pageStyle?: string;
    targets?: string[];
    abTestingGroupIds?: string[];
    pageView: PAGE_VIEWS;
    intent: string;
};

type pageValuesProps = {
    onChange: (newValues: Record<keyof pageValues, any>) => void;
    initialValues: pageValues;
    pageStyles: any[];
    showAllIntents: boolean;
    handleCreateNewOption?: () => void;
    hasGalleryOrPreview?: boolean;
    withoutTargets?: boolean;
    onlyListView?: boolean;
    showWarning?: boolean;
    onOptionsClick?: () => void;
    errors?: any;
    onTogglePreview?: () => void;
};

export const renderGalleryAlert = () => {
    return dialogAlert(DIALOG_NAMES.GALLERY_NOT_FIRST_MODULE, false, undefined, undefined, false, icons.warningYellowIcon);
};

const PageValues: React.FC<pageValuesProps> = ({
    onChange,
    pageStyles,
    showAllIntents,
    initialValues: values,
    handleCreateNewOption,
    hasGalleryOrPreview,
    withoutTargets,
    onlyListView,
    showWarning,
    onOptionsClick,
    errors,
    onTogglePreview
}) => {
    const { intents }: pagesState = useAppSelector((state) => state.pages);
    const { isTablet, isDesktop } = useScreenSize();

    const renderWarningLabel = () => {
        return (
            <WarningLabelsWrapper>
                {showWarning &&
                    renderTooltip(
                        <WarningLabel
                            onClick={() => {
                                document.getElementById('warning_label')?.click?.();
                            }}
                        >
                            <SVGInline src={icons.infoIcon} />
                            {isDesktop ? <span>Warning</span> : null}
                        </WarningLabel>,
                        tooltipTypes.HTML,
                        <>
                            <WarningTooltip>Warning: </WarningTooltip>
                            Saving will not be aborted
                        </>,
                        tooltipPositions.BOTTOM
                    )}
            </WarningLabelsWrapper>
        );
    };

    const handleChange = (key: keyof pageValues, value: any) => {
        let newValue: any = null;
        switch (key) {
            case 'intent':
            case 'pageStyle':
                newValue = value.value;
                break;
            case 'abTestingGroupIds':
            case 'targets':
                newValue = value;
                break;
            case 'name':
                newValue = `${value}`;
                break;
            case 'pageView':
                newValue = value;

                break;
            default:
                break;
        }
        const newValues = {} as Record<keyof pageValues, any>;
        newValues[key] = newValue;
        onChange && onChange(newValues);
    };

    let intentOptions = showAllIntents
        ? intents
        : intents.filter(
              (intent) => ![PageIntents.AUTO_COLLECTION, PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(intent.value)
          );

    if (hasGalleryOrPreview) {
        intentOptions = intentOptions.filter((opt) =>
            [PageIntents.CONTENT_WORLD, PageIntents.HOME, PageIntents.FAVOURITES, PageIntents.RECORDINGS, PageIntents.BASIC].includes(
                opt.value
            )
        );
    }

    const renderTooltipedListViewIcon = renderTooltipWithKey(<InlineSVG src={icons.listViewIcon} />, 'pages_edit_icon_switch_to_list_view');
    const renderTooltipedEditorViewIcon = renderTooltipWithKey(
        <InlineSVG src={icons.desktopIcon} />,
        'pages_edit_icon_switch_to_editor_view'
    );

    return (
        <PageValuesWrapper>
            {!isDesktop && (
                <MobileButtonsWrapper>
                    <MobilePageButtons>
                        <Button
                            type={'DEFAULT'}
                            label={
                                <PagePreviewLabel>
                                    <ButtonIcon>
                                        {' '}
                                        <SVGInline src={icons.viewDarkIcon} />
                                    </ButtonIcon>
                                    Preview{' '}
                                </PagePreviewLabel>
                            }
                            onClick={() => {
                                onTogglePreview?.();
                            }}
                            style={pageOptionsButtonStyleMobile}
                        />
                        <Button
                            type={'DEFAULT'}
                            label={'Page Options'}
                            style={pageOptionsButtonStyleMobile}
                            onClick={() => onOptionsClick?.()}
                        />
                    </MobilePageButtons>

                    <WarningContainer>{renderWarningLabel()}</WarningContainer>
                    <ToggleViewButtonsWrapper>
                        <ToggleViewButton
                            type="DEFAULT"
                            label={renderTooltipedListViewIcon}
                            onClick={() => handleChange('pageView', PAGE_VIEWS.LIST_VIEW)}
                            isActive={values.pageView === PAGE_VIEWS.LIST_VIEW}
                        />
                        <ToggleViewButton
                            type="DEFAULT"
                            label={renderTooltipedEditorViewIcon}
                            onClick={() => handleChange('pageView', PAGE_VIEWS.EDITOR_VIEW)}
                            isActive={values.pageView === PAGE_VIEWS.EDITOR_VIEW}
                            isDisabled={onlyListView}
                        />
                    </ToggleViewButtonsWrapper>
                </MobileButtonsWrapper>
            )}
            <DialogTextField
                value={values.name || ''}
                label="Page Name"
                placeholder={onlyListView ? 'Page Name' : 'Optional'}
                onChange={(evt: any) => handleChange('name', evt.target.value)}
                dataCy={'page-name-input'}
                withTranslations={!onlyListView}
                error={errors?.name}
            />
            <DialogDropdownSingle
                options={intentOptions}
                value={values.intent ? intents.find((opt) => opt.value === values.intent) : ''}
                placeholder="Page Intent"
                onChange={(val: any) => handleChange('intent', val)}
            />
            <DialogDropdownSingle
                options={pageStyles}
                value={values.pageStyle ? pageStyles.find((opt) => opt.value === values.pageStyle) : ''}
                placeholder="Page Style"
                onChange={(val: any) => handleChange('pageStyle', val)}
                newOption={
                    handleCreateNewOption
                        ? {
                              name: 'style',
                              onClick: () => {
                                  handleCreateNewOption();
                              }
                          }
                        : undefined
                }
            />
            {!withoutTargets && (
                <TargetConditionsSelect
                    targets={values.targets || []}
                    onChange={(val) => handleChange('targets', val)}
                    noOfLabels={1}
                    inDialog={!isDesktop}
                />
            )}
            <AbTestingGroupSelectWrapper>
                <ABTestingGroupSelect
                    selectedGroups={values.abTestingGroupIds || []}
                    onChange={(val) => handleChange('abTestingGroupIds', val)}
                    selectedTargetConditions={values.targets}
                    noOfLabels={1}
                />
            </AbTestingGroupSelectWrapper>

            {isDesktop && (
                <ButtonsWrapper>
                    <Button
                        type={'DEFAULT'}
                        label={
                            <PagePreviewLabel>
                                <ButtonIcon>
                                    <SVGInline src={icons.viewDarkIcon} />
                                </ButtonIcon>
                                Preview{' '}
                            </PagePreviewLabel>
                        }
                        onClick={() => {
                            onTogglePreview?.();
                        }}
                        style={pageOptionsButtonStyleDesktop}
                    />
                    <Button
                        type={'DEFAULT'}
                        label={'Page Options'}
                        style={pageOptionsButtonStyleDesktop}
                        onClick={() => onOptionsClick?.()}
                    />

                    <WarningContainer>{renderWarningLabel()}</WarningContainer>
                    <ToggleViewButtonsWrapper>
                        <ToggleViewButton
                            type="DEFAULT"
                            label={renderTooltipedListViewIcon}
                            onClick={() => handleChange('pageView', PAGE_VIEWS.LIST_VIEW)}
                            isActive={values.pageView === PAGE_VIEWS.LIST_VIEW}
                        />
                        <ToggleViewButton
                            type="DEFAULT"
                            label={renderTooltipedEditorViewIcon}
                            onClick={() => handleChange('pageView', PAGE_VIEWS.EDITOR_VIEW)}
                            isActive={values.pageView === PAGE_VIEWS.EDITOR_VIEW}
                            isDisabled={onlyListView}
                        />
                    </ToggleViewButtonsWrapper>
                </ButtonsWrapper>
            )}
            <WarningLabelPlaceHolder
                hidden
                id={'warning_label'}
                onClick={() => {
                    renderGalleryAlert();
                }}
            />
        </PageValuesWrapper>
    );
};

export default PageValues;
