import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ItemState, fetchItem, unsetSelectedItem } from '../../../redux/slices/itemSlice';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import {
    ButtonCardPresenter,
    CardInfo,
    CardPresenter,
    CardTitle,
    ContentWrapper,
    TEMPLATE_SIZES,
    TemplateSizeKey,
    ProviderLogo,
    LogoContainer,
    TopTenNumber
} from '../VisualEditor.css';
import { DynamicCardsContainer, DynamicCardWrapper, ViewAllCardWrapper } from './DynamicItemPreview.css';
import Button from '../../Buttons/Button/Button';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import _ from 'lodash';
import { Item } from '../../../types/Item';
import { getDynamicItems } from '../../../utils/fnPreviews';
import { VisualContainer } from '../EditorialItemCustomization/EditorialItemCustomization.css';
import { Loader } from '../../common/Loader/Loader';
import { DynamicData } from '../../../utils/api/dataHandlers/dynamicData';
import { generateGradientValues } from '../../../utils/fnColor';
import { ViewAllCardPlaces } from '../../../types/Module';
import { UNSAFE_useEffectOnce } from '../../../hooks/useEffectOnce';
import { CloseButtonWrapper } from '../../Modules/VisualEditor.css';

export type DynamicItemPreviewProps = {
    open: boolean;
    top10Offset?: number;
    template: TemplateSizeKey;
    onClose: () => void;
    isGalleryOrPreview?: boolean;
    topTen?: boolean;
    autoCollection?: boolean;
    viewAllCardPlacement?: ViewAllCardPlaces;
    onCardClick?: (index: number) => void;
};

export const DynamicItemPreview: FC<DynamicItemPreviewProps> = ({
    open,
    template,
    topTen,
    top10Offset,
    autoCollection,
    viewAllCardPlacement,
    onClose,
    isGalleryOrPreview,
    onCardClick
}) => {
    const { error, selectedItemLoading: itemLoading, selectedItem, itemPreview }: ItemState = useAppSelector((state) => state.items);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<DynamicData[]>([]);

    const dispatch = useDispatch();

    const loadItem = async (id: string) => {
        await dispatch(fetchItem(id)).unwrap();
    };

    const handleCloseClick = () => {
        onClose && onClose();
    };

    const generateBgImage = (url?: string) => {
        if (url) {
            return `url('${decodeURIComponent(url)}')`;
        }

        return '';
    };
    useEffect(() => {
        return () => {
            dispatch(unsetSelectedItem());
        };
    }, []);

    const loadDynamicData = async (item: Item) => {
        setDataLoading(true);

        const items = await getDynamicItems(item, false);
        if (Array.isArray(items)) {
            setData(items);
        }
    };

    useEffect(() => {
        if (!data.length) return;
        setDataLoading(false);
    }, [data]);

    UNSAFE_useEffectOnce(() => {
        if (!itemPreview?.itemId || !open) return setIsOpen(open);
        setIsOpen(open);

        loadItem(itemPreview.itemId);
    }, [open]);

    UNSAFE_useEffectOnce(() => {
        if (!selectedItem || !open) return;

        loadDynamicData(selectedItem);
    }, [selectedItem]);

    const containerHeight = TEMPLATE_SIZES[template]?.height || TEMPLATE_SIZES['UNKNOWN'].height;

    const noImageIcon = ['16X9_S', '1X1_SQUARED', '1X1_ROUNDED', 'BANNER_S', 'UNKNOWN', 'GALLERY'].includes(template)
        ? icons.photoIcon
        : icons.largePhotoIcon;

    const isButton = template === 'BUTTON';

    const items = selectedItem?.singleAsset ? data.slice(0, 1) : data;

    const getItemBackground = (item: DynamicData) => {
        if (template.includes('BANNER') || isGalleryOrPreview) {
            return item.bigLandscapeImage || item.smallLandscapeImage || item.image;
        }
        if (['16X9_S', '1X1_SQUARED', '1X1_ROUNDED', '16X9_M'].includes(template)) {
            return item.smallLandscapeImage || item.image;
        }
        return item.image;
    };

    if (!isOpen) return null;

    if (error) {
        return <BackendErrorDialog error={error} />;
    }

    return (
        <VisualContainer isGalleryOrPreview={isGalleryOrPreview} isButton={template === 'BUTTON'}>
            {!isGalleryOrPreview && (
                <CloseButtonWrapper onClick={() => handleCloseClick()}>
                    <SVGInline src={icons.closeIcon} />
                </CloseButtonWrapper>
            )}
            <ContentWrapper>
                <DynamicCardsContainer height={containerHeight}>
                    {!itemLoading && !dataLoading ? (
                        <>
                            {autoCollection && viewAllCardPlacement === 'beginning' && (
                                <div key={'view-all-beginning'} style={{ display: 'flex' }}>
                                    <ViewAllCardWrapper $type={template}>
                                        <CardPresenter $type={template}>View All</CardPresenter>
                                    </ViewAllCardWrapper>
                                </div>
                            )}
                            {items.map((item, index) => {
                                const position = top10Offset ? index + top10Offset : index;
                                const isInfinite = top10Offset === -1;

                                return (
                                    <div key={index} style={{ display: 'flex' }}>
                                        {topTen && (
                                            <TopTenNumber template={template as any} $isInfinite={isInfinite}>
                                                {isInfinite ? '∞' : position + 1}
                                            </TopTenNumber>
                                        )}
                                        <DynamicCardWrapper
                                            key={`${index}_card`}
                                            $type={template}
                                            onClick={() => {
                                                onCardClick?.(index);
                                            }}
                                        >
                                            {isButton ? (
                                                <ButtonCardPresenter $type={template} disabled>
                                                    <Button
                                                        type={item.actionName ? 'BLUE' : 'WHITE'}
                                                        label={`${_.truncate(item.actionName, { length: 10 })}` || 'Default'}
                                                    />
                                                </ButtonCardPresenter>
                                            ) : (
                                                <>
                                                    <CardPresenter $type={template} $background={generateBgImage(getItemBackground(item))}>
                                                        {item?.logo && (
                                                            <LogoContainer gradientsValues={generateGradientValues(0, 1, 10)}>
                                                                <ProviderLogo $logo={item.logo} />
                                                            </LogoContainer>
                                                        )}
                                                        {!generateBgImage(getItemBackground(item)) && <SVGInline src={noImageIcon} />}
                                                    </CardPresenter>
                                                    <CardInfo $type={template}>
                                                        <CardTitle>{_.truncate(item.title, { length: 30 })}</CardTitle>
                                                    </CardInfo>
                                                </>
                                            )}
                                        </DynamicCardWrapper>
                                    </div>
                                );
                            })}
                            {autoCollection && viewAllCardPlacement === 'end' && (
                                <div key={'view-all-end'} style={{ display: 'flex' }}>
                                    <ViewAllCardWrapper $type={template}>
                                        <CardPresenter $type={template}>View All</CardPresenter>
                                    </ViewAllCardWrapper>
                                </div>
                            )}
                        </>
                    ) : (
                        <Loader lightTitle />
                    )}
                </DynamicCardsContainer>
            </ContentWrapper>
        </VisualContainer>
    );
};
