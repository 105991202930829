import { TableBody, TableRow, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import icons from '../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchUsers, UsersState } from '../../../redux/slices/usersSlice';
import { createGroup, deleteGroup, fetchGroups, GroupsState, updateGroup } from '../../../redux/slices/groupsSlice';
import { MainContentWrapper } from '../../../style/styled-components/reusable.css';
import { User } from '../../../types/User';
import { DEFAULT_SORT_CONFIG, ISortConfig } from '../../../utils/fnSort';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import GenericTable, { ActionsTableCell, HeaderTableCell, SortableHeaderTableCell, tableActions } from '../../common/Table/Table';
import { GroupName, IconContainer, TenantIcon, TenantName, TenantRow } from './Groups.css';
import { useNavigate } from 'react-router-dom';
import { NewGroup } from './Dialogs/NewGroup';
import { UserGroup } from '../../../types/UserGroup';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { ProjectPreviewForDeletion, UserAvatar, UserAvatarPlaceholder } from '../../Projects/Projects.css';
import { PermissionsState, setUserPermissions } from '../../../redux/slices/permissionsSlice';
import { API_ERROR_CODES } from '../../../utils/Globals';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import _ from 'lodash';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { Loader } from '../../common/Loader/Loader';
import useScreenSize from '../../../hooks/useScreenSize';
import { WidthTableCell } from '../../common/Table/Table.css';
import { PageRoutes } from '../../../types/RouteTypes';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import { GroupsTableSizes } from '../../../types/TableSizes';
import Labels from '../../common/Labels/Labels';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';

export const Groups: React.FC<{ dialogOpen: boolean; selectedGroupId: string; onDialogClose: any }> = ({
    dialogOpen,
    selectedGroupId,
    onDialogClose
}) => {
    const { groups: storeGroups, error: groupsError, loading: groupsLoading }: GroupsState = useAppSelector((state) => state.groups);
    const { users: storeUsers, error: usersError, loading: usersLoading }: UsersState = useAppSelector((state) => state.users);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);

    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });
    const [openAddGroupDialog, setOpenAddGroupDialog] = useState(!!dialogOpen);
    const [groupToEdit, setGroupToEdit] = useState<UserGroup | null>(null);
    const [groups, setGroups] = useState(groupsError ? [] : storeGroups);
    const [users, setUsers] = useState(usersError ? [] : storeUsers);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile, isTablet } = useScreenSize();

    useEffect(() => {
        loadGroups(true, orderBy, searchTerm).then((response) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            if (response.error?.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) {
                loadUsers();
            }
        });
    }, []);

    useEffect(() => {
        if (selectedGroupId) {
            const group = groups.find((group) => group._id === selectedGroupId);
            setGroupToEdit(group || null);
        }
    }, [selectedGroupId]);

    useEffect(() => {
        if (!openAddGroupDialog) {
            onDialogClose && onDialogClose();
        }
    }, [openAddGroupDialog]);

    useEffect(() => {
        if (groupsLoading || usersLoading || groupsError || usersError) return;

        if (storeGroups) setGroups(storeGroups);
        if (storeUsers) setUsers(storeUsers);
    }, [groupsLoading, usersLoading]);

    const loadGroups = async (addPermissions?: boolean, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchGroups({ addPermissions, orderBy, searchTerm })).unwrap();
    };

    const loadUsers = async () => {
        await dispatch(fetchUsers({})).unwrap();
    };

    const removeGroup = async (id: string) => {
        await dispatch(deleteGroup(id)).unwrap();
        loadGroups(false, orderBy, searchTerm);
    };

    const addGroup = async (group: UserGroup, userIds: string[]) => {
        await dispatch(createGroup({ group, userIds })).unwrap();
        loadGroups();
        loadUsers();
        setSearchTerm(undefined);
        setActiveSortingKey(null);
        setSortConfig(DEFAULT_SORT_CONFIG);
    };

    const modifyGroup = async (group: UserGroup, userIds: string[]) => {
        await dispatch(updateGroup({ group, userIds })).unwrap();
        loadGroups(false, orderBy, searchTerm);
        loadUsers();
    };

    const onCloseGroupDialog = () => {
        setOpenAddGroupDialog(false);
        setGroupToEdit(null);
    };

    const onSaveGroup = (group: UserGroup, userIds: string[]) => {
        if (groupToEdit) {
            modifyGroup(group, userIds);
        } else {
            addGroup(group, userIds);
        }
        setOpenAddGroupDialog(false);
        setGroupToEdit(null);
    };

    const onDeleteGroup = (id: string) => {
        const group = groups.find((group) => group._id === id);
        const values = {
            title: 'Delete Group',
            text: (
                <>
                    <p>
                        You are in the process of deleting the following Group <strong>{group?.name}</strong>. Are you sure you want to
                        permanently delete the selected group?
                    </p>
                    <p>Are you sure you want to delete?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                removeGroup(id);
            },
            values,
            <ProjectPreviewForDeletion>
                <p>{group?.name}</p>
            </ProjectPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            }
        );
    };

    const handleOnSearch = (searchTerm: string) => {
        loadGroups(false, orderBy, searchTerm);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadGroups(false, orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const renderColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'User-Group Name'}
                hideArrow={!showSortArrows && activeSortingKey !== 'name'}
                onClick={() => handleSortIconClick('name')}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={GroupsTableSizes.name}
                direction={((sortConfig?.field === 'name' || sortConfig?.field.split('.')[0] === 'name') && sortConfig.direction) || 'asc'}
            />,
            <HeaderTableCell key={`tenant_cell`} text={'Tenant'} columnSize={GroupsTableSizes.tenant} />,
            <HeaderTableCell key={'projects_cell'} text={'Projects'} columnSize={GroupsTableSizes.projects} />,
            <HeaderTableCell key={'users_cell'} text={'Users'} columnSize={GroupsTableSizes.users} />
        ];

        return groups.length ? columns : [];
    };

    const buildTableBody = () => {
        const rows = groups?.map((group, index) => {
            const { name, tenant, projects, _id: group_id } = group;

            const usersForGroup: undefined | User[] = users.filter((user: User) => {
                return user.userGroupIds?.includes(group_id);
            });

            const itemsToShow = isMobile || isTablet ? 1 : 3;

            return (
                <TableRow key={index}>
                    {/* GROUP NAME TABLE CELL */}
                    <WidthTableCell {...GroupsTableSizes.name}>
                        <GroupName
                            onClick={() => {
                                setOpenAddGroupDialog(true);
                                setGroupToEdit(group);
                            }}
                        >
                            {name}
                        </GroupName>
                    </WidthTableCell>

                    {/* TENANTS TABLE CELL */}
                    <WidthTableCell {...GroupsTableSizes.tenant}>
                        <TenantRow>
                            <TenantIcon>
                                <img src={tenant?.logo || icons.tenantIcon} alt="" />
                            </TenantIcon>
                            <TenantName>{tenant?.name || 'Tenant name'}</TenantName>
                        </TenantRow>
                    </WidthTableCell>

                    {/* PROJECTS TABLE CELL */}
                    <WidthTableCell {...GroupsTableSizes.projects}>
                        <Labels
                            values={projects || []}
                            type={MoreInfoTypes.PROJECTS}
                            noOfLabels={itemsToShow}
                            onClickLabel={(project) => {
                                navigate(PageRoutes.PROJECTS, { state: { selectedProjectId: project._id } });
                            }}
                        />
                    </WidthTableCell>

                    {/* USER TABLE CELL */}
                    <WidthTableCell {...GroupsTableSizes.users}>
                        <IconContainer onClick={() => setShowMoreDialog({ type: MoreInfoTypes.USERS, show: true, data: usersForGroup })}>
                            {usersForGroup?.map((user, index: number) => {
                                if (index < itemsToShow) {
                                    return (
                                        <Tooltip title={user.fullName || ''} key={index}>
                                            <UserAvatar background={user?.icon || icons.userIcon} />
                                        </Tooltip>
                                    );
                                }
                            })}
                            {usersForGroup && usersForGroup.length > itemsToShow && (
                                <UserAvatarPlaceholder>+{usersForGroup.length - itemsToShow}</UserAvatarPlaceholder>
                            )}
                        </IconContainer>
                    </WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...GroupsTableSizes.actions}>
                        <ActionsTableCell
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onRemove={() => {
                                onDeleteGroup(group._id);
                            }}
                            onEdit={() => {
                                setOpenAddGroupDialog(true);
                                setGroupToEdit(group);
                            }}
                            tooltipTexts={{ edit: 'user_groups_icon_edit', delete: 'user_groups_icon_delete' }}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };
    if (!groupsLoading && !usersLoading && !userPermissions) {
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    return (
        <>
            {usersError ? <BackendErrorDialog error={usersError} /> : groupsError ? <BackendErrorDialog error={groupsError} /> : null}
            <MainContentWrapper>
                <ScreenTitle
                    loading={groupsLoading || usersLoading}
                    title="User-Groups"
                    withoutSearch
                    withAddButton
                    withProfile
                    addLabel="Create User-Group"
                    onAdd={() => setOpenAddGroupDialog(true)}
                    circlesSlugOptions={{ default: CIRCLE_SLUGS.user_groups, onboarding: ONBOARDING_CIRCLE_SLUGS.user_groups }}
                />
                <SearchBarContainer>
                    <SearchBar
                        title={'Search'}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        disabled={groupsLoading || usersLoading}
                        tooltipText={'user_groups_icon_search'}
                    />
                </SearchBarContainer>
                {groupsLoading ? <Loader title={'User-Groups'} /> : <GenericTable body={buildTableBody()} columns={renderColumns()} />}
            </MainContentWrapper>
            <NewGroup
                open={openAddGroupDialog}
                group={groupToEdit}
                onClose={onCloseGroupDialog}
                onSave={(group, userIds) => {
                    onSaveGroup(group, userIds);
                }}
            />
            <MoreInfoDialog
                type={showMoreDialog.type!}
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                data={showMoreDialog.data}
            />
        </>
    );
};
