import React, { useEffect } from 'react';
import { DashboardCardsWrapper, DateAndCompanyWrapper, Footer, MoreInfoCardsWrapper } from './Dashboard.css';
import { Members } from './Members/Members';
import InsightsDashboardWidget from './Insights/InsightsDashboardWidget';
import Sidebar from '../common/Sidebar/Sidebar';
import { DashboardCard } from '../Cards/DashboardCard/DashboardCard';
import {
    ApplicationWrapper,
    TruncatedText,
    DropdownOptionWrapper,
    MainContentWrapper,
    SCREEN_SIZES
} from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch as useDispatch } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import useScreenSize from '../../hooks/useScreenSize';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { Loader } from '../common/Loader/Loader';
import { fetchDashbard, ProjectsState } from '../../redux/slices/projectsSlice';
import News from './News/News';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import { EMPTY_WORD_STRING } from '../../utils/Globals';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { renderPublishStatusIcon } from '../../utils/fnPublish';
import { itemTypes } from '../../types/Item';
import ObjectNameTooltip from '../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../../types/Object';
import { PublishedStatusIconHolder } from '../PageEdit/Dialogs/UseExistingDialog.css';
import { CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import useTranslation from '../../hooks/useTranslation';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
const numOfCardsToRender: typeof SCREEN_SIZES = {
    MOBILE_S: 2,
    MOBILE_L: 2,
    TABLET: 2,
    DESKTOP_S: 3,
    DESKTOP_L: 4
};

export const Dashboard: React.FC = () => {
    const { sidebarOpen } = useAppSelector((state) => state.sidebarOpen);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { projects, loadingDashboard, dashboardData }: ProjectsState = useAppSelector((state) => state.projects);
    const { isRestrictedArea: isOptimizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'optimize');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { screenType } = useScreenSize();
    const { translate } = useTranslation();

    useEffect(() => {
        // active project id always exists unless the session is deleted
        if (!activeProjectId) return;
        loadResources();
    }, [activeProjectId]);

    const loadResources = async () => {
        await dispatch(fetchDashbard(activeProjectId || '')).unwrap();
    };

    const renderObjectCard = (objectName: string) => {
        if (!Object.keys(dashboardData).length) return;

        const { pages, items, modules, settings, displayConditions, menus } = dashboardData;
        switch (objectName) {
            case 'ITEMS':
                return (
                    <DashboardCard
                        image={resourceCardImages.itemsCard}
                        title={'Lists & Items'}
                        options={
                            items?.map((item) => ({
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={item._id}
                                                name={translate(item.name || EMPTY_WORD_STRING)}
                                                type={ObjectTypes.ITEMS}
                                            />
                                        </TruncatedText>
                                        <div>
                                            <TranslationTooltip translationKey={item.name} />
                                        </div>
                                        <PublishedStatusIconHolder>{renderPublishStatusIcon(item.publishStatus)}</PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                value: item._id,
                                valueForSearch:
                                    `${translate(item.name)} ${
                                        item.itemType === itemTypes.EDITORIAL ? item.itemType : item.contentSourceType?.split('_').join(' ')
                                    }` || EMPTY_WORD_STRING
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), {
                                state: {
                                    itemId: objectId
                                }
                            });
                        }}
                        onSecondaryButtonClick={(_) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), { state: { openCreate: true } });
                        }}
                        customObjectName={'List or Item'}
                    />
                );
            case 'MODULES':
                return (
                    <DashboardCard
                        image={resourceCardImages.modulesCard}
                        title={'Modules'}
                        options={
                            modules?.map((module) => ({
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={module._id}
                                                name={translate(module.name || EMPTY_WORD_STRING)}
                                                type={ObjectTypes.MODULES}
                                            />
                                        </TruncatedText>
                                        <div>
                                            <TranslationTooltip translationKey={module.name} />
                                        </div>
                                        <PublishedStatusIconHolder>
                                            {renderPublishStatusIcon(module.publishStatus)}
                                        </PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                value: module._id,
                                valueForSearch: translate(module.name || EMPTY_WORD_STRING)
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                                state: {
                                    moduleId: objectId,
                                    redirected: true
                                }
                            });
                        }}
                        onSecondaryButtonClick={(_) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), { state: { openCreate: true } });
                        }}
                    />
                );
            case 'PAGES':
                return (
                    <DashboardCard
                        image={resourceCardImages.pagesCard}
                        title={'Pages'}
                        options={
                            pages?.map((p) => ({
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={p._id}
                                                name={translate(p.name || EMPTY_WORD_STRING)}
                                                type={ObjectTypes.PAGES}
                                            />
                                        </TruncatedText>
                                        <div>
                                            <TranslationTooltip translationKey={p.name} />
                                        </div>
                                        <PublishedStatusIconHolder>
                                            {renderPublishStatusIcon(p.publishStatus, p.publishAt)}
                                        </PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                value: p._id,
                                valueForSearch: translate(p.name || EMPTY_WORD_STRING)
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) =>
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', objectId)))
                        }
                        onSecondaryButtonClick={(_) => navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE))}
                    />
                );
            case 'DISPLAYCONDITIONS':
                return (
                    <DashboardCard
                        image={resourceCardImages.conditionsCard}
                        title={'Conditions'}
                        options={
                            displayConditions?.map((c) => ({
                                label: <ObjectNameTooltip id={c._id} name={c.name || EMPTY_WORD_STRING} type={ObjectTypes.CONDITIONS} />,
                                value: c._id,
                                valueForSearch: c.name || EMPTY_WORD_STRING
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_CONDITIONS), {
                                state: {
                                    conditionId: objectId
                                }
                            });
                        }}
                        onSecondaryButtonClick={(_) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_CONDITIONS), {
                                state: { openCreate: true }
                            });
                        }}
                    />
                );
            case 'SETTINGS':
                return (
                    <DashboardCard
                        image={resourceCardImages.targetGroupsCard}
                        title={'Settings'}
                        options={
                            settings?.map((obj) => ({
                                label: <ObjectNameTooltip id={obj._id} name={obj.name || EMPTY_WORD_STRING} type={ObjectTypes.SETTINGS} />,
                                value: obj._id,
                                valueForSearch: obj.name || EMPTY_WORD_STRING
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS), {
                                state: {
                                    settingsId: objectId
                                }
                            });
                        }}
                        onSecondaryButtonClick={(_) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS), { state: { openCreate: true } });
                        }}
                    />
                );
            case 'MENUS':
                return (
                    <DashboardCard
                        image={resourceCardImages.menusCard}
                        title={'Menus'}
                        options={
                            menus?.map((m) => ({
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={m._id}
                                                name={translate(m.name || EMPTY_WORD_STRING)}
                                                type={ObjectTypes.MENUS}
                                            />
                                        </TruncatedText>
                                        <div>
                                            <TranslationTooltip translationKey={m.name} />
                                        </div>
                                        <div>{renderPublishStatusIcon(m.publishStatus)}</div>
                                    </DropdownOptionWrapper>
                                ),
                                value: m._id,
                                valueForSearch: translate(m.name || EMPTY_WORD_STRING)
                            })) || []
                        }
                        onPrimaryButtonClick={(objectId) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS), { state: { menuId: objectId } });
                        }}
                        onSecondaryButtonClick={(_) => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS), { state: { openCreate: true } });
                        }}
                    />
                );
        }
    };

    const renderCards = () => {
        return Object.keys(dashboardData)
            .slice(0, screenType ? numOfCardsToRender[screenType] : 4)
            .map((objectName) => renderObjectCard(objectName.toUpperCase()));
    };

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={loadingDashboard}
                    title="Dashboard"
                    withProfile={true}
                    circlesSlugOptions={{ default: CIRCLE_SLUGS.dashboard }}
                />
                {loadingDashboard && <Loader title={'Dashboard'} />}
                {!loadingDashboard && (
                    <>
                        <DashboardCardsWrapper>{renderCards()}</DashboardCardsWrapper>
                        <MoreInfoCardsWrapper>
                            {!!dashboardData.users && (
                                <Members
                                    projectName={projects.find((p) => p._id === activeProjectId)?.name || 'No Project Selected'}
                                    usersList={
                                        dashboardData.users?.filter((user) => user.projectIds?.includes(activeProjectId || '')) || []
                                    }
                                />
                            )}
                            {!isOptimizeRestricted && <InsightsDashboardWidget />}
                            <News />
                        </MoreInfoCardsWrapper>
                    </>
                )}

                <Footer $sidebarOpen={sidebarOpen}>
                    <DateAndCompanyWrapper>{`${new Date().getFullYear()} 3Screen Solutions GmbH`}</DateAndCompanyWrapper>
                </Footer>
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};
