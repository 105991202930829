import React, { FC, useEffect, useState } from 'react';
import { DialogTextField } from '../../common/Dialog/GenericDialog';
import {
    BackgroundContainer,
    BackgroundSelector,
    BrowseIcon,
    BrowseWrapper,
    ButtonsContainer,
    ButtonWrapper,
    DescriptionContainer,
    EditorialInfo,
    TitleContainer,
    SubTitleContainer,
    EditorialCustomizeVisualContainer
} from './EditorialItemCustomization.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import BackgroundDialog, { supportedResolutionsType } from '../../Items/Dialogs/BackgroundDialog';
import Button from '../../Buttons/Button/Button';
import {
    BackgroundSelectionButton,
    BackgroundSelectionContainer,
    DragAndDropTextWrapper,
    GradientBackgroundToRight,
    GradientBackgroundToRightV2,
    GradientBackgroundToTop,
    GradientBackgroundToTopV2,
    TEMPLATE_SIZES,
    TemplateSizeKey
} from '../VisualEditor.css';
import ActionDialog from '../../Items/Dialogs/ActionDialog';
import { alphaHexToRGBA, generateGradientValues } from '../../../utils/fnColor';
import _ from 'lodash';
import { Item } from '../../../types/Item';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ItemState, fetchItem, unsetSelectedItem } from '../../../redux/slices/itemSlice';
import { DIALOG_NAMES, dialogConfirm } from '../../../utils/fnDialogs';
import { Loader } from '../../common/Loader/Loader';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { CloseButtonWrapper } from '../../Modules/VisualEditor.css';
import BackgroundSwitcher from '../../Items/BackgroundSwitcher';
import { resolutionsIcons } from '../../Items/EditorialView';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';

export type EditorialItemCustomizeProps = {
    open: boolean;
    template: TemplateSizeKey;
    onSave: (arg: Item) => void;
    onClose: () => void;
};

export enum TranslationKeys {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    DESCRIPTION = 'description',
    ACTION = 'actionName'
}

export const EditorialItemCustomize: FC<EditorialItemCustomizeProps> = ({ open, template, onSave, onClose }) => {
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isBackgroundDialogOpen, setIsBackgroundDialogOpen] = useState<boolean>(false);
    const [isHero, setIsHero] = useState<boolean>(false);
    const [isOpenActionDialog, setIsOpenActionDialog] = useState<boolean>(false);
    const [action, setAction] = useState<any>('');
    const [actionName, setActionName] = useState<any>('');
    const [color, setColor] = useState<string>('');
    const [heroBackgroundColor, setHeroBackgroundColor] = useState<string>('');
    const [activeResolution, setActiveResolution] = useState<keyof typeof resolutionsIcons>('bigScreen');
    const [backgroundImage, setBackgroundImage] = useState<supportedResolutionsType | undefined>(undefined);
    const [heroBackgroundImage, setHeroBackgroundImage] = useState<supportedResolutionsType | undefined>(undefined);
    const [extensionError, setExtensionError] = useState<undefined | string>(undefined);
    const [droppedFile, setDroppedFile] = useState<string | undefined>(undefined);
    const [hoverAutoPlay, setVideoForAutoplay] = useState<string | undefined>(undefined);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { selectedItemLoading: loading, error, selectedItem, itemPreview }: ItemState = useAppSelector((state) => state.items);

    const dispatch = useDispatch();

    const loadItem = async (id: string) => {
        await dispatch(fetchItem(id)).unwrap();
    };

    const checkShowUnsaved = () => {
        const initialValues = {
            title: selectedItem?.title || '',
            subtitle: selectedItem?.subtitle || '',
            description: selectedItem?.description || '',
            color: selectedItem?.backgroundColor || '',
            heroBackgroundColor: selectedItem?.heroBackgroundColor || '',
            backgroundImage: selectedItem?.backgroundImage ? JSON.stringify(selectedItem.backgroundImage) : '',
            heroBackgroundImage: selectedItem?.heroBackgroundImage ? JSON.stringify(selectedItem.heroBackgroundImage) : '',
            actionName: selectedItem?.actionName || '',
            action: selectedItem?.action ? JSON.stringify(selectedItem.action) : '',
            videoForAutoplay: selectedItem?.videoForAutoplay || ''
        };

        const currentValues = {
            title,
            subtitle,
            description,
            color,
            heroBackgroundColor,
            backgroundImage: backgroundImage ? JSON.stringify(backgroundImage) : '',
            heroBackgroundImage: heroBackgroundImage ? JSON.stringify(heroBackgroundImage) : '',
            actionName: actionName || '',
            action: action ? JSON.stringify(action) : '',
            videoForAutoplay: hoverAutoPlay
        };

        return !_.isEqual(initialValues, currentValues);
    };

    const renderEditingAlertDialog = () => {
        return dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => saveItem(),
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => handleCloseClick(),
            true
        );
    };

    useEffect(() => {
        setIsOpen(open);
        if (!itemPreview?.itemId) return;

        if (open) {
            loadItem(itemPreview.itemId);
        }
    }, [open, itemPreview?.itemId]);

    useEffect(() => {
        if (loading || error || !selectedItem) return;
        setTitle(selectedItem?.title || '');
        setSubtitle(selectedItem?.subtitle || '');
        setDescription(selectedItem?.description || '');
        setColor(selectedItem?.backgroundColor || '');
        setHeroBackgroundColor(selectedItem?.heroBackgroundColor || '');
        setBackgroundImage(selectedItem?.backgroundImage || undefined);
        setHeroBackgroundImage(selectedItem?.heroBackgroundImage || undefined);
        setAction(selectedItem?.action || '');
        setActionName(selectedItem?.actionName || '');
        setVideoForAutoplay(selectedItem?.videoForAutoplay || '');
    }, [loading]);

    const handleCloseClick = (check = false) => {
        if (check && checkShowUnsaved()) {
            renderEditingAlertDialog();
            return;
        }
        onClose && onClose();
        setTitle('');
        setSubtitle('');
        setDescription('');
        setAction('');
        setActionName('');
        setColor('');
        setHeroBackgroundColor('');
        setBackgroundImage(undefined);
        setHeroBackgroundImage(undefined);
        setVideoForAutoplay(undefined);
        setDroppedFile(undefined);
        dispatch(unsetSelectedItem());
    };

    const saveItem = () => {
        if (!selectedItem) return;

        const newItem: Item = {
            ...selectedItem,
            name: selectedItem.name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            title: title,
            subtitle: subtitle,
            description: description,
            backgroundColor: color,
            heroBackgroundColor,
            backgroundImage,
            heroBackgroundImage,
            action,
            actionName,
            videoForAutoplay: hoverAutoPlay
        };
        onSave && onSave(newItem);
    };

    const handleBackgroundSave = (backgroundValue: string | supportedResolutionsType, autoplay?: string, isColor?: boolean) => {
        if (isHero) {
            setHeroBackgroundColor(isColor && typeof backgroundValue === 'string' ? backgroundValue : '');
            setHeroBackgroundImage(!isColor && typeof backgroundValue !== 'string' ? backgroundValue : undefined);
        } else {
            setColor(isColor && typeof backgroundValue === 'string' ? backgroundValue : '');
            setBackgroundImage(!isColor && typeof backgroundValue !== 'string' ? backgroundValue : undefined);
            setVideoForAutoplay(autoplay || '');
        }
        setIsBackgroundDialogOpen(false);
        handleBackgroundClose();
    };

    const calculateSelectorBackground = (isHero: boolean = false, resolution?: keyof supportedResolutionsType) => {
        const colorToShow = isHero ? heroBackgroundColor : color;
        const background = isHero ? heroBackgroundImage : backgroundImage;
        if (colorToShow) {
            const { r, g, b, a } = alphaHexToRGBA(colorToShow);
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        }
        if (background) {
            const url = resolution ? background?.[resolution] || '' : Object.values(background).find((val) => !!val);

            if (!url) return '';
            return `url('${decodeURIComponent(url)}')`;
        }
        return '';
    };
    const handleBackgroundClose = () => {
        setDroppedFile(undefined);
        setIsBackgroundDialogOpen(false);
        setExtensionError(undefined);
        setIsHero(false);
    };
    const handleDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            const { type } = files[0];
            if (!['jpeg', 'png'].includes(type.split('/')[1])) {
                setExtensionError('Incorrect file extension, can upload only JPG or PNG');
            }
            setDroppedFile(files);
            setIsBackgroundDialogOpen(true);
            setIsHero(true);
        }
    };

    const isBanner = ['BANNER_XL', 'BANNER_XXL'].includes(template);
    const withHeroBackground = ['BANNER_S'].includes(template);
    const hasDescription = !!TEMPLATE_SIZES[template] && template !== '16X9_S';

    const buttonLabel = (
        <ButtonWrapper>
            {_.truncate(actionName, { length: 20, omission: '...' }) || '[NO ACTION NAME]'}
            {<TranslationTooltip translationKey={actionName} />}
        </ButtonWrapper>
    );

    const renderInfoSection = () => {
        return (
            <EditorialInfo showAsBanner={isBanner} template={template}>
                {template !== 'BUTTON' && (
                    <TitleContainer>
                        <DialogTextField
                            placeholder={'Click to edit Title'}
                            value={title}
                            onChange={(e: any) => {
                                setTitle(e.target.value);
                            }}
                            withTranslations
                            isVisualEditor
                        />
                    </TitleContainer>
                )}

                {hasDescription && (
                    <>
                        <SubTitleContainer>
                            <DialogTextField
                                placeholder={'Click to edit Subtitle'}
                                value={subtitle}
                                onChange={(e: any) => {
                                    setSubtitle(e.target.value);
                                }}
                                withTranslations
                                isVisualEditor
                            />
                        </SubTitleContainer>
                        <DescriptionContainer>
                            <DialogTextField
                                placeholder={'Click to edit Description'}
                                value={description}
                                onChange={(e: any) => {
                                    setDescription(e.target.value);
                                }}
                                withTranslations
                                isVisualEditor
                            />
                        </DescriptionContainer>
                    </>
                )}
                <ButtonsContainer>
                    <Button
                        type={'WHITE'}
                        label={action ? buttonLabel : 'Set Action'}
                        onClick={() => {
                            setIsOpenActionDialog(true);
                        }}
                    />
                    <Button
                        type={'BLUE'}
                        label={'Save'}
                        onClick={() => {
                            saveItem();
                        }}
                    />
                </ButtonsContainer>
            </EditorialInfo>
        );
    };

    if (!isOpen) return null;

    return (
        <EditorialCustomizeVisualContainer
            background={withHeroBackground && !loading ? calculateSelectorBackground(true, activeResolution) : undefined}
        >
            <GradientBackgroundToTopV2 black={10} transparent={30} />
            <GradientBackgroundToRightV2 black={20} transparent={100} />
            <CloseButtonWrapper onClick={() => handleCloseClick(true)}>
                <SVGInline src={icons.closeIcon} />
            </CloseButtonWrapper>
            {loading ? (
                <Loader lightTitle />
            ) : (
                <>
                    {isBanner ? (
                        <BackgroundContainer template={template}>
                            <BackgroundSelector showAsBanner={isBanner} $background={calculateSelectorBackground(false, activeResolution)}>
                                <GradientBackgroundToTopV2 black={10} transparent={30} />
                                <GradientBackgroundToRightV2 black={20} transparent={100} />
                                {renderInfoSection()}
                                <BackgroundSelectionContainer
                                    onClick={() => {
                                        setIsBackgroundDialogOpen(true);
                                    }}
                                >
                                    <BackgroundSelectionButton>
                                        <SVGInline src={icons.photoIcon} />
                                    </BackgroundSelectionButton>
                                    <DragAndDropTextWrapper>
                                        {backgroundImage || color ? 'Click to change or Drag and Drop' : 'Drag and Drop or Browse files'}
                                    </DragAndDropTextWrapper>
                                </BackgroundSelectionContainer>
                            </BackgroundSelector>
                            <BackgroundSwitcher
                                activeResolution={activeResolution}
                                backgroundImage={backgroundImage}
                                backgroundColor={color}
                                setActiveResolution={setActiveResolution}
                                setIsOpenBgDialog={setIsBackgroundDialogOpen}
                                insideBanner
                            />
                        </BackgroundContainer>
                    ) : (
                        <>
                            <BackgroundContainer
                                template={template}
                                onClick={() => {
                                    setIsBackgroundDialogOpen(true);
                                }}
                            >
                                <BackgroundSelector
                                    showAsBanner={isBanner}
                                    $background={calculateSelectorBackground(false, activeResolution)}
                                >
                                    {backgroundImage?.[activeResolution] || color
                                        ? renderTooltipWithKey(<SVGInline src={icons.editLightIcon} />, 'item_editorial_icon_edit')
                                        : renderTooltipWithKey(<SVGInline src={icons.addIconWhite} />, 'item_editorial_icon_add')}
                                </BackgroundSelector>
                                <BackgroundSwitcher
                                    activeResolution={activeResolution}
                                    backgroundImage={backgroundImage}
                                    setActiveResolution={setActiveResolution}
                                    setIsOpenBgDialog={setIsBackgroundDialogOpen}
                                    backgroundColor={color}
                                />
                            </BackgroundContainer>
                            {renderInfoSection()}
                        </>
                    )}
                    {withHeroBackground && (
                        <BackgroundSelectionContainer
                            onClick={() => {
                                setIsBackgroundDialogOpen(true);
                                setIsHero(true);
                            }}
                            onDragOver={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onDrop={handleDrop}
                        >
                            <BackgroundSelectionButton>
                                <SVGInline src={icons.photoIcon} />
                            </BackgroundSelectionButton>
                            <DragAndDropTextWrapper>
                                {heroBackgroundImage || heroBackgroundColor
                                    ? 'Click to change or Drag and Drop'
                                    : 'Drag and Drop or Browse Files'}
                            </DragAndDropTextWrapper>
                        </BackgroundSelectionContainer>
                    )}
                </>
            )}

            <BackgroundDialog
                open={isBackgroundDialogOpen}
                onSave={(backgroundValue, autoplay, isColor) => handleBackgroundSave(backgroundValue, autoplay, isColor)}
                onClose={() => handleBackgroundClose()}
                background={isHero ? heroBackgroundColor || heroBackgroundImage : color || backgroundImage}
                isColor={isHero ? !!heroBackgroundColor : !!color}
                backgroundAutoplay={hoverAutoPlay}
                droppedFile={droppedFile}
                isHero={isHero}
                extensionError={extensionError}
            />
            <ActionDialog
                open={isOpenActionDialog}
                action={action}
                name={actionName}
                onSave={(actionName, action) => {
                    setAction(action);
                    setActionName(actionName);
                    setIsOpenActionDialog(false);
                }}
                onClose={() => setIsOpenActionDialog(false)}
            />
        </EditorialCustomizeVisualContainer>
    );
};
