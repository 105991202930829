import React, { FC } from 'react';
import { AppliedForAllText, BackgroundSwitch, BackgroundSwitchContainer, DeviceBackgroundSVG } from './EditorialView.css';
import { isBackgroundAppliedForAll } from '../../utils/fnUrl';
import { resolutionsIcons, resolutionsIconsWithAdd } from './EditorialView';
import { supportedResolutionsType } from './Dialogs/BackgroundDialog';

const BackgroundSwitcher: FC<{
    activeResolution: any;
    setActiveResolution: any;
    backgroundImage?: supportedResolutionsType;
    backgroundColor?: string;
    setIsOpenBgDialog: any;
    insideGallery?: boolean;
    insideBanner?: boolean;
}> = ({ activeResolution, setActiveResolution, backgroundImage, backgroundColor, setIsOpenBgDialog, insideGallery, insideBanner }) => {
    const isAppliedForAll = !!backgroundColor || isBackgroundAppliedForAll(backgroundImage);
    return (
        <BackgroundSwitchContainer insideGallery={insideGallery} insideBanner={insideBanner} onClick={(event) => event.stopPropagation()}>
            <BackgroundSwitch>
                {(Object.keys(resolutionsIcons) as Array<keyof typeof resolutionsIcons>).map((key) => {
                    return (
                        <DeviceBackgroundSVG
                            active={activeResolution === key}
                            disabled={isAppliedForAll}
                            key={`${isAppliedForAll}${key}`}
                            src={isAppliedForAll || !!backgroundImage?.[key] ? resolutionsIcons[key] : resolutionsIconsWithAdd[key]}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!backgroundImage?.[key]) {
                                    setIsOpenBgDialog(true);
                                    return;
                                }
                                setActiveResolution(key);
                            }}
                        />
                    );
                })}
            </BackgroundSwitch>
            {isAppliedForAll && <AppliedForAllText>Applied for all screens</AppliedForAllText>}
        </BackgroundSwitchContainer>
    );
};

export default BackgroundSwitcher;
