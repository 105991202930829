import styled, { CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { ButtonInner, ButtonWrapper } from '../Buttons/Button/Button.css';
import { SelectWrapper } from '../common/Select/Select.css';
import Button from '../Buttons/Button/Button';
import { devices, PageValuesContainer } from '../../style/styled-components/reusable.css';
import { InputLabelWithIconWrapper } from '../common/Dialog/GenericDialog.css';

const theme: any = getTheme();

export const PageValuesWrapper = styled(PageValuesContainer)`
    background: ${theme.palette.white.main};
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 104px;
    position: sticky;
    top: 0;
    z-index: 20;
    gap: 16px;
    padding: 36px;
    align-items: center;
    margin-bottom: 36px;
    .MuiFormControl-root,
    ${SelectWrapper} {
        width: 286px;
        min-height: unset;
        margin: unset;

        ${devices.desktopL} {
            width: 189px;
            min-height: 72px;
            margin-top: 20px;
        }

        label {
            color: ${theme.palette.text.main};
        }
    }

    ${SelectWrapper} {
        margin: 10px 0 0 0;
    }

    ${devices.desktopS} {
        flex-direction: column;
        width: 100%;
        height: auto;

        .MuiFormControl-root,
        ${SelectWrapper} {
            margin-top: 20px;
            width: 100%;
            &.multiple {
                width: 100%;
            }
        }
    }
`;

export const PagePreviewFilterWrapper = styled(PageValuesWrapper)`
    ${devices.desktopS} {
        flex-direction: column-reverse;
    }

    ${ButtonWrapper} {
        width: 151px;
        margin: 10px 0 0 auto;
    }
    ${SelectWrapper} {
        width: 100%;
        margin: 0;
        min-height: 72px;
    }
`;

export const PagePreviewLabel = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const MobilePageButtons = styled.div`
    display: flex;
    gap: 8px;
`;

export const AbTestingGroupSelectWrapper = styled.div`
    &:has(${InputLabelWithIconWrapper}) {
        margin-top: 4px;
    }
    .conditions-select__menu {
        right: 0;
    }

    ${devices.desktopS} {
        width: 100%;
        .conditions-select__menu {
            left: 0;
        }
    }
`;

export const LabeledStripeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 36px;
    .title {
        ${fontSizesCSS.Caption2}
    }
    .content {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
`;

export const LabeledImageBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${theme.palette.grey.main};
    label {
        ${fontSizesCSS.Caption1}
    }
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 252px;
        height: 142px;
        border-radius: 4px;
        border: 1px dashed ${theme.palette.grey.light};
    }
`;

export const toggleViewButtonStyle: CSSProperties = {
    margin: 0,
    padding: 0,
    width: '51px',
    height: '34px'
};

export const addModuleButtonStyle: CSSProperties = {
    margin: 0,
    padding: '6px 19px',
    height: '34px',
    width: 'fit-content'
};

export const pageOptionsButtonStyleDesktop: CSSProperties = {
    width: '151px',
    height: '36px',
    margin: 0
};
export const pageOptionsButtonStyleMobile: CSSProperties = {
    width: '151px',
    height: '36px'
};

export const AddModulestyle: CSSProperties = {
    height: '36px',
    minWidth: '184px',
    width: 'fit-content'
};

export const ModuleActionsContainer = styled.div`
    padding: 35px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .active {
        background-color: ${theme.palette.background.border};
    }
    .right {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .left {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .left-side {
        display: flex;
        align-items: center;
        label {
            margin-left: 10px;
            ${fontSizesCSS.Body2};
        }
        ${ButtonWrapper} {
            border: 1px solid ${theme.palette.background.border};
            ${ButtonInner} {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &:hover {
                background-color: ${theme.palette.background.border};
            }
        }
    }
    .right-side {
        display: flex;
        gap: 10px;
        ${ButtonWrapper} {
            ${fontSizesCSS.Caption2};
        }
    }
`;

export const ToggleViewButtonsWrapper = styled.div`
    display: flex;
`;

export const ToggleViewButton = styled(Button)<{ isActive?: boolean }>`
    margin: 0;
    padding: 0;
    width: 51px;
    height: 34px;
    ${ButtonInner} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        svg {
            path {
                stroke: white;
            }
        }
    }

    &:nth-child(1) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    &:nth-child(2) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    //Active state rules
    background-color: ${({ isActive }) => (isActive ? theme.palette.text.main : theme.palette.white.main)};
    svg {
        path {
            stroke: ${({ isActive }) => (isActive ? theme.palette.white.main : theme.palette.text.main)};
        }
    }
`;

//Module Separator

export const ModuleSeparatorWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const ModuleDividerDiv = styled.div`
    width: 382px;
    height: 2px;
`;

export const SVGAddIconContainer = styled.div`
    padding-top: 4px;
`;
export const SVGRoundIconContainer = styled.div`
    padding-top: 4px;
`;

export const ModuleSeparatorContainer = styled.div<{ $active?: boolean }>`
    padding: 20px 0px;
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    svg {
        border-radius: 2px;
        cursor: pointer;
        height: 16px;
        width: 16px;
        path {
            fill: ${theme.palette.grey.light};
            opacity: 0.5;
        }
    }
    ${ModuleDividerDiv} {
        background: ${theme.palette.grey.light};
    }
    ${SVGAddIconContainer} {
        display: none;
    }
    ${SVGRoundIconContainer} {
        display: block;
    }

    &:hover {
        svg {
            background: ${theme.palette.primary.main};
            path {
                fill: ${theme.palette.white.main};
                opacity: 1;
            }
        }
        ${ModuleDividerDiv} {
            background: ${theme.palette.primary.main};
        }
        ${SVGAddIconContainer} {
            display: block;
        }
        ${SVGRoundIconContainer} {
            display: none;
        }
    }
`;

export const ButtonsWrapper = styled.div`
    gap: 8px;
    display: flex;
    padding-top: 13px;
    margin-left: auto;
    align-items: center;
`;

export const MobileButtonsWrapper = styled.div`
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;

    ${ButtonWrapper} {
        margin: unset;
    }
`;

export const WarningContainer = styled.div`
    margin-left: 0;

    ${devices.tablet} {
        margin-left: auto;
    }
`;

export const PageOptionsContainer = styled.div`
    height: calc(338px /* backgroundImages part*/ + 155px /* seo part*/);
    ${devices.tablet} {
        height: calc(338px /* backgroundImages part*/ + 313px /* seo part*/);
    }
    padding: 24px 36px;
    background-color: ${theme.palette.background.main};
    margin-top: -36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const PageOptionsTitle = styled.div`
    ${fontSizesCSS.Body2};
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const PageSEOFields = styled.div`
    display: flex;
    gap: 24px;
    ${devices.tablet} {
        flex-direction: column;
    }
`;
export const SeoInputWrapper = styled.div`
    .MuiTextField-root {
        margin: unset;
    }
    width: 320px;
    ${devices.tablet} {
        width: 100%;
    }
`;

export const MissingAudiencesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
