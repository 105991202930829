import { logos } from '../assets/images/providerLogos';
import { Item, contentSourceTypes } from '../types/Item';
import { platformAssetstoreServices, SUPERADMIN_PREVIEW_PROJECT_ID_PARAM } from './Globals';
import configServiceAPI from './api/configServiceAPI';
import { DynamicData } from './api/dataHandlers/dynamicData';
import { buildYouboraFilter, parseUrlByService } from './fnUrl';
import noImage from '../assets/images/notAvailableLogo.png';

export const getDataByService = async (
    service: string,
    url: string = '',
    noOfItems?: number,
    specificAsset?: string,
    sourceId?: string,
    projectId?: string
) => {
    const dataService = service as contentSourceTypes;
    const previewItems = await fetchPreviews(url, dataService, noOfItems, specificAsset, sourceId, projectId);
    if (!previewItems?.length) return [];

    // since logos are kept on the FE, add it to the items after they come from the BE
    if (service === contentSourceTypes.PRIME_VIDEO) {
        for (const item of previewItems) {
            item.logo = logos.PrimeVideoLogo;
        }
    }

    if (service === contentSourceTypes.DISNEY_PLUS) {
        for (const item of previewItems) {
            item.logo = logos.DisneyLogo;
        }
    }

    if ([contentSourceTypes.YOUTUBE, contentSourceTypes.YOUTUBE_RECO].includes(dataService)) {
        for (const item of previewItems) {
            item.logo = logos.YoutubeLogo;
        }
    }

    return previewItems;
};

export const fetchPreviews = async (
    url: string,
    service: contentSourceTypes,
    noOfItems?: number,
    assetId?: string,
    sourceId?: string,
    projectId?: string
) => {
    const dynamicUrl = parseUrlByService(
        url,
        service,
        !!assetId ? ([contentSourceTypes.YOUTUBE, contentSourceTypes.YOUTUBE_RECO].includes(service) ? 50 : 999) : noOfItems
    );
    let result: any = null;
    try {
        result = (
            await configServiceAPI.getPreviews(
                encodeURIComponent(dynamicUrl),
                service,
                assetId,
                service === contentSourceTypes.ALLENTE_GATEWAY_STB ? undefined : noOfItems,
                sourceId,
                projectId
            )
        ).response as any;
    } catch (ex) {
        console.error(ex);
    }
    return result || [];
};

export const getDynamicItems = async (
    item: Item,
    firstElement?: boolean,
    templatePreview?: boolean
): Promise<DynamicData | DynamicData[]> => {
    const emptyDynamicData: DynamicData = { title: 'Preview Not Available', subtitle: '', image: noImage };
    let items: DynamicData[] = [];

    const projectId = templatePreview ? SUPERADMIN_PREVIEW_PROJECT_ID_PARAM : item.projectId || '';
    if (!item.dynamicSourceId) {
        return firstElement ? emptyDynamicData : [emptyDynamicData];
    }

    let { service, url } = item.dynamicSource || {};

    if ((!service || !url) && !templatePreview) {
        const source = (await configServiceAPI.getSourceById(item.dynamicSourceId))?.response as any;
        service = source.service;
        url = source.url;
    }

    items = await getDataByService(
        `${service}`,
        url,
        firstElement ? 1 : item.maxNumberOfItems,
        item.singleAsset,
        platformAssetstoreServices.includes(service || '') && !templatePreview ? item.dynamicSourceId : undefined,
        projectId
    );

    if (items.length) {
        return firstElement ? items[0] : items;
    }

    return firstElement ? emptyDynamicData : [emptyDynamicData];
};

// TO DO: after implementing the platform analytics this function should be adapted
export const getAnalyticsData = async (
    type: AnalyticsOptions,
    metrics: youboraMetrics[],
    startDate?: number,
    endDate?: number,
    filters?: { [key: string]: string }[],
    lastMonth?: boolean
) => {
    let result: any = null;
    let filterQuery: string = '';

    if (filters) {
        filterQuery = buildYouboraFilter(filters);
    }
    const analyticsHandlers = {
        [AnalyticsOptions.YOUBORA]: configServiceAPI.getYouboraData
    };
    try {
        result = (await analyticsHandlers[type]?.(metrics, startDate, endDate, filterQuery, lastMonth)).response as any;
    } catch (ex) {
        console.error(ex);
    }

    return result;
};

export enum AnalyticsOptions {
    YOUBORA = 'YOUBORA'
}

export enum youboraMetrics {
    VIEWS = 'views',
    HAPPINESS = 'avgHappiness',
    JOINTIME = 'joinTime', //um seconds, average
    BUFFERRATIO = 'bufferRatio',
    BUFFERTIME = 'bufferTime', //um seconds, sum
    AVERAGE_PLAYTIME = 'playtime',
    PLAYTIME = 'hours', //um hours, sum
    PLAYS_ERRORS = 'playsVsErrors',
    SUBSCRIBERS = 'subscribers',
    ABANDON_USERS = 'abandonUsers'
}

export enum youboraCountryFilters {
    US = 'United States',
    CANADA = 'Canada',
    SPAIN = 'Spain'
}

export enum youboraDeviceFilters {
    PC = 'PC',
    ANDROID = 'Android',
    IOS = 'IOS'
}

export type youboraHandlerResponse = {
    views?: number;
    avgHappiness?: number;
    joinTime?: number;
    bufferRatio?: number;
    bufferTime?: number;
    playtime?: number;
    hours?: number;
    playsVsErrors?: number;
    subscribers?: number;
    abandonUsers?: number;
};

export type youboraHandlerArrayResponse = {
    views?: any[];
    avgHappiness?: any[];
    joinTime?: any[];
    bufferRatio?: any[];
    bufferTime?: any[];
    playtime?: any[];
    hours?: any[];
    playsVsErrors?: any[];
    subscribers?: any[];
    abandonUsers?: any[];
};
