import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { getImgixUrl, getVideoUrl, imgixBaseUrl, imgixDefaultQuery, imgixFMBaseUrl } from '../../../utils/Globals';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { imgExtensions, ParsedFile, videoExtensions } from '../FileManager';
import { BreadcrumbsWrapper, FilePreviewDialogWrapper, FilePreviewWrapper, ImageWrapper } from '../FileManager.css';
import { Player } from '../Player';
import { IconButton, InputAdornment } from '@material-ui/core';
import SVGInline from 'react-inlinesvg';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import icons from '../../../assets/images/icons';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import { DIALOG_NAMES, ToastAlert } from '../../../utils/fnDialogs';
import { isOpenFileUrl } from '../../../utils/fnUrl';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

type FilePreviewProps = {
    open: boolean;
    onClose: () => void;
    file?: ParsedFile | string;
    fullUrl?: boolean;
};

export const FilePreview: FC<FilePreviewProps> = ({ open, file, fullUrl, onClose }) => {
    const [folderPath, setFolderPath] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [filePath, setFilePath] = useState<string>('');
    const [isOpenUrl, setIsOpenUrl] = useState<boolean>(false);

    const handleClose = () => {
        setFileName('');
        setFilePath('');
        setFolderPath('');
        setIsOpenUrl(false);
        onClose();
    };

    const closeButton: DialogButton = {
        label: 'Close',
        onClick: handleClose,
        type: 'BLUE'
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(filePath || '');
        ToastAlert('success', '', '', undefined, DIALOG_NAMES.COPY_URL);
    };

    useEffect(() => {
        if (!file || !open) return;

        if (typeof file === 'string') {
            const folderPath = file?.replace(imgixBaseUrl, '').replace(imgixFMBaseUrl, '').split('?')[0] || '';
            const fileName = _.last(folderPath.split('/')) || '';

            setFolderPath(folderPath);
            setFileName(fileName);
            setFilePath(file);
            setIsOpenUrl(isOpenFileUrl(file));
        } else {
            setFilePath(file.originalPath || '');
            setFileName(file.name);
        }
    }, [file, open]);
    const renderFilePreview = () => {
        const fileExtension = _.last(fileName.split('.'));

        if (!fileExtension && !isOpenUrl) return;
        if (imgExtensions.includes(fileExtension || '') || isOpenUrl) {
            return <ImageWrapper src={fullUrl ? filePath : getImgixUrl(filePath, imgixDefaultQuery, true)}></ImageWrapper>;
        }

        if (videoExtensions.includes(fileExtension || '')) {
            return <Player src={fullUrl ? filePath : getVideoUrl(filePath)} extension={fileExtension || ''} withControls />;
        }
    };

    const ClipboardAdornment = (
        <InputAdornment position="end">
            {renderTooltipWithKey(
                <IconButton
                    onClick={() => {
                        handleCopyClick();
                    }}
                >
                    <SVGInline src={icons.copyIcon} />
                </IconButton>,
                'file_manager_icon_preview_copy_url'
            )}
        </InputAdornment>
    );

    const getTooltipText = () => {
        const pathParts = folderPath.split('/');
        let tooltipText = '';
        pathParts.forEach(
            (part, index) =>
                (tooltipText = tooltipText.concat(
                    `${index === 0 ? 'File Manager' || '' : part} ${index !== pathParts.length - 1 ? '>' : ''} `
                ))
        );

        return tooltipText;
    };

    if (!open) return null;
    return (
        <GenericDialog
            title={!fullUrl ? fileName : isOpenUrl ? 'File Preview' : ''}
            type={isOpenUrl ? DialogTypes.Form : DialogTypes.FilePreview}
            onClose={handleClose}
            actionButtons={isOpenUrl ? [closeButton] : []}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.file_manager }}
            withTitleTooltip
        >
            <FilePreviewDialogWrapper>
                {!isOpenUrl ? (
                    renderTooltip(
                        <BreadcrumbsWrapper>
                            <Breadcrumbs unclickable path={folderPath} placeholder={'File Manager'} />{' '}
                        </BreadcrumbsWrapper>,
                        tooltipTypes.HTML,
                        getTooltipText(),
                        tooltipPositions.TOP_START
                    )
                ) : (
                    <DialogTextField
                        value={filePath || ''}
                        onChange={() => {}}
                        label={'File URL'}
                        InputProps={{ endAdornment: ClipboardAdornment }}
                    />
                )}

                <FilePreviewWrapper>{renderFilePreview()}</FilePreviewWrapper>
            </FilePreviewDialogWrapper>
        </GenericDialog>
    );
};
