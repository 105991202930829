import { Project } from './Project';
import { Tenant } from './Tenant';
import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';

export type Setting = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    conditionIds: string[];
    conditions?: any;
    pin?: PinSettings;
    miscellaneous?: MiscellaneousSettings;
    playback?: PlaybackSettings;
    customer?: CustomerSettings;
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    placed?: { name: string; _id: string; type: PlacedObjectType }[];

    published?: boolean;
    publishAt?: number;
    publishStatus?: PUBLISHED_STATUS;
    abTestingGroupIds?: string[];
};

export type PinSettings = {
    allowedWrongAttempts?: number;
    wrongAttemptsLockDuration?: number;
};
export type MiscellaneousSettings = {
    minimumVersion?: string;
};

export type CustomerSettings = {
    signupUrl?: string;
};

export type PlaybackSettings = {
    defaultVodSubtitleHandling?: boolean;
};

export enum SettingsUMTypes {
    PERCENTAGE = 'percentage',
    SECONDS = 'seconds',
    BOOLEAN = 'boolean',
    FREE_INPUT = 'free input',
    INTEGER = 'integer'
}

export const SUBSECTION_NAMES: any = {
    pin: 'Pin Settings',
    miscellaneous: 'Miscellaneous',
    customer: 'Customer',
    playback: 'Playback Settings'
};

export const DefaultSettingValues: Record<SettingsUMTypes, any> = {
    [SettingsUMTypes.PERCENTAGE]: 0,
    [SettingsUMTypes.SECONDS]: 0,
    [SettingsUMTypes.INTEGER]: 0,
    [SettingsUMTypes.FREE_INPUT]: '',
    [SettingsUMTypes.BOOLEAN]: true
};

export const settingsSections = {
    PIN: 'pin',
    PLAYBACK: 'playback',
    MISCELLANEOUS: 'miscellaneous',
    CUSTOMER: 'customer'
} as const;

export type SettingSectionType = typeof settingsSections[keyof typeof settingsSections];

export type SettingsValues = Partial<Record<SettingSectionType, { [key: string]: SettingsUMTypes }>>;
