import { FC } from 'react';
import { logos } from '../../assets/images/providerLogos';
import getTheme from '../../style/themes/theme';
import {
    ContentProviderButton,
    ContentProviderButtonsWrapper,
    ContentProviderCardWrapper,
    ContentProviderLogoContainer,
    ContentProviderMetadata,
    ContentProviderMetadataSubtitle,
    ContentProviderMetadataTitle,
    ContentProviderStatus,
    ContentProviderStatusContainer
} from './Aggregate.css';
import SVGInline from 'react-inlinesvg';

import icons from '../../style';

const supportedServicesLogos: Record<string, string> = {
    youtube: logos.YoutubeLogoAggregate,
    prime_video: logos.PrimeVideoLogoAggregate,
    stingray_karaoke: logos.StingrayLogoAggregate,
    dazn: logos.DAZNLogoAggregate,
    amagi: logos.AmagiLogoMonetize,
    magnite: logos.MagniteLogoMonetize,
    forScreen: logos.ForScreenLogoMonetize,
    google: logos.GoogleLogoMonetize,
    disney_plus: logos.DisneyLogoAggregate,
    horizon_sports: logos.HorizonSportsLogoAggregate,
    motor_racing: logos.MotorRacingLogoAggregate,
    swr3: logos.SWR3LogoAggregate
};

export enum serviceStates {
    AVAILABLE = 'AVAILABLE',
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

const serviceStateLabels = {
    [serviceStates.AVAILABLE]: 'Setup',
    [serviceStates.DISABLED]: 'Learn more',
    [serviceStates.ENABLED]: 'Settings'
};

export enum ContentProviderTypes {
    SERVICE = 'SERVICE',
    AD = 'AD'
}

const theme: any = getTheme();

type ContentProviderCardProps = {
    type: ContentProviderTypes;
    providerState: serviceStates;
    serviceKey: string;
    adMetadata?: { id: string; name: string };
    disabledService?: boolean;
    onSettingsClick: () => void;
    onPrimaryButtonClick?: () => void;
};

export const ContentProviderCard: FC<ContentProviderCardProps> = ({
    type,
    providerState,
    serviceKey,
    disabledService,
    adMetadata,
    onSettingsClick,
    onPrimaryButtonClick
}) => {
    const logoIsSvg = supportedServicesLogos[serviceKey]?.endsWith('.svg');
    const isAd = type === ContentProviderTypes.AD;
    const isEnabled = providerState === serviceStates.ENABLED;
    const isDisabled = providerState === serviceStates.DISABLED;
    const showProviderMetadata = isAd && isEnabled && !!adMetadata;

    return (
        <ContentProviderCardWrapper $isDisabled={isAd && isDisabled}>
            <ContentProviderLogoContainer
                $backgroundColor={
                    serviceKey === 'magnite'
                        ? theme.palette.black.main
                        : serviceKey === 'prime_video'
                        ? theme.palette.background.providers.amazon
                        : undefined
                }
                disabled={disabledService}
            >
                {logoIsSvg ? <SVGInline src={supportedServicesLogos[serviceKey]} /> : <img src={supportedServicesLogos[serviceKey]} />}
            </ContentProviderLogoContainer>
            <ContentProviderStatus>
                {isEnabled && <SVGInline src={icons.checkmarkCircularFilledIcon} />}
                <ContentProviderStatusContainer>
                    {isAd && isDisabled ? 'Coming soon' : showProviderMetadata ? `ID ACCOUNT ${adMetadata?.id}` : providerState}
                </ContentProviderStatusContainer>
            </ContentProviderStatus>

            {showProviderMetadata && (
                <ContentProviderMetadata>
                    <ContentProviderMetadataSubtitle> Ad network account Name: </ContentProviderMetadataSubtitle>
                    <ContentProviderMetadataTitle>{adMetadata?.name}</ContentProviderMetadataTitle>
                </ContentProviderMetadata>
            )}

            <ContentProviderButtonsWrapper>
                {isAd && (
                    <ContentProviderButton
                        onClick={onPrimaryButtonClick}
                        label={isEnabled ? 'Open' : 'Connect'}
                        type={isEnabled ? 'BLUE' : 'DEFAULT'}
                    />
                )}
                <ContentProviderButton
                    onClick={onSettingsClick}
                    label={isAd ? 'Settings' : serviceStateLabels[providerState]}
                    type={'DEFAULT'}
                />
            </ContentProviderButtonsWrapper>
        </ContentProviderCardWrapper>
    );
};
