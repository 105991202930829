import styled from 'styled-components';
import { SelectWrapper } from '../common/Select/Select.css';
import getTheme from '../../style/themes/theme';

const theme: any = getTheme();

export const SearchBarContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.04);
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    padding: 24px;
    display: flex;
    gap: 16px;
`;

export const SearchBarWrapper = styled.div`
    flex: 1;

    ${SelectWrapper} {
        margin: 0;
        min-height: unset;
        transform-origin: bottom left;

        [class$='placeholder'] {
            color: ${theme.palette.text.main};
            opacity: 0.5;
        }
        [class$='container'] {
            height: fit-content;
            min-height: 38px;
            margin-top: 0;
        }
        [class$='menu'] {
            position: absolute;
            top: 100%;
        }
        [class$='control'] {
            height: fit-content;
            box-shadow: none;
            &:hover {
                border-color: ${theme.palette.background.border};
            }
        }
    }
`;

export const SelectPageSize = styled.div`
    width: 144px;
    height: 38px;

    [class$='container'] {
        min-height: 38px;
        margin-top: 0;
        height: 38px;
    }

    ${SelectWrapper} {
        height: 38px;
        margin: 0;
    }

    .cc_select__control {
        height: 38px;
    }
`;

export const PaginationContainer = styled.div<{ $extraPadding?: boolean }>`
    ${(props) => props.$extraPadding && 'padding-bottom: 72px'};
    justify-content: flex-end;
    margin-top: 24px;
    display: flex;
`;
