import styled, { css, CSSProperties } from 'styled-components';
import fileManagerFolderListImg from '../../assets/images/listFolder.svg';
import fileManagerFolderGridImg from '../../assets/images/gridFolder.svg';
import getTheme, { fontSizes, fontSizesCSS, zIndexesCSS } from '../../style/themes/theme';
import { ToggleViewSwitchWrapper } from '../common/Switch/ToggleViewSwitch.css';
import { ToggleContainer } from '../common/Dialog/GenericDialog.css';
import { devices, MainContentWrapper, ScreenActions } from '../../style/styled-components/reusable.css';
import { TableRow } from '@material-ui/core';
import { SelectWrapper } from '../common/Select/Select.css';
import { WrapperBar } from '../common/Loader/Loader.css';
import { ButtonWrapper } from '../Buttons/Button/Button.css';

const theme: any = getTheme();

export const FileManagerImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
`;

export const FileManagerNameCell = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;

export const FileManagerName = styled.div<{ isPointer?: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 560px;
    color: ${theme.palette.primary.main};
    ${fontSizes.Body1};
    ${(props) => props.isPointer && 'cursor: pointer'};

    ${devices.desktopS} {
        max-width: 240px;
    }

    ${devices.tablet} {
        max-width: 140px;
    }

    ${devices.mobileL} {
        max-width: 70px;
    }
`;
export const FileManagerContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 6px 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const FileManagerFolder = styled.div<{ isGrid?: boolean }>`
    width: 100%;
    height: 100%;
    background: ${(props) => (props.isGrid ? `url(${fileManagerFolderGridImg})` : `url(${fileManagerFolderListImg})`)};
    background-size: cover;
    background-position: center;
    display: flex;
    padding: ${(props) => (props.isGrid ? '0 2px' : '0')};
    box-szing: border-box;

    ${FileManagerContainer} {
        padding: ${(props) => (props.isGrid ? '6px 2px' : '4px 0')};
    }
`;

export const FileManagerUploadingContainer = styled.div<{ $isGrid?: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    ${WrapperBar} {
        width: ${({ $isGrid }) => ($isGrid ? '80px' : '40px')};
        margin-top: ${({ $isGrid }) => ($isGrid ? '82px' : '41px')};
    }
`;

export const FilePreviewCell = styled.div<{ $isFolder?: boolean }>`
    position: relative;
    width: 48px;
    height: 48px;
    background: ${({ $isFolder }) => (!$isFolder ? theme.palette.background.border : 'transparent')};
    border-radius: 3px;
`;

export const FileSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1px;
    box-sizing: border-box;
`;

export const FileSideSection = styled.div`
    height: 100%;
    width: 50%;
    padding: 7px 0;
    box-sizing: border-box;
`;

export const FileImage = styled.div<{ background?: string }>`
    width: 100%;
    height: 100%;
    margin: 2px 1px;
    background: ${(props) => (props.background ? `url('${props.background}')` : theme.palette.text.main)};
    background-size: cover;
    background-position: center;
`;

export const FilePreviewWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilePreviewDialogWrapper = styled.div`
    height: 100%;
    width: 100%;
    nav.MuiBreadcrumbs-root {
        position: absolute;
        max-width: 80%;
        width: 100%;
        ol > li > p {
            ${fontSizes.Title3};
        }
    }
`;

export const BreadcrumbsWrapper = styled.div`
    position: absolute;
    background: red;
    width: 100%;
    top: -61px;
`;

export const ImageWrapper = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const VideoWrapper = styled.video`
    width: 100%;
    height: 100%;
`;

export const VideoTooltip = styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 999;
    svg {
        width: 24px;
        height: 24px;

        path {
            fill: ${theme.palette.white.main};
        }
    }
`;

export const FileIconContainer = styled.div<{ tableView?: boolean }>`
    width: ${(props) => (props.tableView ? '24px' : '32px')};
    height: ${(props) => (props.tableView ? '24px' : '32px')};
    svg,
    img {
        width: ${(props) => (props.tableView ? '16px' : '24px')};
        height: ${(props) => (props.tableView ? '16px' : '24px')};
    }
    border-radius: 50%;
    background: ${theme.palette.background.border};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FilePlacedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 160px;
    overflow-y: auto;
    p {
        margin: 0;
    }
`;

export const FilesGridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px 0 0 0;

    ${devices.tablet} {
        gap: 15px;
    }

    ${devices.mobileL} {
        gap: 16px;
    }
`;

export const FileCardWrapper = styled.div`
    width: 216px;
    height: 164px;
    cursor: pointer;

    ${devices.desktopS} {
        width: 192px;
        height: 152px;
    }

    ${devices.tablet} {
        width: 182px;
        height: 146px;
    }

    ${devices.mobileL} {
        width: 220px;
        height: 167px;
    }
`;

export const FileCardImageWrapper = styled.div<{ isFolder?: boolean }>`
    width: 100%;
    height: 124px;
    border-radius: 4px;
    background: ${(props) => (props.isFolder ? 'transparent' : theme.palette.grey.light)};
    position: relative;

    ${devices.desktopS} {
        height: 110px;
    }

    ${devices.tablet} {
        height: 105px;
    }

    ${devices.mobileL} {
        height: 126px;
    }
`;

export const FileCardImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
`;

export const FileCardDetails = styled.div`
    flex: 1;
    overflow: hidden;
`;
export const FileCardDetailsHolder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const VideoFileCardEncodeStatusHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    svg {
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }
`;

export const FileCardTitle = styled.div`
    color: ${theme.palette.primary.main};
    ${fontSizes.Body1};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const FileCancelUploadText = styled.div<{ listView?: boolean }>`
    color: ${theme.palette.primary.main};
    ${fontSizes.Caption3};
    ${({ listView }) => listView && fontSizes.Body1}
    &:hover {
        cursor: pointer;
    }
`;

export const FileCardDescription = styled.div`
    ${fontSizes.Caption3};
    display: flex;
    gap: 8px;
    width: 100%;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const ActionsHolder = styled.div`
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
    right: 8px;
    background: ${theme.palette.background.border};
    border-radius: 4px;
    z-index: 1;
`;

export const SearchAndToggleContainer = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    ${ToggleViewSwitchWrapper} {
        margin-left: auto;

        ${ToggleContainer} {
            margin-top: 0;
        }
    }
`;

export const FileManagerCardActions = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.palette.background.hovered};
    z-index: 1;
    border-radius: 4px;

    svg {
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const FileManagerStyledDraggableRow = styled(TableRow)<{ isDragging?: boolean; isHovering?: boolean; isDisabled?: boolean }>`
    opacity: ${({ isDragging }) => (isDragging ? 0.7 : 1)};
    ${({ isHovering }) =>
        isHovering &&
        css`
            background: ${theme.palette.background.pressed};
        `};
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
        `};
`;

export const FileManagerDraggableFileCardWrapper = styled(FileCardWrapper)<{
    isDragging?: boolean;
    isHovering?: boolean;
    isDisabled?: boolean;
}>`
    opacity: ${({ isDragging }) => (isDragging ? 0.7 : 1)};
    ${({ isHovering }) =>
        isHovering &&
        css`
            position: relative;
            &:after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: ${theme.palette.background.border};
                border-radius: 4px;
            }
        `};
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
        `};
`;

export const DraggedFilePreview = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px;
    box-sizing: border-box;
    position: fixed;
    height: 40px;
    top: 0;
    left: 5px;
    min-width: 168px;
    background-color: ${theme.palette.background.menuItem};
    border-radius: 4px;
    box-shadow: 0 1px 4px ${theme.palette.background.border};
    ${fontSizesCSS.Caption3};
`;

export const DragAndDropSuggestionText = styled.div`
    background: ${theme.palette.background.main};
    ${fontSizesCSS.Caption2};
    height: 72px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
`;

export const UploadDropZoneContainer = styled.div<{ isVisible: boolean; inDialog?: boolean }>`
    position: ${({ inDialog }) => (inDialog ? 'absolute' : 'fixed')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999;
    border-radius: 4px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
    background: ${theme.palette.primary.mainTranslucent};
    border: 3px dashed ${theme.palette.primary.main};
    transition: all 300ms ease-in-out;
    ${DragAndDropSuggestionText} {
        height: 36px;
    }
`;

export const DragAndDropSuggestionWrapper = styled.div`
    position: absolute;
    bottom: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 352px;
    opacity: 0;
    transition: all 300ms ease-in-out;
    pointer-events: none;
`;

export const CloudIconWrapper = styled.div`
    background: ${theme.palette.background.main};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 8px;
    box-sizing: border-box;
    animation: bounce 700ms linear infinite alternate;
    @keyframes bounce {
        0% {
            transform: translateY(-5px);
        }
        100% {
            transform: translateY(5px);
        }
    }
`;
export const FileManagerDNDSuggestionZone = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    position: relative;

    &:after {
        position: absolute;
        height: 100px;
        content: '';
        bottom: -100px;
        right: 0;
        left: 0;
    }
    &:hover {
        ${DragAndDropSuggestionWrapper} {
            opacity: 1;
        }
    }
`;

export const SearchFileWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    ${ToggleViewSwitchWrapper} {
        label {
            margin-left: auto;
        }
    }
    .MuiFormControl-root .MuiInputBase-root {
        background: unset; // fixes the cut off the corner radius for the input
    }
    .MuiFormControl-root.MuiTextField-root {
        min-height: unset;
        width: 320px;
    }
`;

export const FileManagerButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: 16px;
    top: -74px;
    right: 35px;
    margin: 8px 36px;

    ${ButtonWrapper} {
        width: 136px;
        margin: 0;
    }
`;

export const DialogFileManagerWrapper = styled.div`
    overflow: hidden;
    height: 100%;
    nav.MuiBreadcrumbs-root {
        position: absolute;
        max-width: 60%;
        top: -61px;
        ol > li > p {
            ${fontSizes.Title3};
        }
    }
    .MuiFormControl-root {
        height: 38px;
        min-height: unset;
    }
    .MuiInputBase-root {
        border-radius: 4px;
    }
    ${FileManagerDNDSuggestionZone} {
        height: 50px;
        margin-top: -25px;
        &:after {
            position: relative;
        }
    }
`;

export const ScrollWrapper = styled.div`
    height: calc(100% - 86px - 40px - 16px - 48px); // 86 breadcrumbs 40 filter tabs 16 padding 48 margin
    overflow-y: auto;
    position: relative;
    padding-right: 4px;
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        margin-left: 4px;
        margin-top: 24px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${theme.palette.background.border};
        border-radius: 8px;
    }
`;
export const GroupedByBreadcrumbsContainer = styled.div`
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    p {
        color: ${theme.palette.primary.main};
        ${fontSizesCSS.Caption4};
        font-weight: bold;
    }
    li {
        margin: 0;
        svg {
            padding: 0;
        }
        &:first-child {
            display: none;
        }
        &:nth-child(2) {
            display: none;
        }
    }
`;

export const FileManagerFiltersContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
`;
export const FileManagerFilterLabel = styled.div<{ active?: boolean }>`
    ${fontSizesCSS.Body2};
    padding: 8px 16px;
    background-color: ${(props) => (props.active ? theme.palette.background.light : 'none')};
    color: ${(props) => (props.active ? theme.palette.black.main : theme.palette.grey.dark)};
    &:hover {
        cursor: pointer;
    }
`;

export const ExtraFilters = styled.div`
    margin-left: auto;
`;
export const VideoFiltersSelectContainer = styled.div`
    width: 160px;
    ${SelectWrapper} {
        min-height: unset;
        margin: 0;
        label {
            display: none;
        }
    }
`;

export const FileManagerContentWrapper = styled(MainContentWrapper)`
    padding-top: 0;
    ${ScreenActions} {
        background: ${theme.palette.background.default};
        position: sticky;
        top: 0;
        padding-top: 36px;
        z-index: ${zIndexesCSS.zIndexProfile};
    }
    overflow: unset;
`;

export const SortDropdown = styled.div`
    min-width: 280px;
    ${devices.tablet} {
        width: 100%;
    }

    ${SelectWrapper} {
        min-height: unset;
        height: 36px;
    }
`;

export const FilterAndSortContainer = styled.div`
    background: ${theme.palette.background.default};
    position: sticky;
    top: 108px;
    z-index: ${zIndexesCSS.zIndexPageButtons};
`;

export const SortContainer = styled.div<{ $extraWidth?: boolean }>`
    display: flex;
    gap: 16px;

    ${devices.tablet} {
        ${(props) => props.$extraWidth && 'width: 100%'}
    }
`;
