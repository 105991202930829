import React, { FC } from 'react';
import { ToggleViewSwitchWrapper } from './ToggleViewSwitch.css';
import { ToggleCheckBox, ToggleContainer, ToggleLabel, ToggleSlider } from '../Dialog/GenericDialog.css';
import icons from '../../../style';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';

const ToggleViewSwitch: FC<{ checked: boolean; toggleCallback: () => void; tooltipTexts?: { list: string; grid: string } }> = ({
    checked,
    toggleCallback,
    tooltipTexts
}: any) => {
    return (
        <ToggleViewSwitchWrapper leftIcon={`url(${icons.listGreyFilledIcon})`} rightIcon={`url(${icons.menuGreyFilledIcon})`}>
            {renderTooltipWithKey(
                <ToggleContainer>
                    <ToggleLabel>
                        <ToggleCheckBox
                            type={'checkbox'}
                            checked={checked}
                            onChange={() => {
                                toggleCallback();
                            }}
                        />
                        <ToggleSlider />
                    </ToggleLabel>
                </ToggleContainer>,
                `${checked ? tooltipTexts?.list : tooltipTexts?.grid}`,
                undefined,
                'toggle_view_switch'
            )}
        </ToggleViewSwitchWrapper>
    );
};

export default ToggleViewSwitch;
