import React, { FC, useEffect, useState } from 'react';
import icons from '../../assets/images/icons';
import { Item, itemTypes } from '../../types/Item';
import {
    ActionButtonContainer,
    AddLogoContainer,
    AddLogoIcon,
    AppliedForAllText,
    BackgroundContainer,
    BackgroundSelector,
    BackgroundSwitch,
    BackgroundSwitchContainer,
    ButtonWrapper,
    CardContainer,
    CardDescription,
    CardImage,
    CardTitle,
    DeviceBackgroundSVG,
    EditButtons,
    EditorialHeroInfo,
    EditorialTypeContainer,
    EditorialViewContainer,
    EditTextField,
    LaneWrapper,
    Picture
} from './EditorialView.css';
import SVGInline from 'react-inlinesvg';
import noImage from '../../assets/images/notAvailableLogo.png';
import Button from '../Buttons/Button/Button';
import BackgroundDialog, { supportedResolutionsType } from './Dialogs/BackgroundDialog';
import ActionDialog from './Dialogs/ActionDialog';
import PageActions from '../common/PageActions/PageActions';
import LogoDialog from './Dialogs/LogoDialog';
import { calculateItemBackgroundToShow, generateGradientValues } from '../../utils/fnColor';
import _ from 'lodash';
import { getDynamicItems } from '../../utils/fnPreviews';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import {
    BackgroundDropZone,
    BackgroundSelectionButton,
    BackgroundSelectionContainer,
    DragAndDropTextWrapper,
    GradientBackgroundToRight,
    GradientBackgroundToRightV2,
    GradientBackgroundToTop,
    GradientBackgroundToTopV2,
    LogoContainer,
    ProviderLogo
} from '../PageEdit/VisualEditor.css';
import { DynamicData } from '../../utils/api/dataHandlers/dynamicData';
import { ScreenTypes } from '../../types/AppBranding';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import {
    DescriptionContainer,
    SubTitleContainer,
    TitleContainer
} from '../PageEdit/EditorialItemCustomization/EditorialItemCustomization.css';
import { UNSAFE_useEffectOnce } from '../../hooks/useEffectOnce';
import { CloseButtonWrapper } from '../Modules/VisualEditor.css';
import { useAppSelector } from '../../hooks/redux';
import { ItemState } from '../../redux/slices/itemSlice';
import { renderABIcon } from '../../utils/fnLockingSystem';
import BackgroundSwitcher from './BackgroundSwitcher';

type EditorialViewProps = {
    type: itemTypes;
    item: Item;
    onSave: () => void;
    onEdit?: () => void;
    onDuplicate?: () => void;
    onRemove?: () => void;
    onCancel?: () => void;
    setItemToSave?: (item: Item | null) => void;
    isTemplate?: boolean;
    itemActions?: Actions[];
};

type CardProps = {
    title?: string;
    description?: string;
    image?: string;
    logo?: string;
};

export enum Actions {
    EDIT = 1,
    DUPLICATE,
    REMOVE
}

export const resolutionsIcons: supportedResolutionsType = {
    bigScreen: icons.tvIcon,
    pc: icons.desktopIcon,
    tablet: icons.tabletIcon,
    mobile: icons.phoneIcon
};

export const resolutionsIconsWithAdd: supportedResolutionsType = {
    bigScreen: icons.tvAddIcon,
    pc: icons.desktopAddIcon,
    tablet: icons.tabletAddIcon,
    mobile: icons.phoneAddIcon
};

export const ItemCard: FC<CardProps> = ({ title = '', description = '', image, logo }) => {
    return (
        <CardContainer>
            <CardImage>
                {logo && (
                    <LogoContainer gradientsValues={generateGradientValues(0, 1, 10)}>
                        <ProviderLogo $logo={logo} />
                    </LogoContainer>
                )}
                <Picture src={image || noImage} />
            </CardImage>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
        </CardContainer>
    );
};

export const EditorialView: FC<EditorialViewProps> = ({
    type,
    item,
    isTemplate,
    itemActions,
    onEdit,
    onRemove,
    onDuplicate,
    onSave,
    onCancel,
    setItemToSave
}) => {
    const { createOrModifyLoading }: ItemState = useAppSelector((state) => state.items);

    const actions = itemActions || [Actions.EDIT, Actions.DUPLICATE, Actions.REMOVE];
    const [dynamicData, setDynamicData] = useState<DynamicData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [title, setTitle] = useState<string>(item?.title || '');
    const [subtitle, setSubtitle] = useState<string>(item?.subtitle || '');
    const [description, setDescription] = useState<string>(item?.description || '');
    const [isOpenBgDialog, setIsOpenBgDialog] = useState<boolean>(false);
    const [isOpenActionDialog, setIsOpenActionDialog] = useState<boolean>(false);
    const [isOpenLogoDialog, setIsOpenLogoDialog] = useState<boolean>(false);

    const [logo, setLogo] = useState<string | undefined>(item?.logo);
    const [backgroundImage, setBackgroundImage] = useState<supportedResolutionsType | undefined>(item?.backgroundImage || undefined);
    const [backgroundColor, setBackgroundColor] = useState(item?.backgroundColor || undefined);
    const [videoForAutoplay, setVideoForAutoplay] = useState(item?.videoForAutoplay || '');

    const [action, setAction] = useState<any | undefined>(item?.action);
    const [actionName, setActionName] = useState<string | undefined>(item?.actionName);
    const [activeResolution, setActiveResolution] = useState<keyof typeof resolutionsIcons>('bigScreen');
    const [isEditingHeroBG, setIsEditingHeroBG] = useState<boolean>(false);
    const [heroBackgroundImage, setHeroBackgroundImage] = useState<ScreenTypes | undefined>(item?.heroBackgroundImage || undefined);
    const [heroBackgroundColor, setHeroBackgroundColor] = useState<string | undefined>(item?.heroBackgroundColor || undefined);
    const [droppedFile, setDroppedFile] = useState<string | undefined>(undefined);
    const [extensionError, setExtensionError] = useState<undefined | string>(undefined);

    const checkShowUnsaved = () => {
        const initialValues = {
            title: item?.title || '',
            subtitle: item?.subtitle || '',
            description: item?.description || '',
            backgroundColor: item?.backgroundColor || '',
            backgroundImage: item?.backgroundImage ? JSON.stringify(item.backgroundImage) : '',
            heroBackgroundColor: item?.heroBackgroundColor || undefined,
            heroBackgroundImage: item?.heroBackgroundImage ? JSON.stringify(item.heroBackgroundImage) : undefined,
            actionName: item?.actionName || '',
            action: item?.action ? JSON.stringify(item.action) : '',
            logo: item?.logo || '',
            videoForAutoplay: item?.videoForAutoplay || ''
        };

        const currentValues = {
            title,
            subtitle,
            description,
            backgroundColor: backgroundColor ? backgroundColor : '',
            backgroundImage: backgroundImage ? JSON.stringify(backgroundImage) : '',
            heroBackgroundColor: heroBackgroundColor ? heroBackgroundColor : undefined,
            heroBackgroundImage: heroBackgroundImage ? JSON.stringify(heroBackgroundImage) : undefined,
            actionName: actionName || '',
            action: action ? JSON.stringify(action) : '',
            logo: logo || '',
            videoForAutoplay
        };

        return !_.isEqual(initialValues, currentValues);
    };

    useEffect(() => {
        if (checkShowUnsaved()) {
            const newItem: Item = {
                ...item,
                title,
                subtitle,
                description,
                backgroundColor,
                backgroundImage,
                heroBackgroundColor,
                heroBackgroundImage,
                actionName,
                action,
                logo,
                videoForAutoplay
            };
            setItemToSave && setItemToSave(newItem);
        } else {
            setItemToSave && setItemToSave(null);
        }
    }, [
        title,
        subtitle,
        description,
        logo,
        videoForAutoplay,
        backgroundColor,
        backgroundImage,
        heroBackgroundColor,
        heroBackgroundImage,
        action,
        actionName
    ]);

    const renderEditorialView = () => {
        const handleBackgroundSave = (backgroundValue: string | supportedResolutionsType, autoplay?: string, isColor?: boolean) => {
            if (isEditingHeroBG) {
                setHeroBackgroundColor(isColor && typeof backgroundValue === 'string' ? backgroundValue : '');
                setHeroBackgroundImage(!isColor && typeof backgroundValue !== 'string' ? backgroundValue : undefined);
            } else {
                setBackgroundColor(isColor && typeof backgroundValue === 'string' ? backgroundValue : '');
                setBackgroundImage(!isColor && typeof backgroundValue !== 'string' ? backgroundValue : undefined);
                setVideoForAutoplay(autoplay || '');
            }
            handleBackgroundClose();
        };
        const handleBackgroundClose = () => {
            setIsOpenBgDialog(false);
            setIsEditingHeroBG(false);
            setDroppedFile(undefined);
            setExtensionError(undefined);
        };
        const handleActionSave = (actionName: string, action: any) => {
            setActionName(actionName);
            setAction(action);
            handleActionClose();
        };
        const handleActionClose = () => {
            setIsOpenActionDialog(false);
        };
        const handleLogoClose = () => {
            setIsOpenLogoDialog(false);
        };
        useEffect(() => {
            setTitle(item?.title || '');
            setSubtitle(item?.subtitle || '');
            setDescription(item?.description || '');
            setAction(item.action);
            setActionName(item.actionName);
            setLogo(item.logo);
            setBackgroundImage(item.backgroundImage);
            setBackgroundColor(item.backgroundColor);
            setHeroBackgroundColor(item.heroBackgroundColor);
            setHeroBackgroundImage(item.heroBackgroundImage);
            setVideoForAutoplay(item.videoForAutoplay || '');
        }, [item]);

        const buttonLabel = (
            <ButtonWrapper>
                {_.truncate(actionName, { length: 20, omission: '...' }) || 'Action'}
                {actionName && !isTemplate && <TranslationTooltip translationKey={actionName} />}
            </ButtonWrapper>
        );

        const handleDrop = (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            const { files } = e.dataTransfer;
            if (files && files.length) {
                const { type } = files[0];
                if (!['jpeg', 'png'].includes(type.split('/')[1])) {
                    setExtensionError('Incorrect file extension, can upload only JPG or PNG');
                }
                setDroppedFile(files);
                setIsOpenBgDialog(true);
                setIsEditingHeroBG(true);
            }
        };

        return (
            <EditorialTypeContainer
                $background={calculateItemBackgroundToShow(
                    { heroBackgroundColor, heroBackgroundImage, backgroundColor, backgroundImage },
                    true,
                    undefined,
                    activeResolution
                )}
            >
                <GradientBackgroundToTopV2 black={10} transparent={30} />
                <GradientBackgroundToRightV2 black={30} transparent={100} />
                <BackgroundContainer>
                    <BackgroundSelector
                        $background={calculateItemBackgroundToShow(
                            {
                                heroBackgroundColor,
                                heroBackgroundImage,
                                backgroundColor,
                                backgroundImage
                            },
                            false,
                            undefined,
                            activeResolution
                        )}
                        onClick={() => {
                            setIsOpenBgDialog(true);
                        }}
                    >
                        {backgroundImage?.[activeResolution] || backgroundColor
                            ? renderTooltipWithKey(<SVGInline src={icons.editLightIcon} />, 'item_editorial_icon_edit')
                            : renderTooltipWithKey(<SVGInline src={icons.addIconWhite} />, 'item_editorial_icon_add')}
                    </BackgroundSelector>
                    <BackgroundSwitcher
                        activeResolution={activeResolution}
                        setActiveResolution={setActiveResolution}
                        backgroundImage={backgroundImage}
                        backgroundColor={backgroundColor}
                        setIsOpenBgDialog={setIsOpenBgDialog}
                    />
                </BackgroundContainer>
                <EditorialHeroInfo>
                    <AddLogoContainer
                        onClick={() => {
                            setIsOpenLogoDialog(true);
                        }}
                    >
                        <AddLogoIcon $preview={logo ? decodeURIComponent(logo) : ''}>
                            {!logo && <SVGInline src={icons.iconAddLogo} />}
                        </AddLogoIcon>
                        <span>Click to {!logo ? 'add' : 'edit'} logo</span>
                    </AddLogoContainer>
                    <TitleContainer>
                        <EditTextField
                            placeholder={'Click to edit text'}
                            value={title}
                            onChange={(e: any) => {
                                setTitle(e.target.value);
                            }}
                            withTranslations={!isTemplate}
                            isVisualEditor
                        />
                        {renderABIcon(item)}
                    </TitleContainer>
                    <SubTitleContainer>
                        <EditTextField
                            placeholder={'Click to edit Subtitle'}
                            value={subtitle}
                            onChange={(e: any) => {
                                setSubtitle(e.target.value);
                            }}
                            withTranslations={!isTemplate}
                            isVisualEditor
                        />
                    </SubTitleContainer>
                    <DescriptionContainer>
                        <EditTextField
                            placeholder={
                                'Click to edit description text. this text can not be longer then this and the second row listed here. '
                            }
                            value={description}
                            onChange={(e: any) => {
                                setDescription(e.target.value);
                            }}
                            withTranslations={!isTemplate}
                            isVisualEditor
                        />
                    </DescriptionContainer>
                    <ActionButtonContainer>
                        <Button
                            type={'BLUE'}
                            label={buttonLabel}
                            onClick={() => {
                                setIsOpenActionDialog(true);
                            }}
                        />
                    </ActionButtonContainer>
                </EditorialHeroInfo>
                <BackgroundDropZone
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                />
                <BackgroundSelectionContainer
                    onClick={() => {
                        setIsOpenBgDialog(true);
                        setIsEditingHeroBG(true);
                    }}
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                >
                    <BackgroundSelectionButton>
                        <SVGInline src={icons.photoIcon} />
                    </BackgroundSelectionButton>
                    <DragAndDropTextWrapper>
                        {heroBackgroundImage || heroBackgroundColor ? 'Click to change or Drag and Drop' : 'Drag and Drop or Browse Files'}
                    </DragAndDropTextWrapper>
                </BackgroundSelectionContainer>

                <PageActions
                    onSave={() => onSave()}
                    onCancel={onCancel}
                    disabled={{ save: createOrModifyLoading || !checkShowUnsaved() }}
                />
                <BackgroundDialog
                    open={isOpenBgDialog}
                    onSave={(backgroundValue, autoplay, isColor) => handleBackgroundSave(backgroundValue, autoplay, isColor)}
                    onClose={handleBackgroundClose}
                    background={isEditingHeroBG ? heroBackgroundColor || heroBackgroundImage : backgroundColor || backgroundImage}
                    isColor={droppedFile ? false : isEditingHeroBG ? !!heroBackgroundColor : !!backgroundColor}
                    backgroundAutoplay={videoForAutoplay}
                    isHero={isEditingHeroBG}
                    droppedFile={droppedFile}
                    extensionError={extensionError}
                    isTemplate={isTemplate}
                />
                <ActionDialog
                    open={isOpenActionDialog}
                    action={action}
                    name={actionName}
                    onSave={(actionName, action) => handleActionSave(actionName, action)}
                    onClose={handleActionClose}
                    isTemplate={isTemplate}
                />
                <LogoDialog
                    logo={logo || ''}
                    open={isOpenLogoDialog}
                    onSave={(newLogo) => {
                        setLogo(newLogo || '');
                        setIsOpenLogoDialog(false);
                    }}
                    onClose={handleLogoClose}
                    isTemplate={isTemplate}
                />
            </EditorialTypeContainer>
        );
    };

    const renderDynamicView = () => {
        UNSAFE_useEffectOnce(() => {
            loadDynamicData();
        }, [item.dynamicSourceId, item.contentSourceType, item.maxNumberOfItems]);

        const loadDynamicData = async () => {
            setLoading(true);
            const items = await getDynamicItems(item, false, isTemplate);
            if (Array.isArray(items)) {
                setDynamicData(items);
            }
        };

        useEffect(() => {
            if (!dynamicData.length) return;
            setLoading(false);
        }, [dynamicData]);

        const data = item.singleAsset ? dynamicData.slice(0, 1) : dynamicData;

        if (loading) return;

        return (
            <LaneWrapper>
                {data.map((card, index) => {
                    return (
                        <ItemCard key={index} title={card?.title} description={card?.description} image={card?.image} logo={card?.logo} />
                    );
                })}
            </LaneWrapper>
        );
    };

    const handleIconClick = (evt: any, type: Actions) => {
        evt.preventDefault();
        switch (type) {
            case Actions.EDIT:
                if (onEdit) {
                    onEdit();
                }
                break;
            case Actions.REMOVE:
                if (onRemove) {
                    onRemove();
                }
                break;
            case Actions.DUPLICATE:
                if (onDuplicate) {
                    onDuplicate();
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <EditorialViewContainer $itemType={type}>
                <EditButtons>
                    {actions.map((action, index) => {
                        if (action === Actions.DUPLICATE) return null;

                        const icon =
                            action === Actions.EDIT
                                ? icons.editLightIcon
                                : action === Actions.REMOVE
                                ? icons.trashLightIcon
                                : icons.duplicateLightIcon;
                        const tooltipText =
                            action === Actions.EDIT
                                ? isTemplate
                                    ? 'superadmin_items_templates_icon_edit'
                                    : 'items_icon_edit'
                                : action === Actions.REMOVE
                                ? isTemplate
                                    ? 'superadmin_items_templates_icon_delete'
                                    : 'items_icon_delete'
                                : 'items_icon_duplicate';

                        return renderTooltipWithKey(
                            <SVGInline key={`${index}_icon`} src={icon} onClick={(evt) => handleIconClick(evt, action)} />,
                            tooltipText
                        );
                    })}
                </EditButtons>
                {type === itemTypes.DYNAMIC ? renderDynamicView() : renderEditorialView()}
                <CloseButtonWrapper onClick={() => onCancel && onCancel()}>
                    <SVGInline src={icons.closeIcon} />
                </CloseButtonWrapper>
            </EditorialViewContainer>
        </>
    );
};
