import React, { FC, useEffect, useState } from 'react';
import { Module } from '../../../types/Module';
import { DRAGGABLE_TYPES } from '../../../utils/Globals';
import { CardSeparator, DraggableActionableTemplateCard, TemplateCard } from '../VisualEditor';
import { CardsContainer, ContentWrapper, StripeName, TopTenNumber } from '../VisualEditor.css';
import { Item, itemTypes } from '../../../types/Item';
import { ItemState } from '../../../redux/slices/itemSlice';
import { EditorialItemCustomize } from '../EditorialItemCustomization/EditorialItemCustomization';
import { DynamicItemPreview } from '../ItemPreview/DynamicItemPreview';
import { useAppSelector } from '../../../hooks/redux';
import { ButtonsCustomize } from '../EditorialItemCustomization/ButtonCustomization';
import _ from 'lodash';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { PageStyle, PageStyleColors } from '../../../types/PageStyle';
import ProviderLogoCard, { supportedProvidersForLogos } from './ProviderLogoCard';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import { calculateTop10offsetsForModule } from '../../../utils/fnData';
import { TruncatedText } from '../../../style/styled-components/reusable.css';
import { ModulesState } from '../../../redux/slices/moduleSlice';
import { renderABIcon } from '../../../utils/fnLockingSystem';
import useTranslation from '../../../hooks/useTranslation';

export type CardsStripe = {
    module: Module;
    cardType: any;
    moduleIndex: number;
    findItem: (id: string, moduleIndex: number) => { index: number };
    moveItem: (id: string, moduleIndex: number, to: number) => void;
    setItemForPreview: (itemId: string, moduleId: string, type: itemTypes) => void;
    unsetItemForPreview: () => void;
    onItemAction: (type: any, item?: Item, index?: number) => void;
    isGallery?: boolean;
    onCardClick?: (ci: Item) => void;
    onSave?: (ci: Item) => void;
    style?: PageStyle;
    isTemplate?: boolean;
    dragDisabled?: boolean;
};

export const CardsStripe: FC<CardsStripe> = ({
    module,
    cardType,
    moduleIndex,
    findItem,
    moveItem,
    setItemForPreview,
    unsetItemForPreview,
    onItemAction,
    isGallery,
    onCardClick,
    onSave,
    style,
    isTemplate,
    dragDisabled
}) => {
    const { itemPreview, selectedItemLoading }: ItemState = useAppSelector((state) => state.items);
    const { validTemplates }: ModulesState = useAppSelector((state) => state.modules);
    const { translate } = useTranslation();
    const [pageColors, setPageColors] = useState<PageStyleColors>({});

    useEffect(() => {
        if (style && style?.colors) {
            const { colors } = style;
            setPageColors(colors);
        }
    }, [style]);

    const isButton = cardType === 'BUTTON';

    const handleSaveClick = (item: Item) => {
        onSave && onSave(item);
    };

    const top10Offsets = calculateTop10offsetsForModule(module);

    const renderPreviewOrCustomize = () => {
        if (!itemPreview || _.isEmpty(itemPreview) || isGallery) return;

        if (itemPreview.type === itemTypes.DYNAMIC) {
            const itemIndex = module?.items?.findIndex((item) => item._id === itemPreview.itemId) || 0;
            const offset = module.topTen && top10Offsets?.length ? top10Offsets[itemIndex] : 0;

            return (
                <DynamicItemPreview
                    open={itemPreview.moduleId === module._id}
                    top10Offset={offset}
                    topTen={module.topTen}
                    template={cardType}
                    onClose={() => {
                        unsetItemForPreview();
                    }}
                    isGalleryOrPreview={isGallery || module.preview}
                    autoCollection={!!module.autoCollection}
                    viewAllCardPlacement={module.viewAllCardPlacement}
                />
            );
        }

        if (isButton) {
            return (
                <ButtonsCustomize
                    open={itemPreview.moduleId === module._id}
                    template={'BUTTON'}
                    onSave={(item) => {
                        handleSaveClick(item);
                    }}
                    onClose={() => unsetItemForPreview()}
                />
            );
        }

        return (
            <EditorialItemCustomize
                open={itemPreview.moduleId === module._id}
                template={cardType}
                onSave={(item) => {
                    handleSaveClick(item);
                }}
                onClose={() => {
                    unsetItemForPreview();
                }}
            />
        );
    };
    const getProviderNameForModule = () => {
        if (!module?.includeProviderLogoCard) return '';
        return module?.items?.find((item) => item.itemType === itemTypes.DYNAMIC)?.contentSourceType || '';
    };

    const renderStripeName = () => {
        if (itemPreview?.moduleId === module._id) return null;
        return (
            <StripeName color={pageColors?.body}>
                <TruncatedText> {translate(module.name)}</TruncatedText>
                <TranslationTooltip translationKey={module.name} isVisualEditor />
                {module.adminLocked && renderTooltipWithKey(<SVGInline src={icons.adminLockRed} />, 'pages_visual_editor_admin_locked')}
                {invisible && renderTooltipWithKey(<SVGInline src={icons.invisibleIcon} />, 'modules_invisible_enabled')}
                {renderABIcon(module, true)}
            </StripeName>
        );
    };

    const providerName = getProviderNameForModule();
    const { invisible } = module;
    return (
        <ContentWrapper>
            {
                <>
                    {renderStripeName()}
                    {itemPreview?.moduleId !== module._id && (
                        <CardsContainer isGallery={isGallery}>
                            {!isButton &&
                                !!module.items?.length &&
                                module.includeProviderLogoCard &&
                                supportedProvidersForLogos.includes(providerName) && (
                                    <>
                                        <ProviderLogoCard provider={providerName} template={cardType} />
                                        <CardSeparator
                                            key={0 + '_separator'}
                                            type={cardType}
                                            onPlus={() => {
                                                onItemAction('ADD', undefined, 0);
                                            }}
                                        />
                                    </>
                                )}
                            {module?.items?.map((ci, index) => {
                                const isLastItem = module?.items?.length === index + 1;

                                const offset = module.topTen ? top10Offsets[index] : 0;
                                const template = validTemplates?.find((t) => t.value === module.template)?.key || 'UNKNOWN';
                                const isInfinite = offset === -1;

                                return (
                                    <React.Fragment key={ci._id}>
                                        {module.topTen && (
                                            <TopTenNumber template={template} $isInfinite={isInfinite}>
                                                {isInfinite ? '∞' : offset}
                                            </TopTenNumber>
                                        )}
                                        <DraggableActionableTemplateCard
                                            key={ci._id}
                                            isTemplate={isTemplate}
                                            type={cardType}
                                            item={ci}
                                            onEdit={() => {
                                                onItemAction('EDIT', ci);
                                            }}
                                            onDelete={() => {
                                                onItemAction('DELETE', ci);
                                            }}
                                            onDuplicate={() => {
                                                onItemAction('DUPLICATE', ci);
                                            }}
                                            onView={() => {
                                                setItemForPreview(ci._id, module._id, ci.itemType);
                                            }}
                                            onCardClick={() => {
                                                (isGallery || module.preview) && onCardClick && onCardClick(ci);
                                            }}
                                            moveItem={moveItem}
                                            findItem={findItem}
                                            moduleIndex={moduleIndex}
                                            dragType={DRAGGABLE_TYPES.ITEM}
                                            autoCollection={!!module.autoCollection}
                                            style={style}
                                            dragDisabled={dragDisabled}
                                        />
                                        {!isLastItem && (
                                            <CardSeparator
                                                key={index + '_separator'}
                                                type={cardType}
                                                onPlus={() => {
                                                    onItemAction('ADD', ci, index);
                                                }}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            <TemplateCard
                                key={moduleIndex + '_plus'}
                                type={cardType}
                                isFirst={module?.items?.length === 0}
                                onPlus={() => {
                                    onItemAction('ADD');
                                }}
                            />
                        </CardsContainer>
                    )}
                </>
            }

            {renderPreviewOrCustomize()}
        </ContentWrapper>
    );
};
