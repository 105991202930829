import moment from 'moment';

export const generateDateStringForTables: (timestamp: number) => string = (timestamp: number) => {
    let modifiedString = moment.unix(timestamp).fromNow();

    const presentTime = moment.unix(Math.floor(new Date().getTime() / 1000));
    const lastModifiedTime = moment.unix(timestamp);

    if (presentTime.diff(lastModifiedTime, 'hours') < 2 && presentTime.diff(lastModifiedTime, 'hours') > 1) {
        const minutes = presentTime.diff(lastModifiedTime, 'minutes');
        modifiedString = `${Math.floor(minutes / 60)}h ${minutes % 60}min ago`;
    } else if (presentTime.diff(lastModifiedTime, 'days') > 2) {
        modifiedString = moment.unix(timestamp).format('DD MMM YYYY');
    }

    return modifiedString;
};

export const getFullDate = (timeStamp: number) => {
    return moment.unix(timeStamp).format('DD MMMM YY');
};

export const getDateStandardFormat = (timeStamp: number) => {
    return moment.unix(timeStamp).format('DD MMM YYYY');
};

export const getDateAndTime = (timeStamp: number) => {
    return moment(timeStamp).format('DD MMM YYYY, HH:mm');
};

export const generateTimeString = (millis: number) => {
    const time = moment.duration(millis);

    const hours = time.hours() < 10 ? `0${time.hours()}` : `${time.hours()}`;
    const minutes = time.minutes() < 10 ? `0${time.minutes()}` : `${time.minutes()}`;

    const formattedTime = moment(`${hours}:${minutes}`, 'HH:mm').format('LT');

    return formattedTime;
};

export const generateTimeForMillis = (millis: number): { hours: number; minutes: number; period: 'AM' | 'PM' } => {
    const time = moment.duration(millis);
    const hours = time.hours();
    const minutes = time.minutes();

    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = ((hours + 11) % 12) + 1;

    return {
        hours: formattedHour,
        minutes,
        period
    };
};
