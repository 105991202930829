import styled from 'styled-components';
import getTheme from '../../style/themes/theme';

const theme: any = getTheme();

export const VisualEditorContainer = styled.div<{ height: string; background?: string; collection?: boolean }>`
    min-height: calc(${(props) => props.height} + 169px);
    border-radius: 5px;
    margin: 8px 0;
    width: auto;
    ${(props) => !props.collection && 'contain: size'};
    ${(props) => props.collection && 'padding: 36px 0'};
    position: relative;
    color: ${theme.palette.white.main};
    display: flex;
    align-items: center;
    height: fit-content;
    background: ${({ background }) => (background ? background : theme.palette.black.main)};
    background-size: cover;
`;

export const ActionsButtonsContainer = styled.div`
    display: flex;
    z-index: 3;
    width: 25px;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    svg {
        cursor: pointer;
    }
    margin-left: 48px;
`;

export const CloseButtonWrapper = styled.div`
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 38px;
    position: absolute;
    cursor: pointer;
    padding: 8px;
    height: 24px;
    right: 36px;
    top: 36px;

    svg {
        path {
            fill: ${theme.palette.white.main};
            opacity: 1;
        }
    }
`;
