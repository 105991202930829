/**
 * Default icon set
 * Naming is important!
 */

import addIcon from './ico-add.svg';
import addIconWhite from './ico-add-white.svg';
import audiencesIcon from './ico-audiences.svg';
import controlCenterIcon from './ico-controlcenter.svg';
import faqIcon from './ico-FAQ.svg';
import homeIcon2 from './ico-home2.svg';
import insightsIcon from './ico-insights.svg';
import insightsSidebarIcon from './ico-insights-sidebar.svg';
import logoIcon from './ico-logo-3ReadyCC.svg';
import logoSmall from './logo-small.svg';
import monetizationIcon from './ico-monetization.svg';
import recommendationIcon from './ico-recommendation.svg';
import targetsIcon from './ico-targets.svg';
import translationsIcon from './ico-translations.svg';
import globalTranslationsIcon from './ico-global-translations.svg';
import usersAndGroupsIcon from './ico-users&groups.svg';
import fileManagerIcon from './ico-filemanager.svg';
import cancelUploadIcon from './ico-cancel-upload.svg';
import deleteFolderIcon from './ico-delete-folder.svg';
import deleteImageIcon from './ico-delete-image.svg';
import deleteVideoIcon from './ico-delete-video.svg';
import deleteOtherIcon from './ico-delete-other.svg';
import dashConfigurationsIcon from './ico-dash-configurations.svg';
import dashTargetsIcon from './ico-dash-targets.svg';
import dashAudiencesIcon from './ico-dash-audiences.svg';
import moreIcon from './ico-more.svg';
import moreLightIcon from './ico-more-light.svg';
import arrowUpIcon from './ico-arrow-up.svg';
import arrowDownIcon from './ico-arrow-down.svg';
import arrowRightIcon from './ico-arrow-right.svg';
import arrowRightFullIcon from './ico-arrow-right-full.svg';
import arrowLeftIcon from './ico-arrow-left.svg';
import trashIcon from './ico-trash.svg';
import copyToIcon from './ico-copy-to.svg';
import editIcon from './ico-edit.svg';
import closeEditIcon from './ico-close-edit.svg';
import duplicateIcon from './ico-duplicate.svg';
import duplicateLightIcon from './ico-duplicate-light.svg';
import projectIcon1 from './ico-project1.svg';
import projectIcon2 from './ico-project2.svg';
import projectIcon3 from './ico-project3.svg';
import projectIcon4 from './ico-project4.svg';
import projectIcon5 from './ico-project5.svg';
import projectIcon6 from './ico-project6.svg';
import projectIcon7 from './ico-project7.svg';
import projectIcon8 from './ico-project7.svg';
import userIcon from './ico-user.svg';
import projectsIcon from './ico-database.svg';
import avatarIcon from './ico-avatar.svg';
import closeIcon from './ico-close.svg';
import closeWhiteIcon from './ico-close-white.svg';
import checkmarkCircularIcon from './ico-checkmark-circular.svg';
import checkmarkCircularFilledIcon from './ico-checkmark-circular-filled.svg';
import checkmarkCircularLargeIcon from './ico-checkmark-circular-large.svg';
import checkmarkIcon from './ico-checkmark.svg';
import checkmarkWhiteIcon from './ico-checkmark-white.svg';
import logout from './ico-logout.svg';
import dragIcon from './ico-drag.svg';
import customizeIcon from './ico-customize.svg';
import checkboxIcon from './ico-checkbox.svg';
import checkboxLightIcon from './ico-checkbox-light.svg';
import checkboxActiveIcon from './ico-checkbox-active.svg';
import checkboxActiveLightIcon from './ico-checkbox-active-light.svg';
import searchIcon from './ico-search.svg';
import analyticsUpIcon from './ico-analytics-up.svg';
import analyticsDownIcon from './ico-analytics-down.svg';
import analyticsChangePositive from './ico-analytics-change-positive.svg';
import analyticsChangeNegative from './ico-analytics-change-negative.svg';
import analyticsNoChange from './ico-analytics-no-change.svg';
import browseIcon from './ico-browse.svg';
import paperclipIcon from './ico-paperclip.svg';
import linkSimpleIcon from './ico-link-simple.svg';
import cameraIcon2 from './ico-camera2.svg';
import cameraIcon from './ico-camera.svg';
import cameraIconCrossed from './ico-camera-crossed.svg';
import graphsIcon from './ico-graphs.svg';
import configuratorIcon from './ico-configurator.svg';
import desktopIcon from './ico-desktop.svg';
import desktopAddIcon from './ico-desktop-add.svg';
import listViewIcon from './ico-listView.svg';
import showPasswordIcon from './ico-show-password.svg';
import hidePasswordIcon from './ico-hide-password.svg';
import tenantIcon from './ico-tenant.svg';
import usersIcon from './ico-users.svg';
import logo3Ready from './logo-3Ready.svg';
import logo3ReadyShort from './ico-logo3ReadyShort.svg';
import tenantIcon1 from './ico-tenant1.svg';
import activeIcon from './ico-active.svg';
import inactiveIcon from './ico-inactive.svg';
import disabledIcon from './ico-disabled.svg';
import successIcon from './ico-success-dialog.svg';
import failIcon from './ico-fail-dialog.svg';
import sidebarCollapse from './ico-sidebar-collapse.svg';
import languagesIcon from './ico-languages.svg';
import pagesIcon from './ico-pages.svg';
import modulesIcon from './ico-content-modules.svg';
import itemsIcon from './ico-content-items.svg';
import menusIcon from './ico-menus.svg';
import styleAndBrandingIcon from './ico-style-and-branding.svg';
import settingsIcon from './ico-settings.svg';
import sourcesIcon from './ico-sources.svg';
import conditionsIcon from './ico-conditions.svg';
import displayConditionsIcon from './ico-display-conditions.svg';
import parentConditionsIcon from './ico-parent-conditions.svg';
import customEditorIcon from './ico-custom-editor.svg';
import addItemIcon from './ico-add-item.svg';
import addItemIconLight from './ico-add-item-light.svg';
import newResourceIcon from './ico-new-resource.svg';
import templateIcon from './ico-template.svg';
import uiTemplateIcon from './ico-ui-template.svg';
import infoIcon from './ico-info.svg';
import warningIcon from './ico-warning.svg';
import warningWhiteIcon from './ico-warning-white.svg';
import manageAccountsIcon from './ico-manage-accounts.svg';
import tenantsIcon from './ico-tenants.svg';
import icon2x3 from './TemplateIcons/ico-2x3.svg';
import icon2x3Top10 from './TemplateIcons/ico-2x3-top-10.svg';
import icon16x9S from './TemplateIcons/ico-16x9-s.svg';
import iconBannerL from './TemplateIcons/ico-banner-l.svg';
import iconBannerXXL from './TemplateIcons/ico-banner-xxl.svg';
import iconBannerXL from './TemplateIcons/ico-banner-xl.svg';
import iconBannerM from './TemplateIcons/ico-banner-m.svg';
import iconBannerS from './TemplateIcons/ico-banner-s.svg';
import iconButton from './TemplateIcons/ico-button.svg';
import iconGallery from './TemplateIcons/ico-gallery.svg';
import icon16x9M from './TemplateIcons/ico-16x9-m.svg';
import icon16x9MTop10 from './TemplateIcons/ico-16x9-m-top-10.svg';
import iconRounded from './TemplateIcons/ico-rounded-1x1.svg';
import iconSquared from './TemplateIcons/ico-squared-1x1.svg';
import iconPreview from './TemplateIcons/ico-preview.svg';
import iconLanguageGlobe from './ico-lang-globe.svg';
import iconAddLogo from './ico-add-logo.svg';
import iconProfile from './ico-profile.svg';
import iconToken from './ico-token.svg';
import tvIcon from './ico-tv.svg';
import tvAddIcon from './ico-tv-add.svg';
import phoneIcon from './ico-phone.svg';
import phoneAddIcon from './ico-phone-add.svg';
import tabletIcon from './ico-tablet.svg';
import tabletAddIcon from './ico-tablet-add.svg';
import viewIcon from './ico-view.svg';
import viewDarkIcon from './ico-dark-view.svg';
import photoIcon from './ico-photo.svg';
import largePhotoIcon from './ico-photo-large.svg';
import dynamicIcon from './ico-dynamic.svg';
import separatorRoundIcon from './ico-separator-add.svg';
import editorialIcon from './ico-editorial.svg';
import separatorRoundIconDark from './ico-separator-add-dark.svg';
import menuGreyFilledIcon from './ico-menu-grey-filled.svg';
import listGreyFilledIcon from './ico-list-grey-filled.svg';
import targetMenusIcon from './ico-target-menus.svg';
import targetPagesIcon from './ico-target-pages.svg';
import targetLayoutIcon from './ico-target-layout.svg';
import devicesGreenIcon from './ico-devices-green-bg.svg';
import devicesIcon from './ico-devices.svg';
import rangeIcon from './ico-range.svg';
import valueIcon from './ico-value.svg';
import locationIcon from './ico-location.svg';
import fingerprintIcon from './ico-fingerprint.svg';
import attachFileIcon from './ico-attach-file.svg';
import commentsIcon from './ico-comments.svg';
import pageStyleCheckboxIcon from './ico-checkbox-pagestyle.svg';
import dragLightIcon from './ico-drag-light.svg';
import editLightIcon from './ico-edit-light.svg';
import trashLightIcon from './ico-trash-light.svg';
import bigSearchIcon from './ico-search-big.svg';
import noImageIcon from './ico-no-image.svg';
import triangleGreenIcon from './ico-triangleGreen.svg';
import megaphoneIcon from './ico-megaphone.svg';
import collectionIcon from './ico-collection.svg';
import autoCollectionIcon from './ico-auto-collection.svg';
import collectionPlaceholderInfoIcon from './ico-collection-info.svg';
import increaseArrowsIcon from './ico-increase-arrows.svg';
import downloadIcon from './ico-download.svg';
import downloadIconLight from './ico-download-light.svg';
import videoIcon from './ico-video.svg';
import fileIcon from './ico-file.svg';
import helpIcon from './ico-help.svg';
import helpIconBlue from './ico-help-blue.svg';
import npawIcon from './ico-npaw-logo.svg';
import cloudUploadIcon from './ico-cloud-upload.svg';
import calendarIcon from './ico-calendar.svg';
import calendarIcon2 from './ico-calendar2.svg';
import selectCheckIcon from './ico-select-check.svg';
import purgeIcon from './ico-purge.svg';
import superadminUIIcon from './ico-superadminUI.svg';
import uploadFileIcon from './ico-upload-file.svg';
import analyticsIcon from './ico-analytics.svg';
import deviceManagementIcon from './ico-device-management.svg';
import airplayIcon from './ico-airplay.svg';
import appsIcon from './ico-apps.svg';
import audiobookIcon from './ico-audiobook.svg';
import cartIcon from './ico-cart.svg';
import eighteenIcon from './ico-18+.svg';
import actionIcon from './ico-action.svg';
import alarmAddIcon from './ico-alarm-add.svg';
import alarmIcon from './ico-alarm.svg';
import arrowDownSmIcon from './ico-arrow-down-sm.svg';
import arrowUpSmIcon from './ico-arrow-up-sm.svg';
import arrowLeftSmIcon from './ico-arrow-left-sm.svg';
import arrowRightSmIcon from './ico-arrow-right-sm.svg';
import bookmarkIcon from './ico-bookmark.svg';
import bookmarkFilledIcon from './ico-bookmark-filled.svg';
import bulbIcon from './ico-bulb.svg';
import castIcon from './ico-cast.svg';
import castConnectedIcon from './ico-cast-connected.svg';
import castAvailableIcon from './ico-cast-available.svg';
import catchupIcon from './ico-catchup.svg';
import catchupCrossedIcon from './ico-catchup-crossed.svg';
import clockIcon from './ico-clock.svg';
import codeIcon from './ico-code.svg';
import collapseIcon from './ico-collapse.svg';
import dislikeIcon from './ico-dislike.svg';
import dislikeFilledIcon from './ico-dislike-filled.svg';
import comedyIcon from './ico-comedy.svg';
import crimeIcon from './ico-crime.svg';
import enterIcon from './ico-enter.svg';
import exitIcon from './ico-exit.svg';
import dramaIcon from './ico-drama.svg';
import documentaryIcon from './ico-documentary.svg';
import filterIcon from './ico-filter.svg';
import flagIcon from './ico-flag.svg';
import familyIcon from './ico-family.svg';
import eyeIcon from './ico-eye.svg';
import eyeCrossedIcon from './ico-eye-crossed.svg';
import emailIcon from './ico-email.svg';
import googlePlayIcon from './ico-google-play.svg';
import gamesIcon from './ico-games.svg';
import footballIcon from './ico-football.svg';
import forwardIcon from './ico-forward.svg';
import handleIcon from './ico-handle.svg';
import heartIcon from './ico-heart.svg';
import heartFilledIcon from './ico-heart-filled.svg';
import homeIcon from './ico-home.svg';
import homeBackIcon from './ico-home-back.svg';
import imageIcon from './ico-image.svg';
import imageLargeIcon from './ico-image-large.svg';
import infoFilledIcon from './ico-info-filled.svg';
import infoFilledLargeIcon from './ico-info-filled-large.svg';
import infoLargeIcon from './ico-info-large.svg';
import kidsIcon from './ico-kids.svg';
import likeIcon from './ico-like.svg';
import likeFilledIcon from './ico-like-filled.svg';
import linkIcon from './ico-link.svg';
import listIcon from './ico-list.svg';
import liveIcon from './ico-live.svg';
import live2Icon from './ico-live2.svg';
import live2LargeIcon from './ico-live2-large.svg';
import lockedIcon from './ico-locked.svg';
import lockedLargeIcon from './ico-locked-large.svg';
import manageLargeIcon from './ico-manage-large.svg';
import micCrossedIcon from './ico-mic-crossed.svg';
import micIcon from './ico-mic.svg';
import minusIcon from './ico-minus.svg';
import minusCircularIcon from './ico-minus-circular.svg';
import minusCircularFilledIcon from './ico-minus-circular-filled.svg';
import moreMediaIcon from './ico-more-media.svg';
import moviesIcon from './ico-movies.svg';
import publishedIcon from './ico-published.svg';
import scheduledPublishIcon from './ico-scheduled-publish.svg';
import scheduledPublishWhiteIcon from './ico-scheduled-publish-white.svg';
import unpublishedIcon from './ico-not-published.svg';
import publishedWithChangesIcon from './ico-published-with-changes.svg';
import scheduleDatePickerIcon from './ico-schedule-date-picker.svg';
import spinnerIcon from './ico-spinner.svg';
import docIcon from './ico-doc.svg';
import docCrossedIcon from './ico-doc-crossed.svg';
import lockedObjectIcon from './ico-locked-object.svg';
import lockedObjectLightIcon from './ico-locked-object-light.svg';
import adminLockBlack from './ico-admin-lock.svg';
import adminUnlockBlack from './ico-admin-unlock.svg';
import adminLockRed from './ico-admin-lock-red.svg';
import adminLockAlert from './ico-admin-lock-alert.svg';
import moreContentIcon from './ico-more-content.svg';
import invisibleIcon from './ico-invisible.svg';
import warningYellowIcon from './ico-warning-yellow.svg';
import warningBlackIcon from './ico-warning-black.svg';
import copyIcon from './ico-copy.svg';
import aggregateIcon from './ico-aggregate.svg';
import optimizeIcon from './ico-optimize.svg';
import personalizeIcon from './ico-personalize.svg';
import monetizeIcon from './ico-monetize.svg';
import engageIcon from './ico-engage.svg';
import unpublishedChangesIcon from './ico-unpublished-changes.svg';
import deviceCategoryTVIcon from './ico-device-category-tv.svg';
import deviceCategoryWEBIcon from './ico-device-category-web.svg';
import deviceCategoryCarIcon from './ico-device-category-car.svg';
import deviceCategoryMobileIcon from './ico-device-category-mobile.svg';
import encodeIcon from './ico-encode.svg';
import encodeFinishedIcon from './ico-encode-finished.svg';
import encodeErrorIcon from './ico-encode-error.svg';
import encodePendingIcon from './ico-encode-pending.svg';
import contentSourceIcon from './ico-content-source.svg';
import placedIcon from './ico-placed.svg';
import checkMarkBlackIcon from './ico-checkmark-round-black.svg';
import uploadDoneIcon from './ico-upload-done.gif';
import uploadingIcon from './ico-uploading.gif';
import campaignIdIcon from './ico-campaign-id.svg';
import groupActiveIcon from './ico-group-active.svg';
import groupInactiveIcon from './ico-group-inactive.svg';
import abTestingPlayIcon from './ico-abtesting-play.svg';
import abTestingPauseIcon from './ico-abtesting-pause.svg';
import abTestingStopIcon from './ico-abtesting-stop.svg';
import abTestingStatusDefaultIcon from './ico-AB-test.svg';
import abTestingStatusIcon from './ico-ab-testing-status-default.svg';
import abTestingLightIcon from './ico-ab-testing-light.svg';
import abTestingDarkIcon from './ico-ab-testing-dark.svg';
import criticalWarningIcon from './ico-criticalWarning.svg';

export const TemplateIcons: any = {
    GALLERY: iconGallery,
    '16X9_M': icon16x9M,
    '16X9_S': icon16x9S,
    '2X3': icon2x3,
    '1X1_SQUARED': iconSquared,
    '1X1_ROUNDED': iconRounded,
    BANNER_XXL: iconBannerXXL,
    BANNER_XL: iconBannerXL,
    BANNER_L: iconBannerL,
    BANNER_M: iconBannerM,
    BANNER_S: iconBannerS,
    BUTTON: iconButton
};

export const AnalyticsDeviceCategoryIcons: any = {
    tv: deviceCategoryTVIcon,
    web: deviceCategoryWEBIcon,
    car: deviceCategoryCarIcon,
    mobile: deviceCategoryMobileIcon
};

const icons = {
    addIcon,
    audiencesIcon,
    controlCenterIcon,
    faqIcon,
    homeIcon2,
    insightsIcon,
    insightsSidebarIcon,
    logoIcon,
    logoSmall,
    monetizationIcon,
    recommendationIcon,
    targetsIcon,
    translationsIcon,
    globalTranslationsIcon,
    usersAndGroupsIcon,
    fileManagerIcon,
    cancelUploadIcon,
    deleteFolderIcon,
    deleteImageIcon,
    deleteOtherIcon,
    deleteVideoIcon,
    dashConfigurationsIcon,
    dashTargetsIcon,
    dashAudiencesIcon,
    moreIcon,
    moreLightIcon,
    arrowUpIcon,
    arrowDownIcon,
    arrowRightIcon,
    arrowRightFullIcon,
    trashIcon,
    copyToIcon,
    editIcon,
    closeEditIcon,
    duplicateIcon,
    duplicateLightIcon,
    projectIcon1,
    projectIcon2,
    projectIcon3,
    projectIcon4,
    projectIcon5,
    projectIcon6,
    projectIcon7,
    projectIcon8,
    avatarIcon,
    userIcon,
    projectsIcon,
    dragIcon,
    customizeIcon,
    closeIcon,
    closeWhiteIcon,
    checkmarkCircularIcon,
    checkmarkCircularFilledIcon,
    checkmarkCircularLargeIcon,
    checkmarkIcon,
    checkmarkWhiteIcon,
    logout,
    checkboxIcon,
    checkboxLightIcon,
    checkboxActiveIcon,
    checkboxActiveLightIcon,
    searchIcon,
    analyticsUpIcon,
    analyticsDownIcon,
    analyticsChangePositive,
    analyticsNoChange,
    analyticsChangeNegative,
    browseIcon,
    paperclipIcon,
    linkSimpleIcon,
    cameraIcon2,
    cameraIcon,
    cameraIconCrossed,
    graphsIcon,
    configuratorIcon,
    desktopIcon,
    desktopAddIcon,
    listViewIcon,
    showPasswordIcon,
    hidePasswordIcon,
    logo3Ready,
    logo3ReadyShort,
    tenantIcon1,
    tenantIcon,
    usersIcon,
    activeIcon,
    disabledIcon,
    inactiveIcon,
    successIcon,
    failIcon,
    sidebarCollapse,
    languagesIcon,
    pagesIcon,
    modulesIcon,
    itemsIcon,
    menusIcon,
    styleAndBrandingIcon,
    settingsIcon,
    sourcesIcon,
    conditionsIcon,
    displayConditionsIcon,
    parentConditionsIcon,
    customEditorIcon,
    addItemIcon,
    addItemIconLight,
    newResourceIcon,
    templateIcon,
    infoIcon,
    warningIcon,
    warningBlackIcon,
    manageAccountsIcon,
    tenantsIcon,
    iconLanguageGlobe,
    iconAddLogo,
    iconProfile,
    iconToken,
    tvIcon,
    tvAddIcon,
    tabletIcon,
    tabletAddIcon,
    phoneIcon,
    phoneAddIcon,
    viewIcon,
    viewDarkIcon,
    photoIcon,
    largePhotoIcon,
    dynamicIcon,
    separatorRoundIcon,
    editorialIcon,
    separatorRoundIconDark,
    menuGreyFilledIcon,
    listGreyFilledIcon,
    targetLayoutIcon,
    targetMenusIcon,
    targetPagesIcon,
    devicesGreenIcon,
    rangeIcon,
    valueIcon,
    devicesIcon,
    locationIcon,
    fingerprintIcon,
    attachFileIcon,
    commentsIcon,
    pageStyleCheckboxIcon,
    addIconWhite,
    dragLightIcon,
    editLightIcon,
    trashLightIcon,
    bigSearchIcon,
    noImageIcon,
    triangleGreenIcon,
    megaphoneIcon,
    collectionIcon,
    collectionPlaceholderInfoIcon,
    autoCollectionIcon,
    increaseArrowsIcon,
    downloadIcon,
    fileIcon,
    helpIcon,
    helpIconBlue,
    npawIcon,
    videoIcon,
    arrowLeftIcon,
    downloadIconLight,
    cloudUploadIcon,
    calendarIcon2,
    selectCheckIcon,
    purgeIcon,
    superadminUIIcon,
    uploadFileIcon,
    analyticsIcon,
    deviceManagementIcon,
    airplayIcon,
    appsIcon,
    audiobookIcon,
    calendarIcon,
    cartIcon,
    eighteenIcon,
    actionIcon,
    alarmAddIcon,
    alarmIcon,
    arrowDownSmIcon,
    arrowUpSmIcon,
    arrowLeftSmIcon,
    arrowRightSmIcon,
    bookmarkIcon,
    bookmarkFilledIcon,
    bulbIcon,
    castIcon,
    castConnectedIcon,
    castAvailableIcon,
    catchupIcon,
    catchupCrossedIcon,
    clockIcon,
    codeIcon,
    collapseIcon,
    dislikeIcon,
    dislikeFilledIcon,
    comedyIcon,
    crimeIcon,
    exitIcon,
    enterIcon,
    dramaIcon,
    documentaryIcon,
    emailIcon,
    familyIcon,
    flagIcon,
    filterIcon,
    eyeIcon,
    eyeCrossedIcon,
    googlePlayIcon,
    gamesIcon,
    handleIcon,
    forwardIcon,
    footballIcon,
    heartIcon,
    heartFilledIcon,
    homeIcon,
    homeBackIcon,
    imageIcon,
    imageLargeIcon,
    infoFilledIcon,
    infoFilledLargeIcon,
    infoLargeIcon,
    kidsIcon,
    likeFilledIcon,
    likeIcon,
    linkIcon,
    listIcon,
    liveIcon,
    live2Icon,
    live2LargeIcon,
    lockedIcon,
    lockedLargeIcon,
    adminLockBlack,
    adminUnlockBlack,
    adminLockRed,
    adminLockAlert,
    manageLargeIcon,
    micCrossedIcon,
    micIcon,
    minusCircularFilledIcon,
    minusCircularIcon,
    minusIcon,
    moreMediaIcon,
    moviesIcon,
    publishedIcon,
    publishedWithChangesIcon,
    unpublishedIcon,
    scheduledPublishIcon,
    scheduledPublishWhiteIcon,
    scheduleDatePickerIcon,
    spinnerIcon,
    docIcon,
    docCrossedIcon,
    lockedObjectIcon,
    lockedObjectLightIcon,
    moreContentIcon,
    invisibleIcon,
    warningYellowIcon,
    copyIcon,
    aggregateIcon,
    optimizeIcon,
    personalizeIcon,
    monetizeIcon,
    engageIcon,
    unpublishedChangesIcon,
    warningWhiteIcon,
    encodeIcon,
    encodeFinishedIcon,
    encodeErrorIcon,
    encodePendingIcon,
    contentSourceIcon,
    placedIcon,
    checkMarkBlackIcon,
    uploadDoneIcon,
    uploadingIcon,
    uiTemplateIcon,
    campaignIdIcon,
    groupActiveIcon,
    groupInactiveIcon,
    abTestingPlayIcon,
    abTestingPauseIcon,
    abTestingStopIcon,
    abTestingStatusDefaultIcon,
    abTestingStatusIcon,
    abTestingLightIcon,
    abTestingDarkIcon,
    criticalWarningIcon
} as const;

export default icons;

export type iconType = typeof icons[keyof typeof icons];
