import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import GenericDialog, { DialogButton, DialogDropdownMultiple, DialogTypes } from './GenericDialog';
import { search, SearchBar } from '../SearchBar/SearchBar';
import { TableBody } from '@material-ui/core';
import GenericTable from '../Table/Table';
import icons from '../../../style';
import _ from 'lodash';
import {
    DialogRowStyle,
    DialogTableCell,
    DialogTableRow,
    SearchBarStyledContainer,
    TagName,
    UserAndImageContainer,
    UserEmail,
    UserImage,
    UserName,
    AddUsersOptionContainer,
    UserOption,
    DialogTitleRowStyle,
    DialogTitleCellStyle,
    MoreInfoContentHolder
} from './MoreInfoDialog.css';
import { EMPTY_WORD_STRING, USERROLES } from '../../../utils/Globals';
import TranslationTooltip from '../TranslationTooltip/TranslationTooltip';
import { LanguageFlag } from '../../TargetConditions/DisplayConditions/Conditions.css';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchUsers, UsersState } from '../../../redux/slices/usersSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { fetchTenants, TenantsState } from '../../../redux/slices/tenantsSlice';
import { fetchDashbard, fetchProjects, ProjectsState } from '../../../redux/slices/projectsSlice';
import { UserAvatar, UserOptionEmail, UserOptionName } from '../../Projects/Projects.css';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { dialogAlert, dialogConfirm } from '../../../utils/fnDialogs';
import { calculateIsProjectLead, calculateIsTenantAdmin, PermissionsState } from '../../../redux/slices/permissionsSlice';
import { Project } from '../../../types/Project';
import { RemoveModuleWrapper } from '../../Modules/Modules.css';
import { ObjectTypes } from '../../../types/Object';
import useTranslation from '../../../hooks/useTranslation';

export type MoreInfoDialogProps = {
    type: MoreInfoTypes;
    data: any[];
    open: boolean;
    onClose: () => void;
    withAdd?: boolean;
    onClickLabel?: (obj: any) => void;
};

export enum MoreInfoTypes {
    GROUPS = 'User-Groups',
    PROJECTS = 'Projects',
    TARGETS = 'Target Conditions',
    USERS = 'Users',
    PLACED = 'Part of Object(s)',
    DATE_INTERVAL = 'Start & End Date',
    TIME_INTERVAL = 'Start & End Time',
    LANGUAGES = 'Languages',
    COUNTRIES = 'Countries',
    DAYS = 'Week Days',
    CONNECTED_TO = 'Connected to',
    TAGS = 'Tags',
    SEGMENTS = 'Segments',
    AB_GROUPS = 'AB Testing Groups'
}

export const MoreInfoDialog: FC<MoreInfoDialogProps> = ({ type, data, open, onClickLabel, onClose, withAdd }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [items, setItems] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [addOptions, setAddOptions] = useState<any[]>([]);
    const [selectedToAdd, setSelectedToAdd] = useState<string[]>([]);
    const [selectedToRemove, setSelectedToRemove] = useState<string[]>([]);

    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { userProfile } = useAppSelector((state) => state.profile);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);

    const { users, loading: usersLoading }: UsersState = useAppSelector((state) => state.users);
    const { tenants, loading: tenantsLoading }: TenantsState = useAppSelector((state) => state.tenants);
    const { projects, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);

    const isAdmin = activeTenantId && (userPermissions?.isSuperAdmin || calculateIsTenantAdmin(activeTenantId, userPermissions));
    const isProjectLead = activeProjectId && !isAdmin && calculateIsProjectLead(activeProjectId, userPermissions);

    const dispatch = useAppDispatch();
    const { translate } = useTranslation();

    const loadDashboard = async () => {
        await dispatch(fetchDashbard(activeProjectId || '')).unwrap();
    };

    const loadTenants = async () => {
        return await dispatch(fetchTenants({})).unwrap();
    };

    const loadProjects = async () => {
        return await dispatch(fetchProjects({})).unwrap();
    };

    const loadUsers = async () => {
        return await dispatch(fetchUsers({})).unwrap();
    };

    useEffect(() => {
        if (type !== MoreInfoTypes.USERS) return;
        if (!users.length && activeProjectId && (isAdmin || isProjectLead)) {
            loadUsers();
        }
    }, [activeProjectId, users, type]);

    useEffect(() => {
        setIsOpen(open);
        if (open && withAdd) {
            !tenants?.length && loadTenants();
            !projects?.length && loadProjects();
        }
    }, [open]);

    useEffect(() => {
        setItems(data);
    }, [data]);

    useEffect(() => {
        if (type !== MoreInfoTypes.USERS || !withAdd) return;
        const options = users
            ?.filter((user) => {
                // we filter out superAdmins and tenantAdmins and projectLeads from the options
                // and we make sure the user is a part of the projects tenant
                const currentlySelectedTenant = tenants.find((t) => t._id === activeTenantId);
                const currentlySelectedProject = projects.find((p) => p._id === activeProjectId);
                return (
                    user.tenantIds?.includes(`${activeTenantId}`) &&
                    user.role?.name !== USERROLES.SUPER_ADMIN &&
                    !currentlySelectedTenant?.tenantAdminIds.includes(user._id) &&
                    !currentlySelectedProject?.projectLeadIds?.includes(user._id) &&
                    !data.map((elem) => elem._id).includes(user._id)
                );
            })
            ?.map((user) => {
                return {
                    label: (
                        <AddUsersOptionContainer>
                            <UserOption>
                                <UserAvatar background={user.icon || icons.userIcon} />
                                <UserOptionName>{user.fullName}</UserOptionName>
                            </UserOption>
                            <UserOptionEmail>{user.email}</UserOptionEmail>
                        </AddUsersOptionContainer>
                    ),
                    value: user._id,
                    valueForSearch: user.fullName
                };
            });
        setAddOptions(options);
    }, [data, tenants, projects]);

    const handleClose = () => {
        setSearchTerm('');
        setItems([]);
        onClose();
    };

    const buildTableBody = () => {
        const renderTitle = (title: string) => {
            return (
                <DialogTableRow style={DialogTitleRowStyle}>
                    <DialogTableCell style={DialogTitleCellStyle}>{title}</DialogTableCell>
                </DialogTableRow>
            );
        };

        const renderRow = (item: any, withTranslations?: boolean) => {
            return (
                <DialogTableRow key={item._id} style={DialogRowStyle} onClick={() => onClickLabel && onClickLabel(item || {})}>
                    <DialogTableCell>
                        <TagName $isClickable={!!onClickLabel}>
                            {withTranslations ? translate(item.name || EMPTY_WORD_STRING) : item.name || EMPTY_WORD_STRING}
                            {withTranslations && <TranslationTooltip translationKey={item.name} />}
                        </TagName>
                    </DialogTableCell>
                </DialogTableRow>
            );
        };
        const rows = [];
        switch (type) {
            case MoreInfoTypes.USERS:
                rows.push(
                    items.map((item) => {
                        if (selectedToRemove.includes(item._id)) return null;
                        if (item._id === userProfile?._id) return null;
                        return (
                            <DialogTableRow key={item._id} style={DialogRowStyle}>
                                <DialogTableCell>
                                    <UserAndImageContainer>
                                        <UserImage src={item?.icon || icons.avatarIcon} alt="profile_image" />
                                        <UserName>{_.truncate(item.fullName, { length: 24 })}</UserName>
                                    </UserAndImageContainer>
                                </DialogTableCell>
                                <DialogTableCell>
                                    <UserEmail>
                                        {item.email}
                                        {withAdd && (
                                            <SVGInline
                                                src={icons.closeIcon}
                                                onClick={() => !usersLoading && setSelectedToRemove([...selectedToRemove, item._id])}
                                            />
                                        )}
                                    </UserEmail>
                                </DialogTableCell>
                            </DialogTableRow>
                        );
                    })
                );
                break;
            case MoreInfoTypes.LANGUAGES:
            case MoreInfoTypes.COUNTRIES:
                rows.push(
                    items.map((item) => {
                        return (
                            <DialogTableRow key={item._id} style={DialogRowStyle}>
                                <DialogTableCell>
                                    <TagName>
                                        <LanguageFlag src={item.img} />
                                        {item?.name || ''}
                                    </TagName>
                                </DialogTableCell>
                            </DialogTableRow>
                        );
                    })
                );
                break;
            case MoreInfoTypes.TAGS:
                rows.push(
                    items.map((item) => {
                        return (
                            <DialogTableRow key={item._id} style={DialogRowStyle}>
                                <DialogTableCell>
                                    <TagName>{_.capitalize(item.name).replace('_', ' ') || ''}</TagName>
                                </DialogTableCell>
                            </DialogTableRow>
                        );
                    })
                );
                break;

            case MoreInfoTypes.TARGETS:
                const condition = items.find(
                    (item) => item.objectType === ObjectTypes.CONDITIONS || item.objectType === 'displayConditions'
                );
                const audiences = items.filter((item) => item._id !== condition?._id).map((item) => renderRow(item));
                !!condition && rows.push(...[renderTitle('Display Condition'), renderRow(condition)]);
                rows.push(...[renderTitle('Audiences'), ...audiences]);
                break;

            case MoreInfoTypes.PLACED:
                const types = [...new Set(items.map((item) => item.type))];

                rows.push(
                    types.map((type) => {
                        const objects = items.filter((item) => item.type === type);
                        return (
                            <>
                                {renderTitle(_.startCase(type))}
                                {objects.map((object) => renderRow(object, true))}
                            </>
                        );
                    })
                );
                break;

            default:
                rows.push(
                    items.map((item) => {
                        return (
                            <DialogTableRow key={item._id} style={DialogRowStyle} onClick={() => onClickLabel && onClickLabel(item || {})}>
                                <DialogTableCell>
                                    <TagName $isClickable={!!onClickLabel}>
                                        {translate(item.name || EMPTY_WORD_STRING)}
                                        {type !== MoreInfoTypes.CONNECTED_TO && <TranslationTooltip translationKey={item.name} />}
                                    </TagName>
                                </DialogTableCell>
                            </DialogTableRow>
                        );
                    })
                );
                break;
        }
        return <TableBody>{rows}</TableBody>;
    };

    const renderProjectLeadWarning = (userIds: string[], project?: Project) => {
        const userNames = users.filter((user) => userIds.includes(user._id)).map((user) => user.email);

        const message =
            userNames.length !== 1
                ? `The following users: ${userNames.join(', ')}  are project leads in ${project?.name}`
                : `${userNames[0]} is a project lead in ${project?.name}`;
        const values = {
            title: 'Warning!',
            text: ''
        };
        return dialogConfirm(
            '',
            () => {
                handleSaveClick(true);
            },
            values,
            <RemoveModuleWrapper>
                <SVGInline src={icons.warningIcon} />
                <p>
                    <strong>{message}</strong>
                    <br />
                    Do you wish to remove the user from the project?
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Ok'
                }
            }
        );
    };

    const handleSaveClick = async (withoutWarning: boolean = false) => {
        if (!withoutWarning) {
            const currentlySelectedProject = projects.find((p) => p._id === activeProjectId);
            const projectLeadsToRemove = currentlySelectedProject?.projectLeadIds?.filter((id) => selectedToRemove.includes(id));
            if (projectLeadsToRemove?.length) return renderProjectLeadWarning(projectLeadsToRemove, currentlySelectedProject);
        }

        const members = (
            users?.filter((u) => u.projectIds?.includes(`${activeProjectId}`) && !selectedToRemove.includes(u._id))?.map((u) => u._id) || []
        ).concat(selectedToAdd);

        // if user is projectLead, always add the id to the members so he doesn't get unassigned since he is filtered out of the options
        if (userProfile && isProjectLead && !members.includes(userProfile._id)) {
            members.push(userProfile._id);
        }

        const response = await configServiceAPI.updateProjectMembers(`${activeProjectId}`, members);
        if (response.error) {
            const values = {
                title: 'Oops.. Could not update members!',
                subtitle: `Something went wrong while updating the project's members.`,
                text: 'An error occured: ' + response.error.message
            };
            dialogAlert('', false, values);
        } else {
            loadDashboard();
            handleCancelClick();
            handleClose();
        }
    };
    const handleCancelClick = () => {
        setSelectedToRemove([]);
        setSelectedToAdd([]);
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: () => handleSaveClick(),
        disabled: usersLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCancelClick
    };

    if (!isOpen) return null;

    return (
        <GenericDialog
            title={type}
            type={withAdd ? DialogTypes.MoreInfo : DialogTypes.Flat}
            onClose={() => {
                handleCancelClick();
                handleClose();
            }}
            actionButtons={withAdd && (selectedToAdd.length || selectedToRemove.length) ? [cancelButton, saveButton] : []}
        >
            <SearchBarStyledContainer>
                {withAdd ? (
                    <DialogDropdownMultiple
                        labelText={'Members'}
                        value={addOptions.filter((opt) => selectedToAdd.includes(opt.value))}
                        options={addOptions}
                        placeholder={'Add Users'}
                        allowSelectAll={true}
                        onChange={(value: any) => setSelectedToAdd(value ? value.map((v: any) => v.value) : [])}
                        isDisabled={tenantsLoading || projectsLoading || usersLoading}
                    />
                ) : (
                    <SearchBar
                        title={`Search for ${type}`}
                        searchTerm={searchTerm}
                        setSearchTerm={(value) => {
                            setSearchTerm(value);
                            setItems(search(data, value));
                        }}
                        onSearch={() => {}}
                    />
                )}
            </SearchBarStyledContainer>

            <MoreInfoContentHolder>
                <GenericTable columns={[]} body={buildTableBody()} />
            </MoreInfoContentHolder>
        </GenericDialog>
    );
};
