import styled from 'styled-components';
import { devices, TruncatedText } from '../../style/styled-components/reusable.css';
import getTheme, { fontSizes, fontSizesCSS } from '../../style/themes/theme';
import { InputLabelWithIconWrapper } from '../common/Dialog/GenericDialog.css';

const theme: any = getTheme();

export const AddSettingsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed ${theme.palette.background.pressed};
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 12px;
`;

export const SettingTitleContainer = styled.div`
    gap: 8px;
    display: flex;
    ${fontSizes.Body2}
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

export const SettingsSubsectionsHolder = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.background.pressed};
    padding: 16px 24px 24px;
`;

export const SettingsSubsection = styled.div<{ $expanded?: boolean }>`
    border-top: 1px solid ${theme.palette.background.pressed};
    ${fontSizes.Body2}
    width: calc(100% - 48px);
    background: ${theme.palette.white.main};
    display: flex;
    ${({ $expanded }) => !$expanded && 'margin-bottom: 1px'};
    align-items: center;
    justify-content: space-between;
    padding: 24px;

    &:first-child {
        ${({ $expanded }) => !$expanded && 'margin-top: 1px'};
    }

    svg {
        &:hover {
            cursor: pointer;
        }
        height: 16px;
        width: 16px;
    }
`;
export const SettingSubsectionName = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

export const SettingsSubsectionRow = styled.div`
    background-color: ${theme.palette.background.container};
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    display: flex;
    height: 36px;

    // Remove the margin-bottom of the CheckboxContainer
    a {
        width: 100%;
    }

    ${devices.tablet} {
        margin: 0;
        div {
            font-size: 15px;
        }

        label {
            width: 160px;
        }
    }
    .MuiInputBase-input.Mui-disabled {
        background-color: transparent;
    }

    .MuiFormControl-root {
        min-height: unset;
    }
`;

export const SubsectionInputAdornment = styled.span`
    position: absolute;
    right: 6px;
    ${fontSizesCSS.Body1};
    color: ${theme.palette.foreground.main};
    opacity: 0.5;
`;

export const SettingEditContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    ${devices.tablet} {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
`;

export const SettingsSubsectionRowName = styled(TruncatedText)`
    width: 40%;

    ${devices.tablet} {
        width: 100%;
    }
`;

export const DialogTextFieldWrapper = styled.div`
    width: 100%;
    .MuiFormControl-root {
        margin: 24px 0 0 0;
    }
`;

export const SettingsConditionsView = styled.div`
    width: 100%;
    height: 104px;
    [id$='Target Conditions'] {
        margin-top: 18px;
        label {
            color: ${theme.palette.label.main};
        }
    }
`;

export const AbTestingSelectWrapper = styled.div`
    width: 100%;
    margin-top: 18px;
    &:has(${InputLabelWithIconWrapper}) {
        margin-top: 20px;
    }
    .conditions-select__menu {
        right: 0;
    }

    ${devices.tablet} {
        .conditions-select__menu {
            left: 0;
        }
    }
`;

export const LastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    align-items: center;

    img {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;

        ${devices.tablet} {
            display: none;
        }
    }
`;
