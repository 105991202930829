import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { AdornmentSpan, SelectWrapper } from '../common/Select/Select.css';
import { devices } from '../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const LanguageName = styled.div`
    gap: 4px;
    display: flex;
    max-width: 100%;
    width: fit-content;
    align-items: center;

    a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const LanguageFlagImg = styled.img`
    width: 30px;
    src: ${(props) => props.src};
`;

export const LanguagePreviewForDeletion = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.background.contrast};
    img {
        margin: 0 12px;
    }
`;

// ---------------------- TRANSLATIONS
export const TranslationsKeyAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    div.MuiInputBase-root {
        max-width: 435px;
        ${devices.desktopS} {
            max-width: 245px;
        }
        ${devices.tablet} {
            max-width: 100px;
        }
        input {
            width: 100%;
        }
    }
`;

export const DefaultLanguageWrapper = styled.div`
    span {
        padding: 0;
    }
`;

export const TranslationsInputWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    overflow: hidden;
    .MuiFormControl-root {
        margin: 0;
        min-height: unset;
        width: calc(100% - 32px);
        .MuiInput-root {
            border: none;
            input {
                padding: 0;
                background-color: transparent;
                text-overflow: ellipsis;
                border: 0.5px solid transparent;
                &:focus {
                    border: 0.5px solid ${theme.palette.primary.main};
                    border-radius: 0;
                }
            }
        }
        .MuiInputAdornment-root {
            svg {
                cursor: pointer;
                path {
                    fill: ${theme.palette.black.main};
                }
            }
        }
        p.Mui-error {
            ${fontSizesCSS.Caption4};
        }
    }
`;

export const TranslationsPaginationBottomContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TranslationsPaginationTopContainer = styled(SearchBarContainer)`
    .MuiFormControl-root {
        width: 480px;
        max-width: 480px;
    }
    ${devices.desktopS} {
        flex-direction: column;
    }
    ${devices.tablet} {
        .MuiFormControl-root {
            width: 340px;
        }
    }
`;

export const TranslationsStatisticsContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    ${fontSizesCSS.Caption1};
    ${SelectWrapper} {
        height: 36px;
        width: 144px;
        min-height: unset;
    }
`;

export const AddTranslationLabelWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const FilterTranslationsContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const FilterTranslationsWrapper = styled.div`
    display: flex;
    align-items: center;

    ${SelectWrapper} {
        min-height: unset;

        label.error {
            display: none;
        }

        .cc_select__single-value {
            position: unset;
            transform: unset;
            ${devices.tablet} {
                display: none;
            }
        }

        .cc_select__indicator {
            padding: 0;
            svg {
                opacity: 1;
                width: 16px;
                height: 16px;
            }
        }

        ${AdornmentSpan} {
            opacity: 1;
        }

        [class$='control'] {
            background: inherit;
            border: none;
            box-shadow: none;
            cursor: pointer;
        }

        [class$='menu'] {
            width: 360px;
            ${devices.tablet} {
                right: 0;
            }
        }
    }
`;

export const TranslationfilterIcon = styled.div``;

export const LanguageOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;
`;
