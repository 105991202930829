import React, { FC, useCallback, useEffect, useState } from 'react';
import { Item, itemTypes } from '../../types/Item';
import { Module, templates } from '../../types/Module';
import { moduleTypes } from '../../utils/Globals';
import { Actions } from '../Items/EditorialView';
import { ContentWrapper, TemplateSizeKey, TEMPLATE_SIZES } from '../PageEdit/VisualEditor.css';
import { ActionsButtonsContainer, VisualEditorContainer, CloseButtonWrapper } from './VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import _ from 'lodash';
import { ItemState, unSetItemPreview } from '../../redux/slices/itemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { GalleryAndPreview } from '../PageEdit/VisualElements/GalleryAndPreview';
import { CardsStripe } from '../PageEdit/VisualElements/CardsStripe';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { Collection } from '../PageEdit/Collection';
import { calculateItemBackgroundToShow } from '../../utils/fnColor';
import usePrevious from '../../hooks/usePrevious';

type VisualEditorProps = {
    module: Module;
    template: TemplateSizeKey | 'GALLERY' | 'PREVIEW';
    moduleIndex: number;
    actions: any[];
    onEdit: () => void;
    isTemplate?: boolean;
    onDuplicate: () => void;
    onRemove: () => void;
    onSave: (item: Item) => Promise<boolean>;
    onCancel?: () => void;
    onItemAction: (type: any, item?: Item, index?: number) => void;
    setItemForPreview: (itemId: string, moduleId: string, type: itemTypes) => void;
    createItem: (index?: number) => void;
    setOrderedModule: (module: Module) => void;
};

export const VisualEditor: FC<VisualEditorProps> = ({
    module,
    template,
    moduleIndex,
    actions,
    onSave,
    onEdit,
    onRemove,
    onDuplicate,
    onCancel,
    onItemAction,
    setItemForPreview,
    createItem,
    setOrderedModule,
    isTemplate
}) => {
    const [cModule, setCModule] = useState<Module>(module);
    const [itemToShow, setItemToShow] = useState<Item | undefined>(undefined);
    const [backgroundToPreview, setBackgroundToPreview] = useState<string>('');
    const { itemPreview }: ItemState = useAppSelector((state) => state.items);

    const prevModule = usePrevious(module);

    const dispatch = useDispatch();

    // DRAG AND DROP LOGIC
    const findItem = useCallback(
        (id: string, _moduleIndex: number) => {
            const item = cModule.items?.find((ci) => ci._id === id);

            return {
                item,
                index: cModule.items?.indexOf(item) || 0
            };
        },
        [cModule]
    );

    const moveItem = useCallback(
        (id: string, moduleIndex: number, atIndex: number) => {
            const { item, index } = findItem(id, moduleIndex);
            const newCm = { ...cModule };
            const newCis = [...(newCm.items || [])];
            newCis.splice(index, 1);
            newCis.splice(atIndex, 0, item);
            _.set(newCm, 'items', newCis);
            _.set(
                newCm,
                'itemIds',
                newCis.map((elem) => elem._id)
            );
            setCModule(newCm);
        },
        [findItem, cModule, setCModule]
    );

    const unsetItemForPreview = () => {
        dispatch(unSetItemPreview());
    };

    const handleIconClick = (evt: any, action: Actions) => {
        evt.preventDefault();
        switch (action) {
            case Actions.DUPLICATE:
                onDuplicate();
                return;
            case Actions.REMOVE:
                onRemove();
                return;
            case Actions.EDIT:
                onEdit();
                return;
            default:
                return;
        }
    };
    useEffect(() => {
        setOrderedModule(cModule);
    }, [cModule]);

    useEffect(() => {
        if (!module?.items?.length) {
            setBackgroundToPreview('');
            setItemToShow(undefined);
            return;
        }
        const newItemToShow = (itemToShow && module.items.find((elem) => elem._id === itemToShow._id)) || module.items[0];
        setItemToShow(newItemToShow);
        setBackgroundToPreview(calculateItemBackgroundToShow(newItemToShow, module.preview, ''));
    }, [module]);

    useEffect(() => {
        // sometimes the new module that we receive is equal to the previous one, but because it's a new object, the use effect will be called causing unwanted behaviour on d&d
        // a good exaple is in superadminUI, when at Visual Editor render, a new module object is created from a template
        if (!_.isEqual(prevModule, module)) setCModule(module);
    }, [module]);

    useEffect(() => {
        if (!itemPreview) return;
        const item = cModule.items?.find((ci) => ci._id === itemPreview.itemId);
        setItemToShow(item);
    }, [cModule, itemPreview]);

    const isGallery = template === 'GALLERY';
    const isPreview = !!module.preview;
    const isButton = template === 'BUTTON';
    const isCollection = module?.moduleType === moduleTypes.COLLECTION;
    const cardType = isGallery ? '16X9_S' : template;
    const containerHeight =
        isGallery || isPreview
            ? '606px'
            : isButton
            ? '16px'
            : _.get(TEMPLATE_SIZES, template, null)?.height || TEMPLATE_SIZES['UNKNOWN'].height;

    return (
        <VisualEditorContainer
            height={containerHeight}
            background={isGallery || isPreview ? backgroundToPreview : undefined}
            collection={isCollection}
        >
            {!!actions?.length && (
                <ActionsButtonsContainer>
                    {actions.map((action) => {
                        if (action === Actions.DUPLICATE) return null;

                        const icon =
                            action === Actions.EDIT
                                ? icons.editLightIcon
                                : action === Actions.REMOVE
                                ? icons.trashLightIcon
                                : icons.duplicateLightIcon;
                        const tooltipText =
                            action === Actions.EDIT
                                ? 'modules_icon_edit'
                                : action === Actions.REMOVE
                                ? 'modules_icon_delete'
                                : 'modules_icon_duplicate';

                        return renderTooltipWithKey(
                            <SVGInline key={`${action}_icon`} src={icon} onClick={(evt) => handleIconClick(evt, action)} />,
                            tooltipText
                        );
                    })}
                </ActionsButtonsContainer>
            )}
            <ContentWrapper>
                {isGallery || isPreview ? (
                    <GalleryAndPreview
                        module={cModule}
                        findItem={findItem}
                        moveItem={moveItem}
                        setItemForPreview={setItemForPreview}
                        unsetItemForPreview={unsetItemForPreview}
                        item={itemToShow}
                        moduleIndex={moduleIndex}
                        onBackgroundChange={(newPreview) => {
                            setBackgroundToPreview(newPreview);
                        }}
                        onCardClick={(ci: Item) => setItemToShow(ci)}
                        type={isGallery ? 'GALLERY' : 'PREVIEW'}
                        onItemAction={(actionType, item, index) => onItemAction(actionType, item, index)}
                        onSave={(item) => onSave(item).then((res) => res && dispatch(unSetItemPreview()))}
                        isTemplate={isTemplate}
                    />
                ) : isCollection ? (
                    <Collection
                        module={cModule}
                        onEdit={(ci) => onItemAction('EDIT', ci)}
                        onDelete={(ci) => onItemAction('DELETE', ci)}
                        moduleIndex={moduleIndex}
                        addItem={createItem}
                        type={cardType === 'GALLERY' || cardType === 'PREVIEW' ? 'UNKNOWN' : cardType}
                        isTemplate={isTemplate}
                        setItemForPreview={setItemForPreview}
                        unsetItemForPreview={unsetItemForPreview}
                        onItemSave={(item) => onSave(item).then((res) => res && dispatch(unSetItemPreview()))}
                        moveItem={moveItem}
                        findItem={findItem}
                    />
                ) : (
                    <CardsStripe
                        module={cModule}
                        findItem={findItem}
                        cardType={template}
                        moduleIndex={moduleIndex}
                        moveItem={moveItem}
                        setItemForPreview={setItemForPreview}
                        unsetItemForPreview={unsetItemForPreview}
                        onItemAction={onItemAction}
                        isTemplate={isTemplate}
                        onSave={(item) => onSave(item).then((res) => res && dispatch(unSetItemPreview()))}
                    />
                )}
            </ContentWrapper>
            {!itemPreview && (
                <CloseButtonWrapper onClick={() => onCancel && onCancel()}>
                    <SVGInline src={icons.closeIcon} />
                </CloseButtonWrapper>
            )}
        </VisualEditorContainer>
    );
};
