import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import SVGInline from 'react-inlinesvg';

import { DateSelectorWrapper, FilterValueWrapper, MultiValueContainer, OptionContainer, SelectWrapper } from './Select.css';
import { ObjectNameLabel, SearchSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import { DatePickerComponent } from '../DatePicker/DatePicker';
import icons from '../../../style';

import { ObjectFilter, objectFilterToSelectValue, selectValueToObjectFilter } from '../../../utils/fnFilter';
import { renderUnsavedAlertBeforeAction } from '../../PaginationWrapper/PaginationWrapper';
import { FilterDataState, getFilterData } from '../../../redux/slices/filterDataSlice';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { usePersistentState } from '../../../hooks/usePersistentState';
import { getDateStandardFormat } from '../../../utils/fnDate';
import { TemplateIcons } from '../../../assets/images/icons';
import { UserAvatar } from '../../Projects/Projects.css';
import { ObjectType } from '../../../types/Object';
import { templates } from '../../../types/Module';

export type FilterOptions = { fields: any[]; filterValues: { [key: string]: any[] } };
export type FilterObject = {
    field: { label: string; value: string; icon: string; type: string };
    filterValue?: { label: string; value: string; icon?: string };
    value: string;
};

export type FancyFilterProps = {
    setSearchTerm: (value: string) => void;
    activeObjectFilter?: ObjectFilter;
    onChange: (values: any) => void;
    showUnsaved?: boolean;
    searchTerm: string;
    type?: ObjectType;
    //used when it is necessary to hardcode the fields on fe side
    extraFilters?: {
        fields: any[];
        filterValues: { [key: string]: any };
    };
    label?: string;
    isPageFilter?: boolean;
};

export const searchTermUnsetValue = '[UNSET]';

export const FancyFilter: React.FC<FancyFilterProps> = ({
    type,
    onChange,
    searchTerm,
    setSearchTerm,
    showUnsaved,
    activeObjectFilter,
    extraFilters,
    label,
    isPageFilter
}) => {
    const dispatch = useDispatch();
    const selectRef = useRef<any>(null);
    const hasPrefill = !!searchTerm && !!activeObjectFilter?._id;
    const { store: paginationState } = usePersistentState('paginationState');
    const { searchTerm: paginationStateTerm, activeObjectFilter: paginationStateFilter } = paginationState || {};

    const { filterData, loading }: FilterDataState = useAppSelector((state) => state.filterData);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { fields, filterValues }: FilterOptions = filterData;

    const [selectedValue, setSelectedValue] = useState<FilterObject[]>([]);
    const [searchTermFilter, setSearchTermFilter] = useState<string>('');
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any[]>();

    useEffect(() => {
        const selectElement = document.getElementsByClassName('search-filter-input__value-container')[0] as HTMLElement;
        selectElement &&
            selectElement.addEventListener('click', function () {
                setMenuIsOpen(true);
            });
    }, []);

    useEffect(() => {
        if (!type) return;
        dispatch(getFilterData({ projectId: activeProjectId || '', objectType: type }));
        setSearchTermFilter('');
        setSelectedValue([]);
    }, [activeProjectId, type]);

    useEffect(() => {
        if (fields.length > 0) {
            const lastValue = selectedValue[selectedValue.length - 1] || {};
            setOptions(lastValue.filterValue || !selectedValue.length ? fields : filterValues[lastValue.field?.value]);
        }
    }, [filterData]);

    useEffect(() => {
        if (!extraFilters) return;
        const lastValue = selectedValue[selectedValue.length - 1] || {};
        setOptions(
            lastValue.filterValue || !selectedValue.length ? extraFilters.fields : extraFilters.filterValues[lastValue.field?.value]
        );
    }, [extraFilters]);

    useEffect(() => {
        if (!activeObjectFilter) {
            setSelectedValue([]);
            setSearchTermFilter('');
        }
    }, [activeObjectFilter]);

    useEffect(() => {
        if (isPageFilter) return;
        // USED TO PREFILL THE FILTER INPUT WITH THE DATA FROM THE PAGINATION STATE WHEN COMING BACK FROM EDIT PAGE
        paginationStateFilter && setSelectedValue(objectFilterToSelectValue(paginationStateFilter, fields, filterValues));
        paginationStateTerm && setSearchTermFilter(paginationStateTerm);
    }, [paginationState]);

    useEffect(() => {
        // USED TO PREFILL THE FILTER INPUT WITH THE OBJECT NAME WHEN REDIRECTED
        if (hasPrefill) {
            setSelectedValue([
                {
                    field: { type: 'search', label: '', value: 'prefill', icon: '' },
                    filterValue: { label: searchTerm || '', value: 'prefill' },
                    value: 'prefill'
                }
            ]);
        }
        if (searchTerm === searchTermUnsetValue) {
            // if the searchTerm is equal to the special value, clear both search terms
            setSearchTermFilter('');
            setSearchTerm('');
        }
    }, [searchTerm]);

    useEffect(() => {
        let actualOffset = 0;
        const cursorElement = document.getElementsByClassName('search-filter-input__input')[0] as HTMLElement;
        if (cursorElement) {
            const values = cursorElement.getBoundingClientRect();
            actualOffset = values.x;
        }

        const inputElement = document.getElementsByClassName('search-filter-input__value-container')[0] as HTMLElement;
        if (inputElement) {
            const values = inputElement.getBoundingClientRect();
            actualOffset = actualOffset - values.x;
        }

        const menuElement = document.getElementsByClassName('search-filter-input__menu')[0] as HTMLElement;
        if (menuElement) {
            menuElement.style.left = `${actualOffset}px`;
        }
    }, [selectedValue, menuIsOpen]);

    const CustomMenuMemo = useCallback((props) => CustomMenu(props), []);
    const CustomOptionMemo = useCallback((props) => CustomOption(props), []);
    const CustomMultiValueMemo = useCallback((props) => CustomMultiValue(props), []);
    const CustomClearIndicatorMemo = useCallback((props) => CustomClearIndicator(props), []);
    const CustomMultiValueLabelMemo = useCallback((props) => CustomMultiValueLabel(props), []);
    const CustomMultiValueRemoveMemo = useCallback((props) => CustomMultiValueRemove(props), []);
    const CustomDropdownIndicatorMemo = useCallback((props) => CustomDropdownIndicator(props), []);

    return (
        <>
            <SelectWrapper
                id={`search-filter-input`}
                className={'multiple'}
                $error={false}
                onClick={() => {
                    showUnsaved && renderUnsavedAlertBeforeAction();
                }}
            >
                {label && <label>{label}</label>}
                <Select
                    isMulti
                    isClearable
                    ref={selectRef}
                    options={options}
                    isLoading={loading}
                    isDisabled={loading}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    id={`search-filter-input-select`}
                    key={'search-filter-input-select'}
                    placeholder="Search or Filter Results"
                    classNamePrefix={'search-filter-input'}
                    inputValue={hasPrefill ? '' : searchTermFilter}
                    menuIsOpen={!hasPrefill && !showUnsaved && menuIsOpen}
                    value={!!searchTermFilter && selectedValue.length === 0 ? ([{}] as FilterObject[]) : selectedValue}
                    onInputChange={(value, { action }) => {
                        if (action === 'input-change' && !hasPrefill) {
                            if (showUnsaved) return renderUnsavedAlertBeforeAction();
                            setSearchTermFilter(value);
                            setMenuIsOpen(true);
                            !value.length && !selectedValue.length && onChange(value);
                        }
                    }}
                    onBlur={() => {
                        setSelectedValue((prevState: any[]) => prevState.filter((el: any) => el?.filterValue));
                        setOptions(extraFilters?.fields || fields);
                        setMenuIsOpen(false);
                    }}
                    onChange={(newValues: any, type: any) => {
                        if (showUnsaved) return renderUnsavedAlertBeforeAction();
                        if (type.option?.value === 'search') {
                            setMenuIsOpen(false);
                            return onChange(searchTermFilter);
                        }

                        // set-value type is sending only the selected value
                        const newValue = Array.isArray(newValues) ? newValues.pop() || {} : {};
                        let newSelectedValue: any = [...selectedValue];

                        switch (type.action) {
                            case 'select-option':
                            case 'deselect-option': // handle select a field already selected, which in our case will be the same as select-option
                                if (newValue?.type === 'field') {
                                    newSelectedValue.push({ field: newValue, value: newValue.value });
                                } else {
                                    newSelectedValue.at(-1).value = newSelectedValue.at(-1).value + newValue.value;
                                    newSelectedValue.at(-1).filterValue = newValue;
                                    setMenuIsOpen(false);
                                }
                                break;
                            case 'set-value': // handle select a non-option (datepicker)
                                newSelectedValue.at(-1).value = newSelectedValue.at(-1).value + newValues;
                                newSelectedValue.at(-1).filterValue = {
                                    label: getDateStandardFormat(newValues),
                                    value: newValues
                                };
                                setMenuIsOpen(false);
                                break;
                            case 'pop-value': // handle press on backspace
                                if (!type.removedValue) return;
                                if (type.removedValue.filterValue && type.removedValue.value !== 'prefill') {
                                    delete newSelectedValue.at(-1).filterValue;
                                } else {
                                    newSelectedValue = [...newSelectedValue].slice(0, -1);
                                }
                                setMenuIsOpen(true);
                                break;
                            case 'remove-value': // handle press on x on a value
                                newSelectedValue = newSelectedValue.filter((obj: any) => obj.value !== type.removedValue.value);
                                setTimeout(() => selectRef.current?.blur(), 0); // used to move the blur event to the end of the event loop
                                setMenuIsOpen(false);
                                break;
                            case 'clear': // handle clear all
                                // used to move the blur event to the end of the event loop
                                // since react-select already moved the focus to the end of the event loop, the value needs to be at least 1ms
                                // the value is set to 5ms to make it work on Firefox as well since the min value is 4ms on Firefox
                                setTimeout(() => selectRef.current?.blur(), 5);
                                newSelectedValue = [];
                                break;
                            default:
                                break;
                        }

                        setSearchTerm('');
                        setSearchTermFilter('');
                        setSelectedValue(newSelectedValue);
                        const lastValue = newSelectedValue?.at(-1) || {};
                        setOptions(
                            lastValue.filterValue || newSelectedValue.length === 0
                                ? extraFilters?.fields || fields
                                : extraFilters?.filterValues?.[lastValue.field?.value] || filterValues[lastValue.field?.value]
                        );

                        if (
                            (lastValue.filterValue && type.action === 'pop-value') ||
                            (!lastValue.filterValue && ['select-option', 'deselect-option', 'set-value'].includes(type.action))
                        )
                            return;

                        onChange(selectValueToObjectFilter(newSelectedValue.filter((el: any) => el?.filterValue)));
                    }}
                    filterOption={({ label, value, data }, inputValue) => {
                        const searchTerm = inputValue.toLowerCase().trim();

                        if (data.type === 'field') {
                            switch (value) {
                                case 'search':
                                    return selectedValue.length === 0 && searchTerm.length > 0;
                                case 'modifiedBefore':
                                case 'modifiedAfter':
                                    if (selectedValue.find((el: any) => el.field.value === value)) return false;
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            if (selectedValue.some((el: any) => el?.filterValue?.value === value)) return false;
                        }

                        return data?.valueForSearch
                            ? data?.valueForSearch.toLowerCase().includes(searchTerm)
                            : label.toLowerCase().includes(searchTerm);
                    }}
                    components={{
                        Menu: CustomMenuMemo,
                        Option: CustomOptionMemo,
                        MultiValue: CustomMultiValueMemo,
                        ClearIndicator: CustomClearIndicatorMemo,
                        MultiValueLabel: CustomMultiValueLabelMemo,
                        MultiValueRemove: CustomMultiValueRemoveMemo,
                        DropdownIndicator: CustomDropdownIndicatorMemo,
                        IndicatorSeparator: null
                    }}
                />
            </SelectWrapper>
        </>
    );
};

const CustomMenu = (props: any) => {
    const displayDateSelector =
        !props?.selectProps?.value?.at(-1)?.filterValue &&
        (props?.selectProps?.value?.at(-1)?.field?.value === 'modifiedBefore' ||
            props?.selectProps?.value?.at(-1)?.field?.value === 'modifiedAfter');

    return (
        <components.Menu {...props}>
            {displayDateSelector ? (
                <DateSelectorWrapper>
                    <DatePickerComponent
                        isOpen
                        autoFocus
                        withoutHeader
                        toggleOpenDialog={() => {}}
                        renderCustomInput={() => <></>}
                        saveDate={(date: any) => props.setValue(date)}
                        filterDate={(date) => new Date() > date && Date.now() - 4 * 31556926000 < date}
                    />
                </DateSelectorWrapper>
            ) : (
                props.children
            )}
        </components.Menu>
    );
};

const CustomOption = (props: any) => {
    return (
        <components.Option {...props}>
            <OptionContainer>
                {getIconComponent(props.data)}
                {props.data.tooltip ? (
                    renderTooltip(
                        <TruncatedText>{props.children}</TruncatedText>,
                        tooltipTypes.TEXT,
                        props.data.tooltip,
                        tooltipPositions.TOP
                    )
                ) : (
                    <TruncatedText>{props.children}</TruncatedText>
                )}
            </OptionContainer>
        </components.Option>
    );
};

const CustomMultiValue = ({ index, ...props }: any) => {
    const fieldLabel = props.data?.field?.label;
    const filterValueLabel = props.data?.filterValue?.label;

    return (
        <components.MultiValue {...props}>
            <MultiValueContainer>
                {fieldLabel && (
                    <>
                        <ObjectNameLabel>{fieldLabel}</ObjectNameLabel>
                        <ObjectNameLabel>{`=`}</ObjectNameLabel>
                    </>
                )}
                {filterValueLabel && (
                    <FilterValueWrapper>
                        {getIconComponent(props.data?.filterValue || {})}
                        {filterValueLabel}
                    </FilterValueWrapper>
                )}
            </MultiValueContainer>
        </components.MultiValue>
    );
};

const CustomMultiValueLabel = ({ index, ...props }: any) => {
    return (
        <components.MultiValueLabel {...props} innerProps={{ className: 'custom-value-label' }}>
            {props.children}
        </components.MultiValueLabel>
    );
};

const CustomMultiValueRemove = ({ index, ...props }: any) => {
    if (!props.data.filterValue) return null;

    return (
        <components.MultiValueRemove {...props}>
            <SVGInline src={icons.closeIcon} style={{ marginRight: '8px' }} />
        </components.MultiValueRemove>
    );
};

const CustomClearIndicator = (props: any) => {
    return (
        <components.ClearIndicator {...props}>
            <SearchSVGInline src={icons.closeIcon} />
        </components.ClearIndicator>
    );
};

const CustomDropdownIndicator = (props: any) => {
    if (props.hasValue) return null;

    return (
        <components.ClearIndicator {...props}>
            <SearchSVGInline src={icons.searchIcon} />
        </components.ClearIndicator>
    );
};

const getIconComponent = (data: any) => {
    const isTemplateIcon = Object.keys(templates).includes(data.icon);
    const icon = data.icon;
    const hasIcon = data.hasOwnProperty('icon');
    const IconComponent = isTemplateIcon ? TemplateIcons[data.icon] : icons[icon as keyof typeof icons];

    if (IconComponent) {
        return <SVGInline src={IconComponent} />;
    } else if (hasIcon) {
        return <UserAvatar background={icon || icons.userIcon} />;
    }
    return null;
};
