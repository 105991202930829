import React, { FC, useEffect, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogCheckbox,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import {
    AutoCollectionExtraFields,
    CheckboxListContainer,
    CheckboxWrapper,
    ModuleTypeWrapper,
    OptionSection,
    OptionsToggle,
    RadioButtonsWrapper,
    TargetConditionsInfo,
    TargetConditionsInfoWrapper,
    TargetConditionsWrapper,
    TemplateIconWrapper,
    TemplateOption
} from './NewModule.css';
import SVGInline from 'react-inlinesvg';
import icons, { TemplateIcons } from '../../../assets/images/icons';
import { Page, PageIntents } from '../../../types/Page';
import { isAutoCollectionSupported, Module, templates, ViewAllCardPlaces } from '../../../types/Module';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../hooks/redux';
import _ from 'lodash';
import { ModulesState } from '../../../redux/slices/moduleSlice';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import { validator } from '../../../utils/fnValidator';
import { Item, itemTypes } from '../../../types/Item';
import { allowedTemplatesForCollection, moduleTypes } from '../../../utils/Globals';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { DisplayConditionsState } from '../../../redux/slices/displayConditionsSlice';
import { actionNameMissingAlert, dialogAlert, ToastAlert } from '../../../utils/fnDialogs';
import usePrevious from '../../../hooks/usePrevious';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { InputLabelWithIconWrapper } from '../../common/Dialog/GenericDialog.css';
import Labels from '../../common/Labels/Labels';
import { MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { audiencesState } from '../../../redux/slices/audienceSlice';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';

type newModuleProps = {
    open: boolean;
    page?: Page;
    module?: Module;
    duplicate?: boolean;
    onSave: (arg: Module, arg2?: Item) => void;
    onClose?: () => void;
    restrictedCollection?: boolean;
};
export const TEMPLATES_WITHOUT_PREVIEW = [templates.GALLERY, templates.BANNER_XL, templates.BANNER_XXL, templates.BUTTON];

// for now they are the same, but separate the variables so in the future they change the adaptation will be easier
export const TEMPLATES_WITHOUT_TOP_TEN = TEMPLATES_WITHOUT_PREVIEW;
export const PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW = [
    PageIntents.BASIC,
    PageIntents.CONTENT_WORLD,
    PageIntents.HOME,
    PageIntents.FAVOURITES,
    PageIntents.RECORDINGS
];
export enum TargetConditionsTypes {
    PARENT = 'parent',
    MANUAL = 'manual'
}

export const NewModule: FC<newModuleProps> = ({ open, page, module, duplicate, restrictedCollection, onSave, onClose }) => {
    const [templateOptions, setTemplateOptions] = useState<any[]>([]);
    const [template, setTemplate] = useState<templates | undefined>(undefined);
    const [moduleType, setModuleType] = useState<moduleTypes>(moduleTypes.STRIPE);
    const [autoCollection, setAutoCollection] = useState<boolean>(false);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<any[]>([]);
    const [viewAllCardPlacement, setViewAllCardPlacement] = useState<ViewAllCardPlaces>('end');
    const [slug, setSlug] = useState('');
    const [invisible, setInvisible] = useState<boolean>(false);
    const [hideContentMarker, setHideContentMarker] = useState<boolean>(false);
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const [autoScrollValue, setAutoScrollValue] = useState('10');
    const [includeProviderLogoCard, setIncludeProviderLogoCard] = useState(false);
    const [pinnable, setPinnable] = useState(false);
    const [preview, setPreview] = useState(false);
    const [topTen, setTopTen] = useState(false);
    const [optionsExpanded, setOptionsExpanded] = useState(false);
    const [contentWorld, setContentWorld] = useState<boolean>(false);
    const [targetConditionsType, setTargetConditionsType] = useState<TargetConditionsTypes>(TargetConditionsTypes.PARENT);

    const [targets, setTargets] = useState<any[]>();
    const [itemIds, setItemIds] = useState<string[]>([]);

    const previousAutoCollectionValue = usePrevious(autoCollection);

    const [name, setName] = useState<string>('');
    const [errors, setErrors] = useState<{
        targets?: string;
        template?: string;
        autoScroll?: string;
    }>({});

    const { audiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { validTemplates, createOrModifyLoading }: ModulesState = useAppSelector((state) => state.modules);
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { selectedPage } = useAppSelector((state) => state.pages);

    useEffect(() => {
        if (!open) return;

        // we need to check if there is a module to not overwrite the page's conditions when creating a new module form PageEdit
        if (module) setTargets(module?.conditionIds || []);
        setName(module?.name || '');
        setTemplate(validTemplates?.find((template) => template.value === module?.template)?.value);
        setModuleType(module?.moduleType || moduleTypes.STRIPE);
        setAutoCollection(module?.autoCollection || false);
        setIncludeProviderLogoCard(module?.includeProviderLogoCard || false);
        setAbTestingGroupIds(module?.abTestingGroupIds || []);
        setTargetConditionsType(!!module?.conditionIds?.length ? TargetConditionsTypes.MANUAL : TargetConditionsTypes.PARENT);
        setPinnable(module?.pinnable || false);
        setPreview(module?.preview || false);
        setTopTen(module?.topTen || false);
        setInvisible(module?.invisible || false);
        setHideContentMarker(module?.hideContentMarker || false);
        setItemIds(module?.itemIds || []);
        setViewAllCardPlacement(module?.viewAllCardPlacement || 'end');
        setSlug(module?.slug || '');
        if (module?.template === templates.GALLERY) {
            setAutoScrollValue(module.autoScroll ? `${module.autoScroll / 1000}` : '10'); // value in seconds
            setAutoScroll(!!module.autoScroll);
        }
        setContentWorld(module?.contentWorld || false);
        setOptionsExpanded(
            module?.includeProviderLogoCard ||
                module?.invisible ||
                module?.pinnable ||
                module?.autoCollection ||
                module?.hideContentMarker ||
                module?.contentWorld ||
                module?.template === templates.GALLERY ||
                module?.preview ||
                module?.topTen ||
                false
        );
    }, [module, validTemplates, open]);

    useEffect(() => {
        if (!open) return;
        let options =
            validTemplates?.map((item, index) => ({
                value: item.value,
                label: (
                    <TemplateOption key={index}>
                        <TemplateIconWrapper>
                            <SVGInline src={TemplateIcons[item.key]} />
                        </TemplateIconWrapper>
                        <span>{item.title}</span>
                    </TemplateOption>
                ),
                valueForSearch: item.title
            })) || [];
        if (moduleType === moduleTypes.COLLECTION) {
            options = options.filter((opt) => {
                return allowedTemplatesForCollection.includes(opt.value);
            });
            if (!options.find((opt) => opt.value === template)) {
                setTemplate(undefined);
            }
        }
        if (page?.intent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(page.intent)) {
            options = options.filter((opt) => opt.value !== templates.GALLERY);
        }
        if (topTen) {
            options = options.filter((opt) => !TEMPLATES_WITHOUT_TOP_TEN.includes(opt.value));
        }

        if (preview) {
            options = options.filter((opt) => !TEMPLATES_WITHOUT_PREVIEW.includes(opt.value));
        }
        setTemplateOptions(options);
    }, [validTemplates, moduleType, preview, topTen, open]);

    // if autoCollection is unchecked, reset ViewAllCardPlacement and Slug
    useEffect(() => {
        if (previousAutoCollectionValue && !autoCollection) {
            setViewAllCardPlacement('end');
            setSlug('');
        }
    }, [autoCollection]);

    useEffect(() => {
        if (!isAutoCollectionSupported(template, preview)) {
            setAutoCollection(false);
        }
    }, [template, preview]);

    useEffect(() => {
        !autoScroll && setAutoScrollValue('10');
    }, [autoScroll]);

    const handleCloseClick = () => {
        onClose && onClose();
        setName('');
        setTargets([]);
        setTemplate(undefined);
        setModuleType(moduleTypes.STRIPE);
        setItemIds([]);
        setAutoCollection(false);
        setAutoScroll(true);
        setAutoScrollValue('10');
        setOptionsExpanded(false);
        setIncludeProviderLogoCard(false);
        setTargetConditionsType(TargetConditionsTypes.PARENT);
        setViewAllCardPlacement('end');
        setPinnable(false);
        setTopTen(false);
        setPreview(false);
        setInvisible(false);
        setHideContentMarker(false);
        setContentWorld(false);
        setSlug('');
        setErrors({});
        setAbTestingGroupIds([]);
    };

    const notSupportedTemplateAlertValues = {
        title: 'Warning',
        subtitle: `Auto-Collection is not supported for ${template} template.`,
        text: 'Auto-Collection will be disabled.'
    };

    const renderNotSupportedTemplateDialog = () => {
        return dialogAlert('', false, notSupportedTemplateAlertValues, undefined, false, icons.warningYellowIcon);
    };

    const shouldShowAutocollectionWarning = !isAutoCollectionSupported(template, preview) && autoCollection && !!module;
    const shouldShowActionNameWarning =
        !!module?.items?.length &&
        module.items.some((i) => i.itemType === itemTypes.EDITORIAL && !i.actionName) &&
        template &&
        [templates.GALLERY, templates.BUTTON].includes(template);

    const handleSave = () => {
        if (!validateModule() || !template) return;

        if (shouldShowAutocollectionWarning) {
            ToastAlert(
                'warning',
                notSupportedTemplateAlertValues.title,
                notSupportedTemplateAlertValues.subtitle,
                undefined,
                undefined,
                () => {
                    renderNotSupportedTemplateDialog();
                },
                shouldShowActionNameWarning
                    ? () => {
                          actionNameMissingAlert(true);
                      }
                    : undefined
            );
        }

        if (shouldShowActionNameWarning && !shouldShowAutocollectionWarning) {
            actionNameMissingAlert(true);
        }

        const newModule: Module = {
            _id: duplicate ? '' : module?._id || '',
            name: name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            template,
            moduleType: moduleType,
            itemIds,
            conditionIds: targetConditionsType === TargetConditionsTypes.PARENT ? [] : targets,
            autoCollection: isAutoCollectionSupported(template, preview) ? autoCollection : false,
            includeProviderLogoCard,
            viewAllCardPlacement,
            pinnable,
            topTen,
            preview,
            lastModified: module?.lastModified,
            invisible,
            hideContentMarker,
            contentWorld,
            slug,
            abTestingGroupIds
        };

        if (template === templates.GALLERY && autoScroll) {
            newModule.autoScroll = parseFloat(autoScrollValue) * 1000;
        }

        if (TEMPLATES_WITHOUT_PREVIEW.includes(template)) {
            newModule.preview = false;
        }
        if (TEMPLATES_WITHOUT_TOP_TEN.includes(template)) {
            newModule.topTen = false;
        }

        if (module?.autoScroll && !autoScroll) {
            newModule.autoScroll = 0;
        }

        if (moduleType === moduleTypes.COLLECTION) {
            newModule.preview = false;
            newModule.topTen = false;
            newModule.autoCollection = false;
            newModule.contentWorld = false;
        }

        onSave(newModule);
        handleCloseClick();
    };

    const saveButton: DialogButton = {
        label: !module ? 'Create' : 'Save',
        type: 'BLUE',
        onClick: handleSave,
        disabled: conditionsLoading || createOrModifyLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const validateModule = () => {
        const newErrors = { ...errors };
        newErrors.template = validator({ required: true }, template ? `${template}` : '');

        if (template === templates.GALLERY && autoScroll) {
            newErrors.autoScroll = validator({ required: true }, autoScrollValue);
        }

        const hasAudiences = targets ? targets.some((target) => audiences.find((audience) => audience._id === target)) : false;
        if (targetConditionsType === TargetConditionsTypes.MANUAL && !hasAudiences) {
            newErrors.targets = 'Please select at least one Audience, or use the Parent’s conditions.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const renderContentWorldTooltip = (): string => {
        switch (true) {
            case disableContentWorld:
                return 'module_content_world_disabled_dynamic_list';
            case includeProviderLogoCard:
                return 'module_content_world_disabled_provider_logo';
            case topTen:
                return 'module_content_world_disabled_topTen';
            case autoCollection:
                return 'module_content_world_disabled_auto_collection';
            default:
                return 'module_content_world';
        }
    };

    const renderAutoCollectionTooltip = (): string => {
        switch (true) {
            case disableAutoCollection:
                return 'modules_auto_collection_disabled';
            case contentWorld:
                return 'modules_auto_collection_disabled_contentWorld';
            case topTen:
                return 'modules_auto_collection_disabled_topTen';
            default:
                return 'modules_auto_collection_info';
        }
    };

    const renderInvisibleModuleOption = () => {
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setInvisible(!invisible);
                    }}
                    value={invisible}
                    text={'Hide Module on Page'}
                    active={invisible}
                    disabled={pinnable}
                />
                {renderTooltipWithKey(
                    <SVGInline src={icons.infoIcon} />,
                    pinnable ? 'modules_invisible_info_disabled' : 'modules_invisible_info'
                )}
            </CheckboxWrapper>
        );
    };

    const renderHideContentMarkerOption = () => {
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setHideContentMarker(!hideContentMarker);
                    }}
                    value={hideContentMarker}
                    text={'Hide Content Marker'}
                    active={hideContentMarker}
                />
                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'module_hide_content_marker_info')}
            </CheckboxWrapper>
        );
    };

    const renderContentWorldOption = () => {
        if (moduleType !== moduleTypes.STRIPE) return;
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setContentWorld(!contentWorld);
                    }}
                    value={contentWorld}
                    text={'Content World'}
                    active={contentWorld}
                    disabled={disableContentWorld || includeProviderLogoCard || topTen || autoCollection}
                />
                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, renderContentWorldTooltip())}
            </CheckboxWrapper>
        );
    };

    const renderAutoScrollOption = () => {
        if (template !== templates.GALLERY) return null;
        return (
            <>
                <DialogToggleButton checked={autoScroll} text={'Auto Scroll'} toggleCallback={() => setAutoScroll(!autoScroll)} />
                {autoScroll && (
                    <AutoCollectionExtraFields>
                        <DialogTextField
                            placeholder={'Enter auto scroll value in seconds'}
                            label={'Select Time (sec)'}
                            InputProps={{ endAdornment: <span>(sec)</span> }}
                            value={autoScrollValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const { value } = e.target;
                                const floatingNumberValue = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                setAutoScrollValue(floatingNumberValue);
                                setErrors(_.omit(errors, 'autoscroll'));
                            }}
                            error={errors.autoScroll}
                        />
                    </AutoCollectionExtraFields>
                )}
            </>
        );
    };

    const renderIncludeProviderLogoCardOption = () => {
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setIncludeProviderLogoCard(!includeProviderLogoCard);
                    }}
                    value={includeProviderLogoCard}
                    text={'Provider Logo Card'}
                    active={includeProviderLogoCard}
                    disabled={!isEveryItemSameProvider || contentWorld}
                />
                {renderTooltipWithKey(
                    <SVGInline src={icons.infoIcon} />,
                    contentWorld
                        ? 'modules_include_provider_card_disabled_contentWorld'
                        : isEveryItemSameProvider
                        ? 'modules_include_provider_card_info'
                        : 'modules_include_provider_card_disabled_same_provider'
                )}
            </CheckboxWrapper>
        );
    };

    const renderPinnableOption = () => {
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setPinnable(!pinnable);
                    }}
                    value={pinnable}
                    text={'Pinnable'}
                    active={pinnable}
                    disabled={invisible}
                />
                {renderTooltipWithKey(
                    <SVGInline src={icons.infoIcon} />,
                    invisible ? 'modules_is_pinnable_info_disabled' : 'modules_is_pinnable_info'
                )}
            </CheckboxWrapper>
        );
    };

    const renderTopTenOption = () => {
        if (template && TEMPLATES_WITHOUT_TOP_TEN.includes(template)) return null;
        if (moduleType !== moduleTypes.STRIPE) return null;
        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setTopTen(!topTen);
                    }}
                    value={topTen}
                    text={'Top X'}
                    active={topTen}
                    disabled={contentWorld || autoCollection}
                />
                {renderTooltipWithKey(
                    <SVGInline src={icons.infoIcon} />,
                    contentWorld
                        ? 'modules_top_ten_info_disabled_contentWorld'
                        : autoCollection
                        ? 'modules_top_ten_info_disabled_auto_collection'
                        : 'modules_top_ten_info'
                )}
            </CheckboxWrapper>
        );
    };

    const renderPreviewOption = () => {
        if (template && TEMPLATES_WITHOUT_PREVIEW.includes(template)) return null;
        if (page?.intent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(page?.intent)) return null;
        if (moduleType !== moduleTypes.STRIPE) return null;

        return (
            <CheckboxWrapper>
                <DialogCheckbox
                    onClick={() => {
                        setPreview(!preview);
                    }}
                    value={preview}
                    text={'Preview'}
                    active={preview}
                />
                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'modules_preview_info')}
            </CheckboxWrapper>
        );
    };

    const renderIncludeAutoCollectionOption = () => {
        if (moduleType !== moduleTypes.STRIPE || !isAutoCollectionSupported(template, preview)) return;

        return (
            <>
                <>
                    <DialogToggleButton
                        checked={autoCollection}
                        text={'Include Auto-Collection'}
                        toggleCallback={() => setAutoCollection(!autoCollection)}
                        disabled={disableAutoCollection || contentWorld || topTen}
                        tooltipText={renderAutoCollectionTooltip()}
                    />
                </>

                {autoCollection && (
                    <>
                        <AutoCollectionExtraFields>
                            <DialogRadioButton
                                onClick={() => setViewAllCardPlacement('end')}
                                text={
                                    <>
                                        View all card is placed on the <b>End</b>
                                    </>
                                }
                                active={viewAllCardPlacement === 'end'}
                                disabled={disableAutoCollection}
                            />
                            <DialogRadioButton
                                onClick={() => setViewAllCardPlacement('beginning')}
                                text={
                                    <>
                                        View all card is placed on the <b>Beginning</b>
                                    </>
                                }
                                active={viewAllCardPlacement === 'beginning'}
                                disabled={disableAutoCollection}
                            />
                            <DialogTextField
                                label={'Slug'}
                                toolTipText={'The slug is used for generating web-friendly URLs'}
                                placeholder={''}
                                value={slug}
                                onChange={(e: any) => setSlug(e.target.value.replace(/[^a-zA-Z0-9\-]/g, ''))}
                            />
                        </AutoCollectionExtraFields>
                    </>
                )}
            </>
        );
    };

    const renderOptions = () => {
        return (
            <>
                <CheckboxListContainer>
                    {renderPinnableOption()}
                    {renderContentWorldOption()}
                    {renderIncludeProviderLogoCardOption()}
                    {renderPreviewOption()}
                    {renderInvisibleModuleOption()}
                    {renderTopTenOption()}
                    {renderHideContentMarkerOption()}
                </CheckboxListContainer>
                {renderIncludeAutoCollectionOption()}
                {renderAutoScrollOption()}
            </>
        );
    };

    if (!open) return null;

    // autoCollection is enabled only if there's no items in the module or there's only one dynamic item
    const hasEditorialItem = module?.items?.some((item: any) => item.itemType === itemTypes.EDITORIAL);
    const disableAutoCollection = (module?.items && module.items.length > 1) || (hasEditorialItem && module?.items?.length === 1);

    // contentWorld should be enabled only for modules with editorial items
    const disableContentWorld = module?.items?.some((item) => item.itemType === itemTypes.DYNAMIC);

    // if there's no module(means we are creating), or there's no item inside the module,
    // then we allow the Provider logo checkbox otherwise check the items
    const isEveryItemSameProvider =
        !module ||
        !module.items?.length ||
        module.items
            ?.filter((item: any) => item.itemType === itemTypes.DYNAMIC)
            .every((item, _, items) => item.contentSourceType === items[0].contentSourceType);

    const warnings: any[] = [];
    if (shouldShowAutocollectionWarning) {
        warnings.push({ icon: icons.infoIcon, onClick: () => renderNotSupportedTemplateDialog() });
    }

    if (shouldShowActionNameWarning) {
        warnings.push({ icon: icons.warningIcon, critical: true, onClick: () => actionNameMissingAlert(true, false) });
    }

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={module ? (duplicate ? 'Duplicate Module' : 'Edit Module') : 'Create Module'}
            onClose={() => handleCloseClick()}
            actionButtons={[cancelButton, saveButton]}
            dataCy={'new-dialog-module'}
            warnings={warnings}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.modules }}
        >
            <ModuleTypeWrapper>
                <DialogRadioButton
                    onClick={() => setModuleType(moduleTypes.STRIPE)}
                    text={_.startCase(moduleTypes.STRIPE)}
                    active={moduleType === moduleTypes.STRIPE}
                    disabled={!!module}
                />
                <DialogRadioButton
                    text={_.startCase(moduleTypes.COLLECTION)}
                    active={moduleType === moduleTypes.COLLECTION}
                    disabled={!!module || restrictedCollection}
                    onClick={() => {
                        setModuleType(moduleTypes.COLLECTION);
                        setAutoCollection(false);
                        setTopTen(false);
                        setPreview(false);
                        setContentWorld(false);
                    }}
                />
                {!!module && renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'modules_type_disabled')}
                {!module &&
                    restrictedCollection &&
                    renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'collection_module_type_disabled')}
            </ModuleTypeWrapper>
            <DialogDropdownSingle
                options={templateOptions}
                value={templateOptions.find((option) => option.value === template) || null}
                placeholder={'UI Template'}
                labelText={'UI Template'}
                onChange={(value: any) => {
                    setTemplate(value.value);
                    setErrors(_.omit(errors, 'template'));
                    !optionsExpanded && value.value === templates.GALLERY && setOptionsExpanded(true);
                }}
                error={errors.template}
                dataCy={'module-template-dropdown'}
            />

            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                }}
                label={'Module Name'}
                placeholder={'Module Name'}
                optional={true}
                toolTipText={'It can be a translation key or the name itself'}
                withTranslations
                dataCy={'module-name-input'}
            />

            <TargetConditionsWrapper>
                <label>
                    <InputLabelWithIconWrapper>
                        Target Conditions
                        {renderTooltip(
                            <SVGInline src={icons.infoIcon} />,
                            tooltipTypes.TEXT,
                            targetConditionsType === TargetConditionsTypes.MANUAL
                                ? 'Select an existing or create a new condition'
                                : 'The target conditions will be automatically inherited from the parent object',
                            tooltipPositions.TOP
                        )}
                    </InputLabelWithIconWrapper>
                </label>
                <RadioButtonsWrapper>
                    <DialogRadioButton
                        onClick={() => {
                            setTargetConditionsType(TargetConditionsTypes.PARENT);
                            setErrors(_.omit(errors, 'targets'));
                        }}
                        active={targetConditionsType === TargetConditionsTypes.PARENT}
                        text={'Parent'}
                    />
                    <DialogRadioButton
                        onClick={() => setTargetConditionsType(TargetConditionsTypes.MANUAL)}
                        active={targetConditionsType === TargetConditionsTypes.MANUAL}
                        text={'Manual'}
                    />
                </RadioButtonsWrapper>
            </TargetConditionsWrapper>
            {targetConditionsType === TargetConditionsTypes.PARENT ? (
                <TargetConditionsInfoWrapper>
                    {/* If selectedPage it means the dialog is in Visual Editorial and we need to display the Audiences */}
                    {!selectedPage ? (
                        <TargetConditionsInfo>
                            {!module || !module?.placed?.length
                                ? 'The Module will automatically inherit the Page’s Audiences when added to it.'
                                : 'The Module has one or multiple parents. Navigate to the parent objects to check the exact targeting conditions.'}
                        </TargetConditionsInfo>
                    ) : (
                        <Labels values={selectedPage?.conditions || []} type={MoreInfoTypes.TARGETS} noOfLabels={3} blackLabels />
                    )}
                </TargetConditionsInfoWrapper>
            ) : (
                <TargetConditionsSelect
                    inDialog
                    withoutLabel
                    error={errors.targets}
                    targets={targets || []}
                    onChange={(val) => {
                        setTargets(val);
                        setErrors(_.omit(errors, 'targets'));
                    }}
                />
            )}

            <ABTestingGroupSelect
                inDialog
                selectedGroups={abTestingGroupIds}
                onChange={(newValues) => setAbTestingGroupIds(newValues)}
                selectedTargetConditions={targets || []}
            />

            <OptionSection>
                <OptionsToggle onClick={() => setOptionsExpanded(!optionsExpanded)}>
                    Options <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </OptionsToggle>
                {optionsExpanded && renderOptions()}
            </OptionSection>
        </GenericDialog>
    );
};
