import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { InputLabelWithIconWrapper } from '../../common/Dialog/GenericDialog.css';

const theme: any = getTheme();

export const NewSourceWrapper = styled.div``;

export const ParamsContainer = styled.div`
    ${InputLabelWithIconWrapper} {
        ${fontSizesCSS.Caption3};
        color: ${theme.palette.text.main};
        margin-bottom: 4px;
        svg {
            cursor: pointer;
        }
    }
`;
