import React from 'react';
import { docsWidget } from '../../App';
import { NewsDescription, NewsSubtitle, NewsTitle, NewsWrapper } from './News.css';

const News = () => {
    return (
        <NewsWrapper
            onClick={() => {
                if (!docsWidget) return;
                docsWidget.setSlug('', 'control-center-news');
                docsWidget.element?.click();
            }}
        >
            <NewsTitle>
                <span>Updates:</span> Option to enable Auto-Play, Unique Customers Data Chart in Analyze, Monetize capability, Filter for
                Display Conditions
            </NewsTitle>
            <NewsSubtitle>
                The following updates are available from Version 1.36.0-0 of the Control Center:
                <br />
                The Auto-Play feature can now be enabled for Dynamic Lists and Editorial items. For Editorial Items, the editor can
                configure the video that should be played in the “Video for Auto-Play field. On the Analyze page, a new data chart called
                “Unique Customers” was added. On the Pages, Modules, and List&Items overview pages, the filter was updated and the “Target
                Condition” filtering option was split into two, the “Audiences” and the “Display Conditions” options, for an easier
                overview. The first iteration of the Monetize capability was implemented as a Proof of Concept, enabling the possibility of
                configuring ad rules using Google Ad Manager.
            </NewsSubtitle>
            <NewsDescription>Click here to open the news section of the documentation.</NewsDescription>
        </NewsWrapper>
    );
};

export default News;
