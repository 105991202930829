import { useEffect, useState } from 'react';
import { lockObject, unlockObject } from '../redux/slices/lockSlice';
import { UserProfileState } from '../redux/slices/profileSlice';
import { Item } from '../types/Item';
import { Module } from '../types/Module';
import { Page } from '../types/Page';
import { Audience } from '../types/Audience';
import { Menu } from '../types/Menu';
import { useAppSelector, useAppDispatch as useDispatch } from './redux';
import { DisplayCondition } from '../types/DisplayCondition';
import { Language } from '../types/Language';
import { PageStyle } from '../types/PageStyle';
import { Setting } from '../types/Setting';
import { DynamicSource } from '../types/DynamicSource';
import { CC3BaseObject } from '../types/Object';

type LockableObjects =
    | Page
    | Module
    | Item
    | Audience
    | Menu
    | DisplayCondition
    | Language
    | PageStyle
    | DynamicSource
    | Setting
    | CC3BaseObject;
export enum LockableObjectTypes {
    PAGES = 'pages',
    MODULES = 'modules',
    ITEMS = 'items',
    MENUS = 'menus',
    LANGUAGES = 'languages',
    AUDIENCES = 'audiences',
    DISPLAY_CONDITIONS = 'display_conditions',
    SETTINGS = 'settings',
    PAGE_STYLES = 'pageStyles',
    DYNAMIC_SOURCES = 'dynamicSources',
    CC3BASE_OBJECT = 'CC3BaseObject'
}

const useLockSystem = (objectType: LockableObjectTypes) => {
    const { userProfile }: UserProfileState = useAppSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [unlockIdOnClose, setUnlockIdOnClose] = useState<string>('');

    const lock = async (id: string) => {
        try {
            await dispatch(lockObject({ objectId: id, objectType })).unwrap();
        } catch (ex) {} // if a module is locked, doing a lock or unlock on it will throw an exception. we should catch it but don't need to handle it
    };

    const unlock = async (id: string) => {
        try {
            await dispatch(unlockObject({ objectId: id, objectType })).unwrap();
        } catch (ex) {} // if a module is locked, doing a lock or unlock on it will throw an exception. we should catch it but don't need to handle it
    };

    useEffect(() => {
        if (!unlockIdOnClose) return;

        const unloadCallback = async (event: any) => {
            event.preventDefault();
            unlock(unlockIdOnClose);
        };
        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
            unlock(unlockIdOnClose);
        };
    }, [unlockIdOnClose]);

    const isObjectLocked = (object: LockableObjects) => {
        return !!object?.locked && !!userProfile?._id && object.locked !== userProfile?._id;
    };

    const objectIsLockedBy = (object: LockableObjects) => {
        return object?.lockedByUser?.name || '';
    };

    return { isObjectLocked, objectIsLockedBy, lock, unlock, unlockOnClose: setUnlockIdOnClose };
};

export default useLockSystem;
