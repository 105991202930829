import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import { PageRoutes } from '../../../../types/RouteTypes';
import { useNavigate, useParams } from 'react-router-dom';
import PageValues, { PAGE_VIEWS, pageValues, renderGalleryAlert } from '../../../PageEdit/PageValues';
import PageActions from '../../../common/PageActions/PageActions';
import {
    createTemplate as _createTemplate,
    fetchTemplate,
    fetchTemplates,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    unsetSelectedTemplate,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { PageNameContainer, UnsavedSpan } from '../../../common/DashboardTitle/DashboardTitle.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../style';
import { fetchPageIntents, pagesState } from '../../../../redux/slices/pagesSlice';
import { PageIntents } from '../../../../types/Page';
import { Loader } from '../../../common/Loader/Loader';
import CreateResourceDialog from '../../../common/Dialog/CreateResourceDialog';
import UseExistingDialog, { EXISTING_ITEMS } from '../../../PageEdit/Dialogs/UseExistingDialog';
import { Template, templateType, templateTypes } from '../../../../types/Template';
import { AddModulestyle, PageOptionsContainer, PageOptionsTitle } from '../../../PageEdit/PageEdit.css';
import Button from '../../../Buttons/Button/Button';
import useScreenSize from '../../../../hooks/useScreenSize';
import GenericTable, { ActionsTableCell, DraggableTableRow, tableActions } from '../../../common/Table/Table';
import { WidthTableCell } from '../../../common/Table/Table.css';
import { TableBody, TableCell } from '@material-ui/core';
import { ContentTemplateLogo, NameWrapper, RemoveModuleWrapper, TemplateHeaderStyle } from '../../../Modules/Modules.css';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import { TemplateIcons } from '../../../../assets/images/icons';
import { fetchModulesTemplates, ModulesState } from '../../../../redux/slices/moduleSlice';
import { DRAGGABLE_TYPES, EMPTY_WORD_STRING, getImgixUrl, imgixBaseUrl, imgixFMBaseUrl, moduleTypes } from '../../../../utils/Globals';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../../../utils/fnDialogs';
import { checkSameOrder } from '../../../../utils/fnData';
import { templates } from '../../../../types/Module';
import useBlockNavigation from '../../../../hooks/useBlockNavigation';
import NewModule from '../Modules/NewModule';
import { ImageFields } from '../../../StyleAndBranding/PageStyle/PageStyle.css';
import { DialogCheckbox, DialogFileField, DialogToggleButton } from '../../../common/Dialog/GenericDialog';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import _ from 'lodash';
import { supportedResolutionsType } from '../../../Items/Dialogs/BackgroundDialog';
import { isOpenFileUrl } from '../../../../utils/fnUrl';
import { uploadFilesSync } from '../../../../redux/slices/fileManagerSlice';
import { handlePageImages } from '../../../PageEdit/PageEdit';
import { ModulesTemplatesTableSizes } from '../../../../types/TableSizes';
import { itemTypes } from '../../../../types/Item';
import { PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW } from '../../../Modules/Dialogs/NewModule';
import { ToggleButtonContainer } from '../../../common/Dialog/GenericDialog.css';
import { validator } from '../../../../utils/fnValidator';
import { acceptedExtensionsPageBackground, pageStyleImageInfo } from '../../../StyleAndBranding/PageStyle/PageStyle';

const DEFAULT_STYLE_LABEL = `Project's Default Page Style`;
const DEFAULT_STYLE_VALUE = 'default_style';

export const PageTemplateEdit: FC = () => {
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [pageName, setPageName] = useState<string>('');
    const [pageIntent, setPageIntent] = useState<string>('');
    const [pageStyle, setPageStyle] = useState<string>('');
    const [modules, setModules] = useState<any[]>([]);
    const [pageStylesOptions, setPageStylesOptions] = useState<any[]>([]);

    const [openNewResourceDialog, setOpenNewResourceDialog] = useState<boolean>(false);
    const [openNewModuleDialog, setOpenNewModuleDialog] = useState<boolean>(false);
    const [openUseExistingDialog, setOpenUseExistingDialog] = useState<boolean>(false);
    const [newModuleId, setNewModuleId] = useState<string>('');
    const [showGalleryWarning, setShowGalleryWarning] = useState<boolean>(false);
    const [moduleToEdit, setModuleToEdit] = useState<Template | undefined>(undefined);

    const [pageHasCollection, setPageHasCollection] = useState<boolean>(false);
    const [allowedNumberOfModules, setAllowedNumberOfModules] = useState<number>(0);
    const [pageHasContentWorld, setPageHasContentWorld] = useState<boolean>(false);

    const [isPageOptionsOpen, setIsPageOptionsOpen] = useState<boolean>(false);
    const [applyBackgroundForAll, setApplyBackgroundForAll] = useState<boolean>(true);
    const [backgroundImageFiles, setBackgroundImageFiles] = useState<supportedResolutionsType>({});
    const [backgroundImageUrls, setBackgroundImageUrls] = useState<supportedResolutionsType>({});
    const [pageBackgroundImage, setPageBackgroundImage] = useState<supportedResolutionsType>({});
    const [initialBackgroundImage, setInitialBackgroundImage] = useState<supportedResolutionsType>({});
    const [pageIncreasedTopMargin, setPageIncreasedTopMargin] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    const { validTemplates }: ModulesState = useAppSelector((state) => state.modules);
    const { intents }: pagesState = useAppSelector((state) => state.pages);
    const { templates: storeTemplates, selectedTemplate, templateTypeLoading }: templatesState = useAppSelector((state) => state.templates);
    const unsavedDependencyArray = [
        selectedTemplate,
        pageName,
        pageStyle,
        pageIntent,
        modules,
        pageIncreasedTopMargin,
        pageBackgroundImage
    ];

    useBlockNavigation(showUnsaved, () => renderAlertShowUnsaved(), unsavedDependencyArray);

    const pageId = useParams().page_id;

    const loadPageIntents = async () => {
        if (!intents.length) {
            return await dispatch(fetchPageIntents({})).unwrap();
        }
    };

    const loadTemplate = async (id: string) => {
        await dispatch(fetchTemplate({ templateId: id, type: templateTypes.PAGE })).unwrap();
    };

    const loadTemplates = async (type: templateType) => {
        await dispatch(fetchTemplates({ type })).unwrap();
    };

    const loadModuleTemplates = async () => {
        await dispatch(fetchModulesTemplates()).unwrap();
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response.id) {
            loadTemplate(pageId || '');
            moduleToEdit && setNewModuleId(moduleToEdit._id);
        }
    };

    const loadPageStyleTemplates = async () => {
        await loadTemplates(templateTypes.PAGE_STYLE);
    };

    const releaseTemplate = async () => {
        if (!moduleToEdit) return;
        const response = await dispatch(_releaseTemplate({ templateId: moduleToEdit._id, type: templateTypes.MODULE })).unwrap();
        if (response) {
            setNewModuleId(moduleToEdit._id);
            loadTemplate(pageId || '');
        }
    };

    const retractTemplate = async () => {
        if (!moduleToEdit) return;
        const response = await dispatch(_retractTemplate({ templateId: moduleToEdit._id, type: templateTypes.MODULE })).unwrap();
        if (response) {
            setNewModuleId(moduleToEdit._id);
            loadTemplate(pageId || '');
        }
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (response.id) {
            handleSaveClick(response.id);
            setNewModuleId(response.id);
        }
    };

    const removeModuleFromPage = async (id: string) => {
        const index = modules.findIndex((module) => module._id === id);
        if (index === -1) return;
        const newModules = [...modules];
        newModules.splice(index, 1);
        setModules(newModules);
    };

    const resetState = () => {
        setPageName('');
        setPageStyle('');
        setPageIntent('');
        setModules([]);
        setPageBackgroundImage({});
        setInitialBackgroundImage({});
        dispatch(unsetSelectedTemplate());
        setNameError('');
    };

    const renderAlertShowUnsaved = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => handleSaveClick(),
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                setShowUnsaved(false);
            },
            true
        );
    };
    const handleRemoveIconClick = (module: Template) => {
        const values = {
            title: 'Remove Module From Page Template',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeModuleFromPage(module._id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    Are you sure you want to remove the <strong>Module{`(${module.values?.name || EMPTY_WORD_STRING})`}</strong> from the{' '}
                    <strong>Page{`(${pageName || EMPTY_WORD_STRING})`}</strong>?
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const validatePage = () => {
        const name = validator({ required: true }, pageName);
        setNameError(name);
        return !name;
    };

    const handleSaveClick = async (newModuleId?: string) => {
        if (!validatePage()) return;

        const newModules = modules.length ? modules.map((module) => module._id) : [];
        const hasCollection = modules.find((module) => module.values?.moduleType === moduleTypes.COLLECTION);
        if (newModuleId) {
            if (hasCollection) {
                newModules.unshift(newModuleId);
            } else {
                newModules.push(newModuleId);
            }
        }

        const newPageTemplate = {
            ...selectedTemplate,
            values: {
                ...selectedTemplate?.values,
                name: pageName,
                intent: pageIntent,
                moduleTemplatesIds: newModules,
                pageStyleTemplateId: pageStyle === DEFAULT_STYLE_VALUE ? '' : pageStyle,
                increasedTopMargin: pageIncreasedTopMargin
            }
        };

        const imageResult = await handlePageImages(
            backgroundImageFiles,
            backgroundImageUrls,
            applyBackgroundForAll,
            initialBackgroundImage,
            createFiles
        );

        if (!!imageResult) {
            newPageTemplate.values.backgroundImage = imageResult;
        }

        if (showGalleryWarning) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.GALLERY_NOT_FIRST_MODULE, () => {
                renderGalleryAlert();
            });
        }

        updateTemplate(newPageTemplate);
    };

    const calculateContentSourceValueForTemplate = (module: Template) => {
        if (!(module?.itemTemplates && module?.itemTemplates?.length)) return '(No source)';
        if (module.itemTemplates?.every((i) => i.values?.itemType === itemTypes.EDITORIAL)) return 'Editorial';
        if (module.itemTemplates.every((i) => i.values?.contentSourceType === module.itemTemplates?.[0]?.values?.contentSourceType)) {
            return _.capitalize(module.itemTemplates?.[0].values?.contentSourceType);
        }
        return 'Multiple sources';
    };

    const handlePageValuesChange = (values: Record<keyof pageValues, any>) => {
        if ('name' in values) {
            setPageName(values.name || '');
            setNameError('');
        }

        'intent' in values && setPageIntent(values.intent || '');
        'pageStyle' in values && setPageStyle(values.pageStyle || '');
    };

    // PAGE OPTIONS

    const createFiles = async (files: File[]) => {
        const prefix = `system_templates/page_backgrounds`;
        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const calculateApplyForAll = (backgroundImages: any) => {
        const values: any[] = Object.values(backgroundImages);
        if (!values.length) return true;
        const firstUrl = decodeURIComponent(values[0]);
        const isOpenUrl = isOpenFileUrl(firstUrl);
        const firstFileName = !isOpenUrl
            ? firstUrl.split(values[0].includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
            : firstUrl.split('/').pop();
        return (
            !values
                .filter((_, index) => index > 0)
                .some((value) => {
                    const url = decodeURIComponent(value);
                    const isOpenUrl = isOpenFileUrl(url);
                    const fileName = !isOpenUrl
                        ? url.split(value.includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
                        : url.split('/').pop();
                    return fileName !== firstFileName;
                }) && values.length === 4
        );
    };

    const handleImageSelect = (image: File | string, key: keyof supportedResolutionsType) => {
        let newImages = { ...backgroundImageFiles };
        let newUrls = { ...backgroundImageUrls };
        let pageImage = { ...pageBackgroundImage };
        let initialPageImage = { ...initialBackgroundImage };

        if (!image) {
            if (applyBackgroundForAll) {
                newImages = {};
                newUrls = {};
                pageImage = {};
                initialPageImage = {};
            } else {
                newImages = _.omit(newImages, key);
                newUrls = _.omit(newUrls, key);
                pageImage = _.omit(pageImage, key);
                initialPageImage = _.omit(initialPageImage, key);
            }
        } else {
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`system_templates`);
                _.set(newImages, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(newUrls, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(pageImage, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
            } else {
                _.set(newImages, key, image);
                _.set(pageImage, key, image);
            }
        }

        setBackgroundImageFiles(newImages);
        setBackgroundImageUrls(newUrls);
        setPageBackgroundImage(pageImage);
        setInitialBackgroundImage(initialPageImage);
    };

    const checkTemplatesOrder = () => {
        const hasGallery = modules.find((module) => module.values.template === templates.GALLERY);
        const newModuleTemplates = modules.map((module) => module.values.template) || [];

        if (hasGallery) {
            for (let index = 1; index < newModuleTemplates.length; index++) {
                const prevTemplate = newModuleTemplates[index - 1];
                if (prevTemplate !== templates.GALLERY && newModuleTemplates[index] === templates.GALLERY) {
                    return setShowGalleryWarning(true);
                }
            }
        }
        setShowGalleryWarning(false);
    };

    const findModule = useCallback(
        (id: string) => {
            const module = modules.find((module: Template) => module._id === id) || modules[0];
            return {
                module,
                index: modules.indexOf(module)
            };
        },
        [modules]
    );

    const moveModule = useCallback(
        (id: string, atIndex: number) => {
            const { module, index } = findModule(id);
            setModules(() => {
                const newModules = [...modules];
                newModules.splice(index, 1);
                newModules.splice(atIndex, 0, module);
                return newModules;
            });
        },
        [findModule, modules, setModules]
    );

    useEffect(() => {
        loadPageIntents();
        loadModuleTemplates();
        loadPageStyleTemplates();

        return () => {
            resetState();
        };
    }, []);

    useEffect(() => {
        const pageStylesOpt =
            storeTemplates.pageStyle?.map((style) => {
                return {
                    label: style.values?.name || EMPTY_WORD_STRING,
                    value: style._id
                };
            }) || [];

        pageStylesOpt.push({ label: DEFAULT_STYLE_LABEL, value: DEFAULT_STYLE_VALUE });

        setPageStylesOptions(pageStylesOpt);
    }, [storeTemplates]);

    useEffect(() => {
        checkTemplatesOrder();
    }, [modules]);

    useEffect(() => {
        setPageHasCollection(
            modules.some((module) => module.values?.moduleType === moduleTypes.COLLECTION) || pageIntent === PageIntents.AUTO_COLLECTION
        );
        setAllowedNumberOfModules(modules.some((module) => module.values?.moduleType === moduleTypes.COLLECTION) ? 1 : 0);
        setPageHasContentWorld(modules.some((module) => !!module.values?.contentWorld));
    }, [modules, pageIntent]);

    useEffect(() => {
        const modulesIds = modules.map((module) => module._id);
        const initialModulesIds = (selectedTemplate?.moduleTemplates || []).map((module: Template) => module._id);
        const styleToCheck = pageStyle === DEFAULT_STYLE_VALUE ? '' : pageStyle;

        setShowUnsaved(() => {
            if (!checkSameOrder(initialModulesIds, modulesIds)) {
                return true;
            }

            return (
                selectedTemplate?.values?.name !== pageName ||
                (selectedTemplate?.values?.intent || PageIntents.BASIC) !== pageIntent ||
                (selectedTemplate?.values?.pageStyleTemplateId || '') !== styleToCheck ||
                (selectedTemplate?.values?.increasedTopMargin || false) !== pageIncreasedTopMargin ||
                JSON.stringify(selectedTemplate?.values?.backgroundImage || {}) !== JSON.stringify(pageBackgroundImage)
            );
        });
        //calculate showunsaved
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        pageId && loadTemplate(pageId);
    }, [pageId]);

    useEffect(() => {
        if (!selectedTemplate) return;
        setPageName(selectedTemplate.values?.name || '');
        setPageIntent(selectedTemplate.values?.intent || PageIntents.BASIC);
        setModules(selectedTemplate?.moduleTemplates || []);
        setPageStyle(selectedTemplate.values?.pageStyleTemplateId || DEFAULT_STYLE_VALUE);
        setApplyBackgroundForAll(calculateApplyForAll(selectedTemplate.values?.backgroundImage || {}));
        setPageBackgroundImage(selectedTemplate.values?.backgroundImage || {});
        setInitialBackgroundImage(selectedTemplate.values?.backgroundImage || {});
        setPageIncreasedTopMargin(selectedTemplate.values?.increasedTopMargin || false);

        if (newModuleId && openNewModuleDialog) {
            setModuleToEdit(selectedTemplate.moduleTemplates?.find((template) => template._id === newModuleId));
            setNewModuleId('');
        }
    }, [selectedTemplate]);

    useEffect(() => {
        // when there is no background image selected the increasedTopMargin value should be false
        if (!Object.keys(pageBackgroundImage).length) {
            setPageIncreasedTopMargin(false);
        }
    }, [pageBackgroundImage]);

    const titleJSX = (
        <>
            <span>Page Templates</span>
            {pageName && (
                <PageNameContainer>
                    <SVGInline src={icons.arrowRightIcon} />
                    <span>{pageName ?? '(title)'}</span>
                </PageNameContainer>
            )}
            {showUnsaved && <UnsavedSpan>&nbsp;&nbsp;[Unsaved]</UnsavedSpan>}
            &nbsp;&nbsp;
        </>
    );

    const renderAddModuleActions = () => {
        if (
            [PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent) ||
            (pageHasCollection && modules.length > allowedNumberOfModules)
        )
            return;
        return (
            <PageActionsWrapper>
                {isMobile ? (
                    <Button
                        type={'BLUE'}
                        label={'Create Module Template'}
                        style={AddModulestyle}
                        onClick={() => setOpenNewResourceDialog(true)}
                    />
                ) : (
                    <>
                        <Button
                            type={'BLUE'}
                            label={'Create Module Template'}
                            style={AddModulestyle}
                            onClick={() => setOpenNewModuleDialog(true)}
                        />
                        <Button
                            type={'BLUE'}
                            label={'Use Existing'}
                            style={AddModulestyle}
                            onClick={() => setOpenUseExistingDialog(true)}
                        />
                    </>
                )}
            </PageActionsWrapper>
        );
    };

    const buildTableColumns = () => {
        let columns = [];
        columns.push(
            <TableCell key={'ui_template'} style={TemplateHeaderStyle}>
                {'UI'}
            </TableCell>,
            <TableCell key={'name'}>{'Module Name'}</TableCell>,
            <TableCell key={'template'}>{'Content Source'}</TableCell>,
            <TableCell key={'lastModified'}>{'Last Modified'}</TableCell>
        );
        columns.unshift(<TableCell key={'first_cell'} />);
        return columns;
    };
    const buildTableRows = () => {
        const rows: JSX.Element[] = modules.map((module) => {
            const template = validTemplates?.find((t) => t.value === module.values?.template)?.key || '(Unknown)';
            const lastModified = generateDateStringForTables(module?.lastModified || 0);

            return (
                <DraggableTableRow
                    key={module._id}
                    id={`${module._id}`}
                    type={DRAGGABLE_TYPES.MODULE}
                    moveItem={moveModule}
                    findItem={findModule}
                    dragDisabled={pageHasCollection}
                >
                    <WidthTableCell {...ModulesTemplatesTableSizes.icon}>
                        <ContentTemplateLogo>
                            <SVGInline src={template ? TemplateIcons[template] : TemplateIcons.GALLERY} />
                            <div>{template}</div>
                        </ContentTemplateLogo>
                    </WidthTableCell>
                    <WidthTableCell {...ModulesTemplatesTableSizes.name}>
                        <NameWrapper onClick={() => navigate(PageRoutes.SUPERADMIN_MODULES, { state: { moduleId: module._id } })}>
                            <TruncatedText>{module.values.name}</TruncatedText>
                        </NameWrapper>
                    </WidthTableCell>
                    <WidthTableCell {...ModulesTemplatesTableSizes.placed}>{calculateContentSourceValueForTemplate(module)}</WidthTableCell>

                    <WidthTableCell {...ModulesTemplatesTableSizes.lastModified}>{lastModified}</WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...ModulesTemplatesTableSizes.actions}>
                        <ActionsTableCell
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                setModuleToEdit(module);
                                setOpenNewModuleDialog(true);
                            }}
                            onRemove={() => {
                                handleRemoveIconClick(module);
                            }}
                            tooltipTexts={{
                                edit: 'superadmin_module_templates_icon_edit',
                                delete: 'superadmin_module_templates_icon_remove_from_page'
                            }}
                            publishedStatus={module.publishStatus}
                        />
                    </WidthTableCell>
                </DraggableTableRow>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderPageOptions = () => {
        return (
            <PageOptionsContainer>
                <PageOptionsTitle>
                    Page Background {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'pages_edit_page_background_image')}
                </PageOptionsTitle>

                <ImageFields>
                    <DialogFileField
                        imageInfo={pageStyleImageInfo}
                        customAddIcon={icons.addIconWhite}
                        preview={pageBackgroundImage.bigScreen}
                        fieldKey={'Big Screen (1920x1080)'}
                        customLabel={applyBackgroundForAll ? 'All Screens' : 'Big Screen (1920x1080)'}
                        localFileCallBack={(file) => handleImageSelect(file, 'bigScreen')}
                        fileManagerCallback={(url: string) => handleImageSelect(url, 'bigScreen')}
                        openUrlCallback={(url: string) => handleImageSelect(url, 'bigScreen')}
                        alwaysShowLabel
                        withoutFileManager
                        accepts={acceptedExtensionsPageBackground}
                    />
                    {!applyBackgroundForAll && (
                        <>
                            <DialogFileField
                                imageInfo={pageStyleImageInfo}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage.pc}
                                fieldKey={'PC (1280x720)'}
                                customLabel={'PC (1280x720)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'pc')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'pc')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'pc')}
                                alwaysShowLabel
                                withoutFileManager
                                accepts={acceptedExtensionsPageBackground}
                            />
                            <DialogFileField
                                imageInfo={pageStyleImageInfo}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage.tablet}
                                fieldKey={'Tablet (960x540)'}
                                customLabel={'Tablet (960x540)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'tablet')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'tablet')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'tablet')}
                                alwaysShowLabel
                                withoutFileManager
                                accepts={acceptedExtensionsPageBackground}
                            />
                            <DialogFileField
                                imageInfo={pageStyleImageInfo}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage.mobile}
                                fieldKey={'Mobile (360x202)'}
                                customLabel={'Mobile (360x202)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'mobile')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'mobile')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'mobile')}
                                alwaysShowLabel
                                withoutFileManager
                                accepts={acceptedExtensionsPageBackground}
                            />
                        </>
                    )}
                </ImageFields>

                <ToggleButtonContainer>
                    <DialogToggleButton
                        checked={applyBackgroundForAll}
                        toggleCallback={() => {
                            setApplyBackgroundForAll(!applyBackgroundForAll);
                            setBackgroundImageFiles({});
                            setBackgroundImageUrls({});
                            setPageBackgroundImage({});
                            setInitialBackgroundImage({});
                        }}
                        text={'Apply for all screens'}
                        tooltipText={applyBackgroundForAll ? 'apply_for_all_on' : 'apply_for_all_off'}
                    />
                </ToggleButtonContainer>

                <DialogCheckbox
                    active={pageIncreasedTopMargin}
                    value={pageIncreasedTopMargin}
                    onClick={() => {
                        setPageIncreasedTopMargin(!pageIncreasedTopMargin);
                    }}
                    text={'Increased Top Margin'}
                    disabled={!Object.keys(pageBackgroundImage).length}
                    tooltipText={'style_and_branding_increased_margin'}
                />
            </PageOptionsContainer>
        );
    };

    const renderListView = () =>
        modules?.length ? (
            <>
                <GenericTable columns={buildTableColumns()} body={buildTableRows()} />
                {renderAddModuleActions()}
            </>
        ) : (
            renderAddModuleActions()
        );
    return (
        <ApplicationWrapper $loading={templateTypeLoading.page}>
            <MainContentWrapper noSidebar>
                <ScreenTitle
                    title={titleJSX}
                    withProfile
                    withAddButton={
                        ![PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent) &&
                        !(pageHasCollection && modules.length > allowedNumberOfModules)
                    }
                    backIcon
                    withoutSearch
                    addLabel={'Add Module Template'}
                    onAdd={() => setOpenNewResourceDialog(true)}
                    onBack={() => {
                        const locationToGo = PageRoutes.SUPERADMIN_PAGES;
                        navigate(locationToGo);
                    }}
                />

                <PageValues
                    onChange={handlePageValuesChange}
                    initialValues={{ name: pageName, pageStyle, intent: pageIntent, pageView: PAGE_VIEWS.LIST_VIEW }}
                    pageStyles={pageStylesOptions}
                    showWarning={showGalleryWarning}
                    showAllIntents={
                        modules.filter((elem) => elem._id !== 'search').length < 2 &&
                        //in case a page has a collection module, the user should not be able to change the intent to auto_collection
                        !modules.some((module) => module.values.moduleType === moduleTypes.COLLECTION)
                    }
                    hasGalleryOrPreview={
                        !!modules.find((elem) => [templates.GALLERY].includes(elem.values?.template) || elem.values.preview)
                    }
                    withoutTargets
                    onlyListView
                    onOptionsClick={() => setIsPageOptionsOpen(!isPageOptionsOpen)}
                    errors={{ name: nameError }}
                />
                {isPageOptionsOpen && renderPageOptions()}
                {templateTypeLoading.page ? <Loader /> : renderListView()}

                <PageActions
                    onSave={handleSaveClick}
                    disabled={{
                        save: !showUnsaved
                    }}
                    onCancel={() => {
                        navigate(PageRoutes.SUPERADMIN_PAGES);
                    }}
                />

                <CreateResourceDialog
                    title={'Module'}
                    open={openNewResourceDialog}
                    onClose={() => {
                        setOpenNewResourceDialog(false);
                    }}
                    historyUrl={''}
                    handleCreateNewResourceClick={() => {
                        setOpenNewModuleDialog(true);
                    }}
                    withSelectExisting
                    handleSelectExistingClick={() => {
                        setOpenUseExistingDialog(true);
                    }}
                    withoutSelectTemplate
                />

                <UseExistingDialog
                    open={openUseExistingDialog}
                    onClose={() => setOpenUseExistingDialog(false)}
                    existingItemType={EXISTING_ITEMS.TEMPLATE}
                    existingTemplateType={templateTypes.MODULE}
                    itemIdsToExclude={[...modules.map((module) => module._id)]}
                    onSave={(moduleId) => {
                        handleSaveClick(moduleId);
                    }}
                    moduleTypeToExclude={pageHasCollection || modules.length > 1 ? moduleTypes.COLLECTION : undefined}
                    moduleTemplatesToExclude={
                        pageIntent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(pageIntent) ? [templates.GALLERY] : undefined
                    }
                    excludePreview={!!pageIntent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(pageIntent)}
                    restrictedContentWorld={pageHasContentWorld}
                    isSuperAdminUI
                />

                <NewModule
                    module={moduleToEdit}
                    open={openNewModuleDialog}
                    onClose={() => {
                        setOpenNewModuleDialog(false);
                        setModuleToEdit(undefined);
                    }}
                    onSave={(template) => {
                        moduleToEdit ? updateTemplate(template) : createTemplate(template);
                        setOpenNewResourceDialog(false);
                    }}
                    onRelease={() => {
                        releaseTemplate();
                    }}
                    onRetract={() => retractTemplate()}
                    restrictedTemplates={
                        pageIntent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(pageIntent) ? [templates.GALLERY] : undefined
                    }
                    restrictedCollection={pageHasCollection || modules.length > 1}
                    restrictedContentWorld={pageHasContentWorld && !moduleToEdit?.values?.contentWorld}
                />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};
