import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import PreviewBackground from '../../../assets/images/cards/cardPreview.png';
import { CardTypeIndicator } from '../VisualEditor.css';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const PreviewSection = styled.div`
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 12px;
`;

export const PreviewToggle = styled.div`
    color: ${theme.palette.primary.main};
    ${fontSizesCSS.Caption1};
    path {
        fill: ${theme.palette.primary.main};
    }
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const PreviewContainer = styled.div`
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PreviewLaneWrapper = styled.div<{ $fullWidth?: boolean }>`
    height: auto;
    ${(props) => props.$fullWidth && 'min-height: 320px'};
    width: 100%;
    white-space: nowrap;
    display: inline-block;
    overflow: auto;

    ::-webkit-scrollbar {
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${theme.palette.background.hover};
        border-radius: 8px;
    }
`;

export const PreviewCard = styled.div<{ $image?: string; $fullWidth?: boolean; $singleAsset?: boolean }>`
    background: ${(props) =>
        props.$image
            ? `url("${props.$image}") ${props.$image.includes('static/media/notAvailableLogo') ? `, ${theme.palette.text.main}` : ''}`
            : `url(${PreviewBackground})`};
    position: relative;
    display: inline-block;
    background-size: cover;
    background-position: center;

    ${(props) =>
        props.$fullWidth
            ? css`
                  width: 482px;
                  height: 320px;
                  border-radius: 4px;

                  ${devices.tablet} {
                      width: 100%;
                  }
              `
            : css`
                  width: 128px;
                  height: 72px;
                  border-radius: 6px;
              `}

    ${(props) =>
        props.$singleAsset
            ? css`
                  margin: 16px 27px 0 27px;

                  ${devices.tablet} {
                      margin: 0;
                  }
              `
            : css`
                  &:not(:first-child) {
                      margin: 0 4px;
                  }
              `}

    ${CardTypeIndicator} {
        svg {
            width: 16px;
            height: 16px;
            path {
                fill: ${theme.palette.black.main};
            }
        }
    }
`;

export const PreviewCardOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.palette.background.hovered};
    border-radius: 6px;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
            fill: ${theme.palette.white.main};

    }
`;
