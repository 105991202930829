import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../../types/Project';

export interface ActiveItemState {
    activeTenantId: string | undefined;
    activeProjectId: string | undefined;
}

export const initialState: ActiveItemState = {
    activeTenantId: undefined,
    activeProjectId: undefined
};

const slice = createSlice({
    name: 'activeItem',
    initialState,
    reducers: {
        setActiveTenant(state: any, action: PayloadAction<any>) {
            state.activeTenantId = action.payload;
        },

        setActiveProject(state: any, action: PayloadAction<string>) {
            state.activeProjectId = action.payload;
        },
        unsetActiveTenant(state: any) {
            state.activeTenantId = undefined;
        },
        unsetActiveProject(state: any) {
            state.activeProjectId = undefined;
        }
    }
});

export default slice.reducer;

export const { setActiveProject, setActiveTenant, unsetActiveProject, unsetActiveTenant } = slice.actions;
