import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { ConfigurableKeys } from './ConfigurableSelect';

export type CustomizableValueDialogProps = {
    open: boolean;
    type: ConfigurableKeys;
    title: string;
    existingValue?: string | number;
    onClose: () => void;
    onSave: (value: string | number) => void;
};

export const CustomizableValueDialog: FC<CustomizableValueDialogProps> = ({ type, title, open, existingValue, onClose, onSave }) => {
    const [newValue, setNewValue] = useState<string>('');
    const [newValueError, setNewValueError] = useState<string>('');

    const isNumber = type === ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS;

    const validateNewValue = () => {
        const nameEror = validator({ required: true }, `${newValue}`);
        setNewValueError(nameEror);
        return !nameEror.length;
    };
    const handleSaveClick = () => {
        if (!validateNewValue()) return;
        onSave(isNumber ? parseInt(newValue) : newValue.trim());
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setNewValue('');
        setNewValueError('');
        onClose?.();
    };

    const addButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    useEffect(() => {
        if (!existingValue) return;
        setNewValue(`${existingValue}`);
    }, [existingValue]);

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={existingValue ? `Edit ${title}` : `Create ${title}`}
            actionButtons={[cancelButton, addButton]}
            onClose={handleCloseClick}
        >
            <DialogTextField
                value={`${newValue}`}
                onChange={(e: any) => {
                    if (isNumber && !e.target.value.match(/^([0-9]+)?(\.)?([0-9])?$/)) return;
                    setNewValue(e.target.value);
                }}
                error={newValueError}
                placeholder={'Name'}
                label={'Name'}
            />
        </GenericDialog>
    );
};
