import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../Dialog/GenericDialog';
import {
    BottomArrow,
    StrictTimeError,
    TimeBoxSection,
    TimeContainer,
    TimePickerBox,
    TimePickerContainer,
    TimePickerDialogStyle,
    TimePickeWithRangeDialogStyle,
    TimeSection,
    TimeTextSection,
    TopArrow
} from './TimePicker.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { generateTimeForMillis } from '../../../utils/fnDate';
import { CIRCLE_SLUGS } from '../HelpIcon/HelpIcon';

export enum Sections {
    STARTHOUR,
    STARTMIUTE,
    STARTPERIOD,
    ENDHOUR,
    ENDMINUTE,
    ENDPERIOD
}

const MAXHOUR = 12;
const MINHOUR = 1;

const MINMINUTE = 0;
const MAXMINUTE = 59;

export const ONE_DAY_MILIS = 86400000;
export const ONE_DAY_SECONDS = 86400;

export const textFieldStyle = {
    width: '226px',
    minHeight: '36px'
};

export type TimePickerProps = {
    open: boolean;
    sTime?: number;
    eTime?: number;
    onClose: () => void;
    saveTime: (arg: any) => void;
    inRange?: boolean;
    timeIncrement?: number; // in minutes
    strictTimeSelection?: boolean; // If this is true, time starts at current time and cannot be set to a past time
    selectedDate?: number; // used for strict time selection calculation
    customSaveButtonLabel?: string;
};

export const TimePicker: FC<TimePickerProps> = ({
    open,
    saveTime,
    onClose,
    sTime,
    eTime,
    inRange,
    timeIncrement,
    strictTimeSelection,
    selectedDate,
    customSaveButtonLabel
}) => {
    const [startHour, setStartHour] = useState<number>(12);
    const [startMinute, setStartMinute] = useState<number>(0);
    const [startPeriod, setStartPeriod] = useState<'AM' | 'PM'>('AM');

    const [endHour, setEndHour] = useState<number>(12);
    const [endMinute, setEndMinute] = useState<number>(0);
    const [endPeriod, setEndPeriod] = useState<'AM' | 'PM'>('PM');

    const getHour = (hour: number, direction: '-1' | '1') => {
        return direction === '-1' ? (hour <= MINHOUR ? MAXHOUR : hour - 1) : hour >= MAXHOUR ? MINHOUR : hour + 1;
    };

    const getMinute = (min: number, direction: '-1' | '1', timeIncrement: number = 1) => {
        return direction === '-1'
            ? min - timeIncrement < MINMINUTE
                ? MAXMINUTE + 1 - timeIncrement
                : min - timeIncrement
            : min + timeIncrement > MAXMINUTE
            ? MINMINUTE
            : min + timeIncrement;
    };

    const convertToMillis = (hour: number, minutes: number, period: 'AM' | 'PM') => {
        const hour24Format = period === 'PM' ? (hour < 12 ? (hour += 12) : hour) : hour === 12 ? (hour -= 12) : hour;
        return hour24Format * (1000 * 60 * 60) + minutes * (1000 * 60);
    };

    const resetState = () => {
        if (strictTimeSelection) {
            const now = new Date();
            const minute = now.getMinutes();
            const remainder = minute % (timeIncrement || 1);
            const nextMinuteInTheFuture = (timeIncrement || 1) - remainder + minute;
            const hourToShow = nextMinuteInTheFuture > MAXMINUTE ? now.getHours() + 1 : now.getHours();
            setStartHour(hourToShow > MAXHOUR ? hourToShow - MAXHOUR : hourToShow);
            setStartMinute(nextMinuteInTheFuture > MAXMINUTE ? MINMINUTE : nextMinuteInTheFuture);
            setStartPeriod(hourToShow > 12 ? 'PM' : 'AM');
        } else {
            setStartHour(12);
            setStartMinute(0);
            setStartPeriod('AM');
            setEndHour(12);
            setEndMinute(0);
            setEndPeriod('PM');
        }
    };

    const handleSave = () => {
        const startTime = convertToMillis(startHour, startMinute, startPeriod);
        let endTime = convertToMillis(endHour, endMinute, endPeriod);

        if (endTime < startTime) {
            endTime += ONE_DAY_MILIS;
        }

        inRange ? saveTime({ startTime, endTime }) : saveTime(startTime);
        onClose && onClose();
        resetState();
    };

    const isTimeInTheFuture = () => {
        if (!selectedDate) return true;
        const date = new Date(selectedDate * 1000);
        const adjustedStartHour = startHour === 12 ? 0 : startHour;
        date.setHours(startPeriod === 'AM' ? adjustedStartHour : adjustedStartHour + 12, startMinute, 0, 0);
        return date > new Date();
    };

    const saveButton: DialogButton = {
        label: customSaveButtonLabel || 'Add',
        type: 'BLUE',
        onClick: handleSave,
        disabled: strictTimeSelection && !isTimeInTheFuture()
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onClose
    };
    useEffect(() => {
        if (strictTimeSelection) {
            const now = new Date();
            const minute = now.getMinutes();
            const remainder = minute % (timeIncrement || 1);
            const nextMinuteInTheFuture = (timeIncrement || 1) - remainder + minute;
            const hourToShow = nextMinuteInTheFuture > MAXMINUTE ? now.getHours() + 1 : now.getHours();
            setStartHour(hourToShow > MAXHOUR ? hourToShow - MAXHOUR : hourToShow);
            setStartMinute(nextMinuteInTheFuture > MAXMINUTE ? MINMINUTE : nextMinuteInTheFuture);
            setStartPeriod(hourToShow > 12 ? 'PM' : 'AM');
        }
    }, []);
    useEffect(() => {
        if (sTime) {
            const { hours, minutes, period } = generateTimeForMillis(sTime);
            setStartHour(hours);
            setStartMinute(minutes);
            setStartPeriod(period);
        }

        if (eTime) {
            const { hours, minutes, period } = generateTimeForMillis(eTime);
            setEndHour(hours);
            setEndMinute(minutes);
            setEndPeriod(period);
        }
    }, [sTime, eTime]);

    const handleArrowClick = (section: Sections, direction: '1' | '-1' = '-1') => {
        switch (section) {
            case Sections.STARTHOUR:
                setStartHour(getHour(startHour, direction));
                break;
            case Sections.ENDHOUR:
                setEndHour(getHour(endHour, direction));
                break;
            case Sections.STARTMIUTE:
                setStartMinute(getMinute(startMinute, direction, timeIncrement));
                break;
            case Sections.ENDMINUTE:
                setEndMinute(getMinute(endMinute, direction, timeIncrement));
                break;
            case Sections.STARTPERIOD:
                setStartPeriod(startPeriod === 'AM' ? 'PM' : 'AM');
                break;
            case Sections.ENDPERIOD:
                setEndPeriod(endPeriod === 'AM' ? 'PM' : 'AM');
                break;
            default:
                break;
        }
    };

    const renderArrow = (section: Sections, top?: boolean) => {
        if (top) {
            return (
                <TopArrow onClick={() => handleArrowClick(section, '1')}>
                    <SVGInline src={icons.arrowUpIcon} />
                </TopArrow>
            );
        }

        return (
            <BottomArrow onClick={() => handleArrowClick(section, '-1')}>
                <SVGInline src={icons.arrowDownIcon} />
            </BottomArrow>
        );
    };

    if (!open) return null;
    return (
        <GenericDialog
            type={inRange ? DialogTypes.TimePickerRange : DialogTypes.TimePicker}
            title={'Time Selection'}
            onClose={onClose}
            actionButtons={[cancelButton, saveButton]}
            style={inRange ? TimePickeWithRangeDialogStyle : TimePickerDialogStyle}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.conditions }}
        >
            <TimePickerContainer>
                <TimeBoxSection>
                    <TimePickerBox>
                        <TimeContainer>
                            {renderArrow(Sections.STARTHOUR, true)}
                            <TimeSection> {startHour} </TimeSection>
                            {renderArrow(Sections.STARTHOUR)}
                        </TimeContainer>
                        <TimeContainer>
                            {renderArrow(Sections.STARTMIUTE, true)}
                            <TimeSection> {startMinute} </TimeSection>
                            {renderArrow(Sections.STARTMIUTE)}
                        </TimeContainer>
                        <TimeContainer>
                            {renderArrow(Sections.STARTPERIOD, true)}
                            <TimeSection> {startPeriod} </TimeSection>
                            {renderArrow(Sections.STARTPERIOD)}
                        </TimeContainer>
                    </TimePickerBox>

                    {inRange && (
                        <TimePickerBox>
                            <TimeContainer>
                                {renderArrow(Sections.ENDHOUR, true)}
                                <TimeSection> {endHour} </TimeSection>
                                {renderArrow(Sections.ENDHOUR)}
                            </TimeContainer>
                            <TimeContainer>
                                {renderArrow(Sections.ENDMINUTE, true)}
                                <TimeSection> {endMinute} </TimeSection>
                                {renderArrow(Sections.ENDMINUTE)}
                            </TimeContainer>
                            <TimeContainer>
                                {renderArrow(Sections.ENDPERIOD, true)}
                                <TimeSection> {endPeriod} </TimeSection>
                                {renderArrow(Sections.ENDPERIOD)}
                            </TimeContainer>
                        </TimePickerBox>
                    )}
                </TimeBoxSection>
                {inRange && (
                    <TimeTextSection>
                        <DialogTextField
                            value={`${startHour} ${startMinute} ${startPeriod}`}
                            onChange={() => {}}
                            style={textFieldStyle}
                            isDisabled
                            whiteWhileDisabled
                        />
                        <SVGInline src={icons.arrowRightFullIcon} />
                        <DialogTextField
                            value={`${endHour} ${endMinute} ${endPeriod}`}
                            onChange={() => {}}
                            style={textFieldStyle}
                            isDisabled
                            whiteWhileDisabled
                        />
                    </TimeTextSection>
                )}
                {strictTimeSelection && !isTimeInTheFuture() && <StrictTimeError>Time must be in the future!</StrictTimeError>}
            </TimePickerContainer>
        </GenericDialog>
    );
};
