import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { FilterType, ParsedFile } from '../../components/FileManager/FileManager';
import azureUploader from '../../utils/api/azureUploader';

export const fetchFiles = createAsyncThunk<
    { data: any; error: { message: string; code: string } | null },
    { prefix: string; filter?: FilterType; orderBy?: string; searchTerm?: string }
>('fileManager/fetchFiles', async ({ prefix, filter, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getFiles(prefix, filter, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { data: result.response as any, error: null };
});

export const fetchFile = createAsyncThunk<{ file: any; error: { message: string; code: string } | null }, string>(
    'fileManager/fetchFile',
    async (path, thunkApi) => {
        const result = await ConfigServiceAPI.getFile(path);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { file: result.response as any, error: null };
    }
);

export const fetchSystemIcons = createAsyncThunk<{ data: any; error: { message: string; code: string } | null }>(
    'fileManager/fetchSystemIcons',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getSystemIcons();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { data: result.response as any, error: null };
    }
);

export const fetchTemplateIcons = createAsyncThunk<{ data: any; error: { message: string; code: string } | null }>(
    'fileManager/fetchTemaplteIcons',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getTemplateIcons();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { data: result.response as any, error: null };
    }
);

export const fetchCustomIcons = createAsyncThunk<{ data: any; error: { message: string; code: string } | null }, string>(
    'fileManager/fetchCustomIcons',
    async (projectId, thunkApi) => {
        const result = await ConfigServiceAPI.getCustomIcons(projectId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { data: result.response as any, error: null };
    }
);

export const uploadFilesSync = createAsyncThunk<
    { urls: string[]; error: { message: string; code: string } | null },
    { files: File[]; prefix: string; overwrite?: boolean }
>('fileManager/uploadFileBlocking', async ({ files, prefix, overwrite }, thunkApi) => {
    const result = await azureUploader.uploadFilesSync(files, prefix, overwrite);
    const urls = result.map((res) => res.url).filter((url) => url);
    //show the first error if there's one
    const errorResult = result.find((res) => res.error || !res.success);

    if (errorResult) {
        return thunkApi.rejectWithValue(errorResult);
    }
    return { urls: urls as string[], error: null };
});

export const uploadFileViaClient = createAsyncThunk<
    { data: { success: boolean; placeholderName?: string }; error: { message: string; code: string } | null },
    {
        file: File;
        prefix: string;
        uploadProgressCallback?: (data: { progress: number; name: string }) => void;
        uploadSuccessCallback?: (name: string) => void;
        uploadErrorCallback?: () => void;
        overwrite?: boolean;
    }
>(
    'fileManager/uploadFileViaClient',
    async ({ file, prefix, uploadProgressCallback, uploadSuccessCallback, uploadErrorCallback, overwrite }, thunkApi) => {
        const _uploadSuccessCallback = (name: string) => {
            //on success, if overwrite is true, always purge imgix
            if (overwrite) {
                ConfigServiceAPI.purgeImgixPath(prefix ? `${prefix}/${file.name}` : file.name).then(() => {
                    uploadSuccessCallback?.(name);
                });
            } else uploadSuccessCallback?.(name);
        };
        const result = await azureUploader.uploadFile(
            file,
            prefix,
            uploadProgressCallback,
            _uploadSuccessCallback,
            uploadErrorCallback,
            overwrite
        );
        if (result.error || !result.success) {
            return thunkApi.rejectWithValue(result);
        }
        return { data: { success: result.success }, error: null };
    }
);

export const updateFile = createAsyncThunk<
    { data: { success: boolean; placeholderName?: string }; error: { message: string; code: string } | null },
    {
        originalPath: string;
        newPath: string;
        overwrite?: boolean;
        uploadProgressCallback?: (data: { progress: number; name: string }) => void;
        uploadSuccessCallback?: (name: string) => void;
        uploadErrorCallback?: () => void;
    }
>(
    'fileManager/updateFile',
    async ({ newPath, originalPath, overwrite, uploadProgressCallback, uploadSuccessCallback, uploadErrorCallback }, thunkApi) => {
        const _uploadSuccessCallback = (name: string) => {
            //on success alywas purge and delete the old file
            ConfigServiceAPI.purgeImgixPath(newPath);
            ConfigServiceAPI.deleteFile(originalPath).then(() => {
                uploadSuccessCallback?.(name);
            });
        };
        const result = await azureUploader.editFile(
            newPath,
            originalPath,
            overwrite,
            uploadProgressCallback,
            _uploadSuccessCallback,
            uploadErrorCallback
        );
        if (result.error || !result.success) {
            return thunkApi.rejectWithValue(result);
        }
        const paths = newPath.split('/');
        paths.pop();
        const prefix = paths.join('/');
        return { data: { success: result.success }, error: null };
    }
);

export const encodeVideo = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { prefix: string; fileName: string }
>('fileManager/encodeVideo', async ({ prefix, fileName }, thunkApi) => {
    const result = await ConfigServiceAPI.encodeVideo(prefix, fileName);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteFile = createAsyncThunk<{ message: string; error: { message: string; code: string } | null }, string>(
    'fileManager/deleteFile',
    async (file, thunkApi) => {
        const result = await ConfigServiceAPI.deleteFile(file);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { message: result.response as any, error: null };
    }
);

export const uploadFolder = createAsyncThunk<{ message: string; error: { message: string; code: string } | null }, any>(
    'fileManager/uploadFolder',
    async (folder: { prefix: string; folderName: string }, thunkApi) => {
        const result = await ConfigServiceAPI.createFolder(folder);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { message: result.response as any, error: null };
    }
);

export const deleteFolder = createAsyncThunk<{ message: string; error: { message: string; code: string } | null }, any>(
    'fileManager/deleteFolder',
    async (folder: { prefix: string; folderName: string }, thunkApi) => {
        const result = await ConfigServiceAPI.deleteFolder(folder);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { message: result.response as any, error: null };
    }
);

export interface FilesState {
    files: ParsedFile[];
    systemIcons: any[];
    templateIcons: any[];
    loading: boolean;
    encodeVideoLoading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
    customIcons?: {
        [projectId: string]: any[];
    };
    uploadFilesProgress: { [name: string]: number }; // used to keep track of uploading progress in slice because of posibility to navigate away
    editingFile?: string; // used for knowing if a file is editing so that we can hide it until it's done
    overwritingFile?: string; // used for knowing if a file is being overwritten so that we can hide it until it's done
    editingFileToRestoreAfterCancel?: { file: any; index: number };
}

const initialState: FilesState = {
    files: [],
    systemIcons: [],
    templateIcons: [],
    customIcons: {},
    error: null,
    loading: false,
    encodeVideoLoading: false,
    uploadFilesProgress: {}
};

const areFilesSameFolder = (fileName1: string, filename2: string) => {
    const folderOfFile1 = fileName1.substring(0, fileName1.lastIndexOf('/'));
    const folderOfFile2 = filename2.substring(0, filename2.lastIndexOf('/'));

    return folderOfFile1 === folderOfFile2;
};

const slice = createSlice({
    name: 'fileManager',
    initialState,
    reducers: {
        unsetFilesError(state) {
            state.error = null;
        },
        unsetEditingFile(state) {
            state.editingFile = undefined;
            state.editingFileToRestoreAfterCancel = undefined;
        },
        restoreOldFileAfterCancel(state) {
            if (!state.editingFileToRestoreAfterCancel) return;
            const { file, index } = state.editingFileToRestoreAfterCancel;
            const newFiles = [...current(state).files];
            const isFolderEmpty = !newFiles?.length;

            const didCancelHappenInCurrentFolder =
                isFolderEmpty || areFilesSameFolder(newFiles[0].originalPath || newFiles[0]?.path || '', file.originalPath);

            if (didCancelHappenInCurrentFolder) {
                newFiles.splice(index, 0, file);
                state.files = [...newFiles];
            }

            state.editingFileToRestoreAfterCancel = undefined;
        },
        unsetUploadingFiles(state, action: PayloadAction<string | undefined>) {
            const newFiles = [...current(state).files];
            state.files = [
                ...newFiles.filter((file) => {
                    if (!file.isUploading) return true;
                    if (action.payload) {
                        return file.name === action.payload;
                    }
                    return false;
                })
            ];
            state.overwritingFile = undefined;
            state.editingFile = undefined;
            state.uploadFilesProgress = {};
            state.editingFileToRestoreAfterCancel = undefined;
        },
        unsetOverwritingFile(state) {
            const files = [...current(state).files];
            const index = files.findIndex((f) => f.originalPath === state.overwritingFile);
            if (index > -1) {
                files.splice(index, 1);
                state.files = [...files];
            }
            state.overwritingFile = undefined;
        },
        setUploadFilesProgress(state, action: PayloadAction<{ key: string; value?: number }>) {
            const newValue = { ...current(state).uploadFilesProgress };
            if (action.payload.value === undefined) {
                delete newValue[action.payload.key];
            } else {
                newValue[action.payload.key] = action.payload.value;
            }
            state.uploadFilesProgress = newValue;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFiles.fulfilled, (state, action: any) => {
                const newFiles = [...action.payload.data];
                // if there are files that are uploading, add them to the files state
                Object.keys(current(state).uploadFilesProgress).forEach((key) => {
                    const file = {
                        name: key.split('/').pop() || '',
                        originalPath: key,
                        size: 0,
                        isUploading: true
                    };
                    const isFolderEmpty = !newFiles?.length;

                    const isUploadingInCurrentFolder =
                        isFolderEmpty || areFilesSameFolder(file.originalPath || '', newFiles[0]?.originalPath || newFiles[0]?.path || '');
                    if (isUploadingInCurrentFolder) {
                        newFiles.push(file);
                    }
                });
                state.files = [...newFiles];
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchFiles.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.files = [];
                state.loading = false;
            })
            .addCase(fetchFiles.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchFile.fulfilled, (state, action: any) => {
                // when we fetch a file after upload/edit, we want to swap it with its placeholder
                // and in case of edit, replace the old file with the new one
                const file = action.payload.file;

                const newFiles = [...current(state).files];
                const isFolderEmpty = !newFiles?.length;

                const fetchedFileIsPartOfCurrentFolder =
                    isFolderEmpty || areFilesSameFolder(file.originalPath, newFiles[0]?.originalPath || newFiles[0]?.path || '');
                if (state.editingFile) state.editingFile = undefined;

                if (!fetchedFileIsPartOfCurrentFolder) return;
                const placeholderIndex = newFiles.findIndex((f) => f.originalPath === file.originalPath && f.isUploading);
                const oldFileIndex = newFiles.findIndex((f) => f.originalPath === file.originalPath && !f.isUploading);

                if (placeholderIndex > -1) {
                    newFiles.splice(placeholderIndex, 1, file);
                } else {
                    newFiles.push(file);
                }
                if (oldFileIndex > -1) {
                    newFiles.splice(oldFileIndex, 1);
                }
                state.files = [...newFiles];
            })
            .addCase(fetchFile.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
            })
            .addCase(fetchSystemIcons.fulfilled, (state, action: any) => {
                state.systemIcons = action.payload.data;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSystemIcons.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.systemIcons = [];
                state.loading = false;
            })
            .addCase(fetchSystemIcons.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchTemplateIcons.fulfilled, (state, action: any) => {
                state.templateIcons = action.payload.data;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchTemplateIcons.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.templateIcons = [];
                state.loading = false;
            })
            .addCase(fetchTemplateIcons.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchCustomIcons.fulfilled, (state, action: any) => {
                const projId = action?.meta?.arg || '';
                state.customIcons = {
                    ...(state.customIcons || {}),
                    [projId]: action.payload.data
                };
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchCustomIcons.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(fetchCustomIcons.pending, (state, _action) => {
                state.loading = true;
            })

            .addCase(uploadFilesSync.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(uploadFilesSync.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(uploadFilesSync.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })

            .addCase(uploadFileViaClient.fulfilled, (state, action: any) => {
                // when an upload is succesfully started, we add the placeholder file to the state quietly, without a reload
                const newFile: ParsedFile = {
                    name: action.meta.arg.file.name,
                    originalPath: action.meta.arg.prefix + '/' + action.meta.arg.file.name,
                    size: 0,
                    lastModified: Date.now(),
                    isUploading: true
                };
                const newFiles = [...current(state).files];
                const isFolderEmpty = !newFiles?.length;

                const isUploadingInCurrentFolder =
                    isFolderEmpty || areFilesSameFolder(newFile.originalPath || '', newFiles[0]?.originalPath || newFiles[0]?.path || '');
                if (action.meta.arg.overwrite) {
                    const oldFile = action.meta.arg.prefix + '/' + action.meta.arg.file.name;
                    state.overwritingFile = oldFile;
                }
                if (isUploadingInCurrentFolder) {
                    newFiles.push(newFile);
                    state.files = [...newFiles];
                }

                state.error = null; // unset error here since we won't reload imediatelly
            })
            .addCase(uploadFileViaClient.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                if (state.overwritingFile) state.overwritingFile = undefined;
                state.loading = false;
            })
            .addCase(uploadFolder.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(uploadFolder.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(uploadFolder.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(deleteFolder.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteFolder.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteFolder.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateFile.fulfilled, (state, action: any) => {
                // when an upload is succesfully started via edit, we add the placeholder file to the state quietly, and we take out the old file from the state
                const paths = action.meta.arg.newPath.split('/');
                const name = paths.pop();
                const newFile: ParsedFile = {
                    name: name,
                    originalPath: action.meta.arg.newPath,
                    size: 0,
                    lastModified: Date.now(),
                    isUploading: true
                };
                const newFiles = [...current(state).files];

                const isFolderEmpty = !newFiles?.length;
                const isUploadingInCurrentFolder =
                    isFolderEmpty || areFilesSameFolder(newFile.originalPath || '', newFiles[0]?.originalPath || newFiles[0]?.path || '');

                if (action.meta.arg.overwrite) {
                    state.overwritingFile = action.meta.arg.newPath;
                }
                const oldIndex = newFiles.findIndex((f) => f.originalPath === action.meta.arg.originalPath);
                if (oldIndex > -1) {
                    // if we are moving(not uploading in the current folder) the file, we do not add the placeholderFile in the state, only remove the file that is being moved
                    const oldFile = newFiles.splice(oldIndex, 1)[0];
                    if (isUploadingInCurrentFolder) {
                        newFiles.splice(oldIndex, 0, newFile);
                    }
                    state.editingFileToRestoreAfterCancel = { file: oldFile, index: oldIndex };
                } else {
                    isUploadingInCurrentFolder && newFiles.push(newFile);
                }
                state.files = [...newFiles];
                state.editingFile = action.meta.arg.originalPath;

                if (!isUploadingInCurrentFolder) {
                    // if we are moving the file, add it to the uploadFileProgress, so when the user navigates into the new folder,
                    // he can already see the progress there (this can happen when the user moves a file, and enters the folder and the upload starts after that)
                    state.uploadFilesProgress = { [action.meta.arg.newPath]: 0 };
                }
                state.error = null; // unset error here since we won't reload imediatelly
            })
            .addCase(updateFile.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                if (state.overwritingFile) state.overwritingFile = undefined;
                state.editingFile = undefined;
                state.editingFileToRestoreAfterCancel = undefined;
            })
            .addCase(deleteFile.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteFile.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteFile.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(encodeVideo.pending, (state, _action) => {
                state.encodeVideoLoading = true;
            })
            .addCase(encodeVideo.fulfilled, (state, _action) => {
                state.encodeVideoLoading = false;
            })
            .addCase(encodeVideo.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.encodeVideoLoading = false;
            });
    }
});

export const {
    unsetFilesError,
    setUploadFilesProgress,
    unsetEditingFile,
    unsetOverwritingFile,
    unsetUploadingFiles,
    restoreOldFileAfterCancel
} = slice.actions;
export default slice.reducer;
