import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogFileField,
    DialogTextField,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { generateKeyFromName } from '../../../utils/fnGenerator';
import { Tenant } from '../../../types/Tenant';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchUsers, UsersState } from '../../../redux/slices/usersSlice';
import { User } from '../../../types/User';
import _ from 'lodash';
import { API_ERROR_CODES, getImgixUrl, USERROLES } from '../../../utils/Globals';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { validator } from '../../../utils/fnValidator';
import { UserAvatar, UserOptionContainer, UserOptionName } from '../../Projects/Projects.css';
import icons from '../../../assets/images/icons';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL } from '../../../utils/fnUrl';

export type CreateTenantProps = {
    open: boolean;
    onSave: (value: Tenant) => void;
    onClose: () => void;
    tenant?: Tenant | null;
};

const CreateTenant: FC<CreateTenantProps> = ({ open, onSave, onClose, tenant }) => {
    const dispatch = useDispatch();
    const { users: storeUsers, error, loading }: UsersState = useAppSelector((state) => state.users);
    const { error: imageError, loading: imageLoading }: FilesState = useAppSelector((state) => state.files);

    const [logo, setLogo] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(open);
    const [tenantName, setTenantName] = useState('');
    const [tenantKey, setTenantKey] = useState('');
    const [tenantAdmins, setTenantAdmins] = useState<any>([]);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<{ name?: string }>({});
    const [adminOptions, setAdminOptions] = useState<any>([]);
    const [deleteLogo, setDeleteLogo] = useState(false);

    const title = tenant ? 'Edit Tenant' : 'Create Tenant';

    useEffect(() => {
        const loadUsers = async () => {
            return await dispatch(fetchUsers({})).unwrap();
        };
        if (open) {
            loadUsers().then((response) => {
                if (response.error?.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) {
                    setIsOpen(true);
                }
            });
        } else {
            setIsOpen(false);
        }
        setDeleteLogo(false);
    }, [open]);

    useEffect(() => {
        if (tenant) {
            setLogo(tenant.logo);
            setTenantName(tenant.name);
            setTenantKey(tenant.key);
            setTenantAdmins(tenant.tenantAdmins?.map((admin: User) => ({ value: admin._id, label: admin.fullName })));
        }
    }, [tenant]);

    useEffect(() => {
        if (loading || error) return;
        if (storeUsers) {
            const options = storeUsers
                .filter((user) => {
                    // we filter out superAdmins from the options
                    return user.role?.name !== USERROLES.SUPER_ADMIN;
                })
                .map((user) => {
                    return {
                        value: user._id,
                        label: (
                            <UserOptionContainer>
                                <UserAvatar background={user?.icon || icons.userIcon} />
                                <UserOptionName>{user.fullName}</UserOptionName>
                            </UserOptionContainer>
                        ),
                        valueForSearch: user.fullName
                    };
                });
            setAdminOptions(options);
        }
    }, [loading]);

    useEffect(() => {
        if (tenant?.key) return;
        setTenantKey(generateKeyFromName(tenantName).toUpperCase());
    }, [tenantName]);

    const clearErrors = (key: string) => {
        let newErrors = { ...errors };
        newErrors = _.omit(newErrors, key);
        setErrors(newErrors);
    };

    const validateTenant = () => {
        let newErrors = { ...errors };

        newErrors.name = validator({ required: true, minLength: 4 }, tenantName);
        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const handleSaveClick = async () => {
        if (!onSave) return;
        if (!validateTenant()) return;
        const tenantAdminIds = tenantAdmins.map((admin: any) => admin.value);
        const newTenant = {
            _id: tenant?._id || '',
            name: tenantName,
            key: tenantKey,
            tenantAdminIds: tenantAdminIds,
            logo: logo ? encodeURIComponent(logo) : '',
            lastModified: Math.floor(new Date().getTime() / 1000)
        };

        if (logoFile) {
            const urls = await createFile(logoFile);
            if (urls?.length) {
                const fileName = extractFileNameFromAzureURL(urls[0]);
                newTenant.logo = encodeURIComponent(getImgixUrl(fileName, undefined, true));
            }
        } else if (deleteLogo) {
            newTenant.logo = '';
        }

        onSave(newTenant);
        setLogo(null);
        setTenantName('');
        setTenantKey('');
        setTenantAdmins([]);
        setErrors({});
    };
    const handleCloseClick = () => {
        onClose();
        setLogo(null);
        setTenantName('');
        setTenantKey('');
        setTenantAdmins([]);
        setErrors({});
    };

    const createFile = async (file: File) => {
        const newFile = new File([file], `${file.name}-${tenant?._id}`, { type: file.type });
        const prefix = 'tenant_logos';
        try {
            const response = await dispatch(uploadFilesSync({ files: [newFile], prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        loading: imageLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    // this is outside the main return because we do not open the dialog if we have error
    // so isOpen will be false
    if (error) {
        return <BackendErrorDialog error={error} />;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <GenericDialog type={DialogTypes.Form} title={`${title}`} onClose={handleCloseClick} actionButtons={[cancelButton, saveButton]}>
            <DialogFileField
                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                fieldKey="Tenant logo (128x128px)"
                localFileCallBack={(file) => {
                    setLogoFile(file);
                    setDeleteLogo(file === null);
                }}
                preview={tenant?.logo}
                error={imageError?.message || undefined}
                localFileOnly
                withoutPreview
            />
            <DialogTextField
                label={'Tenant name'}
                value={tenantName}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    clearErrors('name');
                    setTenantName(evt.target.value);
                }}
                placeholder={'Add tenant name'}
                error={errors.name}
            />
            <DialogTextField
                label={'Key'}
                value={tenantKey}
                placeholder={'(auto-filled)'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.value.length > 3) return;
                    setTenantKey(evt.target.value.toUpperCase());
                }}
                isDisabled={!!tenant?.key}
            />
            <DialogDropdownMultiple
                onChange={(newVal: { value: string; label: any }[]) => {
                    setTenantAdmins(newVal || []);
                }}
                placeholder={'Add tenant admin'}
                options={adminOptions}
                value={tenantAdmins}
            />
        </GenericDialog>
    );
};

export default CreateTenant;
