import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { DisplayConditionsState } from '../../../redux/slices/displayConditionsSlice';
import { fetchPageIntents, pagesState } from '../../../redux/slices/pagesSlice';
import { fetchPageStyles, PageStylesState } from '../../../redux/slices/pageStylesSlice';
import { Page } from '../../../types/Page';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import GenericDialog, { DialogButton, DialogDropdownSingle, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';

type NewPageDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: Page) => void;
};

type pageValues = {
    name?: string;
    pageStyle?: string;
    targets?: string[];
    abTestingGroupIds?: string[];
    intent?: string;
};

export const NewPageDialog: FC<NewPageDialogProps> = ({ open, onClose, onSave }) => {
    const [pageValues, setPageValues] = useState<any>({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pageStyleOptions, setPageStyleOptions] = useState<any[]>([]);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { pageStyles, loading: stylesLoading }: PageStylesState = useAppSelector((state) => state.pageStyles);
    const { intents, intentsLoading }: pagesState = useAppSelector((state) => state.pages);

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
        setPageValues({});
    };

    const handleSaveClick = async () => {
        const newPage: Page = {
            _id: '',
            name: pageValues.name || '',
            pageStyleId: pageValues.pageStyle || '',
            intent: pageValues.intent || '',
            conditionIds: pageValues.targets || [],
            abTestingGroupIds: pageValues.abTestingGroupIds || [],
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            options: {}
        };
        onSave(newPage);
        handleClose();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        onClick: handleSaveClick,
        type: 'BLUE',
        disabled: conditionsLoading
    };
    const closeButton: DialogButton = {
        label: 'Cancel',
        onClick: handleClose,
        type: 'DEFAULT'
    };

    const loadPageIntents = async () => {
        return await dispatch(fetchPageIntents({ projectId: activeProjectId })).unwrap();
    };

    const loadPageStyles = async () => {
        return await dispatch(fetchPageStyles({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    const handleChange = (key: keyof pageValues, value: any) => {
        let newValue: any = null;
        switch (key) {
            case 'intent':
            case 'pageStyle':
                newValue = value.value;
                break;
            case 'abTestingGroupIds':
            case 'targets':
                newValue = value;
                break;
            case 'name':
                newValue = `${value}`;
                break;

            default:
                break;
        }
        const newValues: pageValues = {
            ...pageValues,
            [key]: newValue
        };
        setPageValues(newValues);
    };

    useEffect(() => {
        if (!pageStyles.length) return;
        setPageStyleOptions(
            pageStyles?.map((style) => {
                return {
                    label: style.name || EMPTY_WORD_STRING,
                    value: style._id
                };
            })
        );

        setPageValues({ ...pageValues, pageStyle: pageStyles.find((s) => s.isDefault)?._id || '' });
    }, [pageStyles]);

    useEffect(() => {
        if (!intents.length) return;
        setPageValues({ pageValues, intent: intents[0].value });
    }, [intents]);

    useEffect(() => {
        if (open) {
            loadPageStyles();
            loadPageIntents();
        }

        setIsOpen(open);
    }, [open]);

    if (!isOpen) return null;

    return (
        <GenericDialog
            title={'Create Page'}
            type={DialogTypes.Form}
            actionButtons={[closeButton, saveButton]}
            onClose={handleClose}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.pages }}
        >
            <DialogTextField
                value={pageValues.name || ''}
                label="Page Name"
                placeholder="Optional"
                onChange={(evt: any) => handleChange('name', evt.target.value)}
                withTranslations
            />
            <DialogDropdownSingle
                options={intents}
                value={pageValues.intent ? intents.find((intent) => intent.value === pageValues.intent) : ''}
                placeholder="Page Intent"
                onChange={(val: any) => handleChange('intent', val)}
                isDisabled={intentsLoading}
            />
            <DialogDropdownSingle
                options={pageStyleOptions}
                value={pageValues.pageStyle ? pageStyleOptions.find((opt) => opt.value === pageValues.pageStyle) : ''}
                placeholder="Page Style"
                onChange={(val: any) => handleChange('pageStyle', val)}
                isDisabled={stylesLoading}
            />
            <TargetConditionsSelect
                targets={pageValues.targets || []}
                onChange={(val) => handleChange('targets', val)}
                hideCreateNew
                inDialog
            />
            <ABTestingGroupSelect
                inDialog
                selectedTargetConditions={pageValues.targets || []}
                selectedGroups={pageValues.abTestingGroupIds || []}
                onChange={(val) => handleChange('abTestingGroupIds', val)}
            />
        </GenericDialog>
    );
};
