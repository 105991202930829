import React, { FC, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

import { TruncatedText } from '../../../style/styled-components/reusable.css';
import GenericDialog, { DialogButton, DialogTypes } from './GenericDialog';
import { UserAvatar } from '../../Projects/Projects.css';
import {
    ActivityDialogObjectName,
    ActivityFieldStatus,
    ActivityDialogInfo,
    ActivitiesWrapper,
    ActivityContainer,
    ActivityFieldKey,
    ActivityDetails,
    ObjectTypeIcon,
    ActivityField,
    ActivityDate,
    ActivityInfo,
    ActivityType,
    ArrowIcon
} from './ObjectActivityDialog.css';

import { formatObjectActivityKey, RenamedKeysType } from '../../../utils/fnString';
import { ObjectActivity, ObjectType } from '../../../types/Object';
import { getIconByObjectType } from '../../../utils/Globals';
import { getDateAndTime } from '../../../utils/fnDate';
import { useAppSelector } from '../../../hooks/redux';
import { CIRCLE_SLUGS } from '../HelpIcon/HelpIcon';
import icons from '../../../assets/images/icons';
import SVGInline from 'react-inlinesvg';
import useTranslation from '../../../hooks/useTranslation';

export type ObjectActivityDialogProps = {
    open: boolean;
    objectTitle: string;
    onClose: () => void;
    objectType: ObjectType;
};

const ObjectActivityDialog: FC<ObjectActivityDialogProps> = ({ open, objectType, objectTitle, onClose }) => {
    const { objectActivity, loading } = useAppSelector((state) => state.objectActivity);
    const { translate } = useTranslation();

    const [showActivityDetails, setShowActivityDetails] = useState(-1);

    const handleArrowClick = (index: number) => {
        setShowActivityDetails((prevOpen) => (prevOpen === index ? -1 : index));
    };

    const handleOnClose = () => {
        setShowActivityDetails(-1);
        onClose();
    };

    const closeButton: DialogButton = {
        type: 'BLUE',
        label: 'Close',
        onClick: handleOnClose
    };

    if (!open || loading) return null;

    return (
        <GenericDialog
            onClose={handleOnClose}
            type={DialogTypes.Form}
            title={'Activity Log'}
            actionButtons={[closeButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS[objectType as keyof typeof CIRCLE_SLUGS] }}
        >
            <ActivityDialogObjectName>{objectTitle}</ActivityDialogObjectName>
            <ActivityDialogInfo>Last 7 Days</ActivityDialogInfo>

            <ActivitiesWrapper>
                {!!objectActivity.length ? (
                    (objectActivity as ObjectActivity[]).map((activity, index) => (
                        <ActivityContainer key={activity._id}>
                            <ActivityInfo>
                                <ActivityType>{_.capitalize(activity.type)}</ActivityType>
                                <Tooltip title={activity.createdByUser.name} placement="top">
                                    <UserAvatar background={activity.createdByUser.icon || icons.userIcon} />
                                </Tooltip>
                                <ActivityDate>{getDateAndTime(activity.createdAt)}</ActivityDate>
                                {activity.type === 'updated' && (
                                    <ArrowIcon>
                                        <SVGInline
                                            src={showActivityDetails === index ? icons.arrowUpIcon : icons.arrowDownIcon}
                                            onClick={() => handleArrowClick(index)}
                                        />
                                    </ArrowIcon>
                                )}
                            </ActivityInfo>

                            {showActivityDetails === index && (
                                <ActivityDetails>
                                    {activity.objectType === objectType ? (
                                        activity.updatedKeys?.map((key) => (
                                            <ActivityField key={key}>
                                                <ActivityFieldKey>
                                                    {formatObjectActivityKey(activity.objectType as keyof RenamedKeysType, key) || ''}
                                                </ActivityFieldKey>
                                                <ActivityFieldStatus>Updated</ActivityFieldStatus>
                                            </ActivityField>
                                        ))
                                    ) : (
                                        <ActivityField>
                                            <ActivityFieldKey>
                                                <ObjectTypeIcon>{getIconByObjectType(activity.objectType as ObjectType)}</ObjectTypeIcon>
                                                <TruncatedText>{translate(activity.objectName)}</TruncatedText>
                                            </ActivityFieldKey>
                                            <ActivityFieldStatus>Updated</ActivityFieldStatus>
                                        </ActivityField>
                                    )}
                                </ActivityDetails>
                            )}
                        </ActivityContainer>
                    ))
                ) : (
                    <ActivityDialogInfo>No activity in the last 7 days</ActivityDialogInfo>
                )}
            </ActivitiesWrapper>
        </GenericDialog>
    );
};

export default ObjectActivityDialog;
