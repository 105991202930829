import { FC, useState } from 'react';
import GenericDialog, { DialogRadioButton, DialogTypes } from './GenericDialog';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import {
    BoldedTitle,
    RemoveObjectIcon,
    RemoveObjectMainSection,
    RemoveObjectSelectSection,
    RemoveObjectSubtitle,
    RemoveObjectSubtitleWrapper
} from './RemoveObjectDialog.css';
import _ from 'lodash';

export enum RemovableObjects {
    ITEM = 'item',
    MODULE = 'module',
    PAGE = 'page'
}

enum removeTypes {
    UNLINK = 'unlink',
    DELETE = 'delete',
    DELETE_WITH_CHILDREN = 'delete_with_children'
}

export type RemoveObjectDialogProps = {
    objectType: RemovableObjects;
    onDelete: (withChildren?: boolean) => void;
    objectName?: string;
    parentName?: string;
    label?: string;
    withUnlink?: boolean;
    isList?: boolean;
    onUnlink?: () => void;
};

export const RemoveObjectDialog: FC<RemoveObjectDialogProps> = ({
    objectType,
    objectName,
    parentName,
    label,
    withUnlink,
    isList,
    onDelete,
    onUnlink
}) => {
    const [selectedRemoveType, setSelectedRemoveType] = useState<removeTypes>(!withUnlink ? removeTypes.DELETE : removeTypes.UNLINK);

    const childDescription = objectType === RemovableObjects.MODULE ? 'items' : 'objects';
    const subtitle = withUnlink ? (
        <>
            Before removing <BoldedTitle>{objectName}</BoldedTitle> from <BoldedTitle>{parentName}</BoldedTitle>, please select whether to
            keep the {label || objectType} in the system or delete it permanently. Keep in mind, this action cannot be undone.
        </>
    ) : isList ? (
        <>
            Before deleting the selected {objectType}s, please select wheter to delete only the {objectType}s and keep their{' '}
            {childDescription} in the system, or delete both the {objectType}s and all their {childDescription} . Keep in mind, this action
            cannot be undone.
        </>
    ) : (
        <>
            Before deleting <BoldedTitle>{objectName}</BoldedTitle>, please select wheter to delete only the {objectType} and keep its{' '}
            {childDescription} in the system, or delete both the {objectType} and all its {childDescription}. Keep in mind, this action
            cannot be undone.
        </>
    );

    const removeOptions = {
        [RemovableObjects.ITEM]: {
            [removeTypes.UNLINK]: (
                <>
                    Keep <BoldedTitle>{objectName}</BoldedTitle> in &quot;Lists & Items&quot;
                </>
            ),
            [removeTypes.DELETE]: 'Delete permanently'
        },
        [RemovableObjects.MODULE]: withUnlink
            ? {
                  [removeTypes.UNLINK]: (
                      <>
                          Keep <BoldedTitle>{objectName}</BoldedTitle> in &quot;Modules&quot;
                      </>
                  ),
                  [removeTypes.DELETE]: isList ? 'Delete Modules Only' : 'Delete permanently',
                  [removeTypes.DELETE_WITH_CHILDREN]: isList
                      ? 'Delete Modules along with all their items'
                      : 'Delete permanently, along with all items'
              }
            : {
                  [removeTypes.DELETE]: isList ? 'Delete Modules Only' : 'Delete permanently',
                  [removeTypes.DELETE_WITH_CHILDREN]: isList
                      ? 'Delete Modules along with all their items'
                      : 'Delete permanently, along with all items'
              },
        [RemovableObjects.PAGE]: {
            [removeTypes.DELETE]: isList ? 'Delete Pages Only' : 'Delete Page Only',
            [removeTypes.DELETE_WITH_CHILDREN]: isList ? 'Delete Pages and all their objects' : 'Delete Page and all its objects'
        }
    };

    const renderDialogContent = () => {
        const options = removeOptions[objectType];

        return (
            <>
                <RemoveObjectSubtitleWrapper>
                    <RemoveObjectIcon>
                        <SVGInline src={icons.warningBlackIcon} />
                    </RemoveObjectIcon>
                    <RemoveObjectMainSection>
                        <RemoveObjectSubtitle>{subtitle} </RemoveObjectSubtitle>
                        <RemoveObjectSelectSection>
                            {(Object.keys(options) as Array<keyof typeof options>).map((option, index) => {
                                return (
                                    <DialogRadioButton
                                        key={`remove_radio_${index}`}
                                        text={options[option]}
                                        active={option === selectedRemoveType}
                                        onClick={() => setSelectedRemoveType(option)}
                                    />
                                );
                            })}
                        </RemoveObjectSelectSection>
                    </RemoveObjectMainSection>
                </RemoveObjectSubtitleWrapper>
            </>
        );
    };
    const onConfirm = () => {
        switch (selectedRemoveType) {
            case removeTypes.UNLINK:
                return onUnlink?.();
            case removeTypes.DELETE:
                return onDelete();
            case removeTypes.DELETE_WITH_CHILDREN:
                return onDelete(true);
            default:
                return;
        }
    };

    return (
        <GenericDialog
            buttonsInfo={{
                noButton: { label: 'Cancel', type: 'DEFAULT' },
                confirmButton: {
                    label: 'Remove',
                    type: [removeTypes.DELETE, removeTypes.DELETE_WITH_CHILDREN].includes(selectedRemoveType) ? 'RED' : 'BLUE'
                }
            }}
            type={DialogTypes.Confirm}
            title={isList ? `Remove Multiple ${_.capitalize(objectType)}s` : `Remove ${label || _.capitalize(objectType)}`}
            confirmReversed
            onConfirm={onConfirm}
        >
            {renderDialogContent()}
        </GenericDialog>
    );
};
