import React, { useEffect, useRef } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import history from '../history';

import '../utils/fnFirebase';
import '../utils/fnSentry';

import { setupDocumentation } from '../utils/circle';
import FeedbackButton from './Buttons/FeedbackButton';
import Routes from './Routes';

export const dialogsRef = React.createRef<HTMLDivElement>();
export let docsWidget: { element: HTMLElement | null; setSlug: (slug: string, spaceSlug?: string, fullUrl?: boolean) => void } | null =
    null;

export default function App() {
    useEffect(() => {
        setTimeout(() => (docsWidget = setupDocumentation() as any), 1000);
    }, []);

    useEffect(() => {
        /*
            When dealing with closing the dialogs via ESCAPE, if we have multiple dialogs open, after the first one is closed,
            the focus jumps back on the BODY element. The implementation below is a workaround for the ESCAPE event, if there's an
            ESCAPE event on the BODY element, we check if we have a dialog open  and repeat the event on it.(this is represented by the last div with the role="presentation")

         */

        const listener = (event: any) => {
            if (event.key !== 'Escape' || !event.isTrusted) return;
            const divsWithRolePresentation = document.body.querySelectorAll('div[role="presentation"]');
            const lastDivWithRolePresentation = divsWithRolePresentation[divsWithRolePresentation.length - 1];
            lastDivWithRolePresentation?.dispatchEvent(new KeyboardEvent('keydown', event));
        };
        document.body.addEventListener('keydown', listener);
        return () => {
            document.body.removeEventListener('keydown', listener);
        };
    }, []);

    return (
        <Router history={history as any}>
            <div id="app-container">
                <Routes />
                <FeedbackButton />
            </div>
            <div id="dialogs-container"></div>
            <div id="toasts-container"></div>
        </Router>
    );
}
