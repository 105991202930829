import styled, { CSSProperties, css } from 'styled-components';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import { Drawer } from '@material-ui/core';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { devices, IconPreviewImage, SidebarSVGInline, StyledSVGInline } from '../../../style/styled-components/reusable.css';
import { DropdownContent, DropdownMenuList } from '../DropdownMenu/DropdownMenu.css';

const theme: any = getTheme();

export const SidebarWrapper = styled.div`
    position: sticky;
    position: -webkit-sticky;
    width: 240px;
    top: 0;
    height: 100vh;
    flex: 1;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    border: 1px solid ${theme.palette.background.contrast};
    .active {
        background: ${theme.palette.background.active};
    }
    div {
        div {
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
`;

export const SidebarFooter = styled.div`
    margin-top: auto;
    ${fontSizesCSS.Caption4};
`;

export const AppWrapper = styled.div`
    text-align: center;
`;

export const AppLogo = styled.img`
    height: 32px;
    display: flex;
    width: 148px;
    &:hover {
        cursor: pointer;
    }
`;
export const AppLogoCollapsed = styled.img`
    height: 20px;
    display: flex;
    width: 24px;
    &:hover {
        cursor: pointer;
    }
    margin-bottom: -16px;
`;
export const GroupProjectTitle = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    ${fontSizesCSS.Body2};
    &:hover {
        cursor: pointer;
    }
`;
export const ItemRowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const ItemRow = styled.div<{ $active?: boolean; $extraPages?: boolean }>`
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        background-color: ${theme.palette.background.contrast};
    }
    ${(props) => props.$active && `background-color: ${theme.palette.background.contrast}`};
    &:hover > a {
        font-weight: bold;
    }
    a {
        max-width: 206px;
        text-decoration: none;
        ${fontSizesCSS.Body1};
        color: ${theme.palette.text.main};
        ${(props) => props.$active && `font-weight: bold`};
        ${({ $extraPages }) =>
            $extraPages &&
            `
        ${IconPreviewImage} {
            padding: 0 10px;
            width: 18px;
            height: 18px;
        }`}
        display: flex;
        align-items: center;
    }
    button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: transparent;
        border: none;
        ${fontSizesCSS.Body1};
        color: ${theme.palette.text.main};
        &:hover {
            cursor: pointer;
        }
    }
    div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const SidebarItemActions = styled.span`
    display: flex;
    gap: 4px;
    margin-left: auto;
    padding-right: 5px;
    align-items: center;
    svg {
        width: 15px;
        height: 15px;
    }
`;
export const EngageSidebarSVGInline = styled(SidebarSVGInline)`
    opacity: 1;
`;

export const EngageDropdownItemRow = styled(ItemRow)`
    background-color: ${({ $active }) => ($active ? theme.palette.background.menuItemHover : theme.palette.background.menuItem)};
    &:hover {
        background-color: ${theme.palette.background.menuItemHover};
    }
`;

export const EngageDropdownItemTitle = styled.div`
    font-weight: bold;
`;

export const AddLinkDialogStyles: CSSProperties = {
    width: '608px'
};

export const GroupStyled = styled.div`
    ${fontSizesCSS.Body1};
    color: ${theme.palette.text.main};
    display: flex;
    flex-direction: column;
`;

export const GroupTitle = styled.div`
    ${fontSizesCSS.Caption2};
    color: ${theme.palette.text.main};
    opacity: 0.4;
    padding-left: 8px;
`;

export const CustomDrawer = styled(Drawer)<{ $disabled?: boolean }>`
    z-index: ${zIndexesCSS.zIndexSideBar};
    transition: width 150ms ease-in-out;
    width: ${(props) => (props.open ? '240px' : '72px')};
    > div:nth-child(1) {
        background: ${theme.palette.background.default};
    }
    .MuiDrawer-paperAnchorDockedLeft {
        border: none;
    }
    > div {
        overflow: hidden;
    }

    ${(props) => props.$disabled && 'pointer-events: none;'}

    ${SidebarWrapper} {
        position: relative;
        width: ${(props) => (props.open ? '240px' : '72px')};
        padding: 40px 16px 8px 16px;
        box-sizing: border-box;
        transition: width 150ms ease-in-out, opacity 500ms ease-in-out;
        display: flex;
        flex-direction: column;
        gap: ${(props) => (props.open ? '16px' : '36px')};

        ${(props) =>
            props.$disabled &&
            css`
                opacity: 0.7;

                ${DropdownContent} {
                    pointer-events: none;
                }
            `}
    }

    ${devices.desktopS} {
        position: absolute;
    }
`;

export const SideBarArrow = styled.div<{ open: boolean }>`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    display: flex;
    justify-content: flex-start;
    div {
        box-sizing: border-box;
        transform: ${(props: any) => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
        z-index: 1300;
        position: fixed;
        margin-left: 12px;
        top: 42px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${theme.palette.grey.light};
        color: ${theme.palette.text.main};
        border-radius: 100%;
        background-color: ${theme.palette.background.main};
        cursor: pointer;
        svg {
            padding: 0;
            width: 12px;
            height: 12px;
        }
        &:hover {
            background-color: ${theme.palette.primary.main};
            path {
                fill: ${theme.palette.white.main};
                color: ${theme.palette.white.main};
            }
        }
    }
`;

export const ProjectMenuItem = styled.div<{ sidebarOpen?: boolean; $isActive?: boolean }>`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    ${fontSizesCSS.Body1};
    font-weight: ${({ $isActive }) => ($isActive ? 'bold' : '')};
    background-color: ${({ $isActive }) => ($isActive ? theme.palette.background.menuItemHover : theme.palette.background.menuItem)};
    &:hover {
        background-color: ${theme.palette.background.menuItemHover};
    }
    border-radius: 4px;
`;

export const ProjectMenuItemImgContainer = styled.div``;

export const ProjectMenuItemImg = styled.img`
    width: 20px;
    height: 20px;
`;
export const ProjectSelectArrow = styled(StyledSVGInline)`
    width: 20px;
    height: 20px;
    padding: 0;
    margin-left: auto;
    visibility: hidden;
`;

export const ProjectSelectDropdownMenu = styled(DropdownMenu)`
    ${DropdownMenuList} {
        background: ${theme.palette.background.contrast};
    }
    width: 100%;
    &:hover {
        ${ProjectSelectArrow} {
            visibility: visible;
        }
    }
`;

export const EngageSelectArrow = styled(ProjectSelectArrow)`
    padding-right: 8px;
`;

export const CollapsableItemRow = styled(ItemRow)`
    &:hover {
        ${EngageSelectArrow} {
            visibility: visible;
        }
    }
`;

export const SubsectionRow = styled(ItemRow)`
    padding-left: 38px;
`;

export const TenantsMenuItemImg = styled(ProjectMenuItemImg)`
    padding: 0 10px;
`;
