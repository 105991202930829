import React, { FC, useEffect, useState } from 'react';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { GroupField, GroupFieldsContainer } from '../TargetGroups/GroupEdit.css';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import { NewAdSettingsDialog } from './Dialogs/NewAdSettingsDialog';
import { useAppSelector, useAppDispatch as useDispatch } from '../../hooks/redux';
import {
    AdSettingsState,
    createAdSettings,
    deleteAdSettings,
    deleteAdSettingsList,
    fetchAdSettings,
    fetchAdUnits,
    updateAdSettings
} from '../../redux/slices/adSettingsSlice';
import { ADTypes, AD_TYPES, AdSetting } from '../../types/AdSettings';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { generateDateStringForTables } from '../../utils/fnDate';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { WidthTableCell } from '../common/Table/Table.css';
import Labels from '../common/Labels/Labels';
import { adSettingsTableSizes } from '../../types/TableSizes';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import useScreenSize from '../../hooks/useScreenSize';
import { useNavigate } from 'react-router-dom';
import { PageRoutes, buildPathWithProjectId } from '../../types/RouteTypes';
import GenericTable, { ActionsTableCell, ImageTableCell, SelectAllTableCell, SortableTableCell, tableActions } from '../common/Table/Table';
import { ObjectTypes } from '../../types/Object';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import { EMPTY_WORD_STRING } from '../../utils/Globals';
import { dialogConfirm } from '../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { useMultiSelect } from '../../hooks/useMultiSelect';
import PaginationWrapper, { ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';
import { Loader } from '../common/Loader/Loader';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    DEFAULT_SORT_CONFIG,
    ISortConfig,
    calculateOrderByFromSortConfig
} from '../../utils/fnSort';
import { fetchAudiences } from '../../redux/slices/audienceSlice';
import { fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { BlueObjectName } from '../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip.css';
import { CampaignIdWrapper } from './AdSettings.css';
import { SourcesState, fetchServices } from '../../redux/slices/sourceSlice';

const AdSettings: FC = () => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { adSettings, adUnits, loading, error, totalResults }: AdSettingsState = useAppSelector((state) => state.adSettings);
    const { services }: SourcesState = useAppSelector((state) => state.dynamicSources);

    const { isRestrictedArea: isMonetizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'monetize');
    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();
    const { select } = useMultiSelect();
    const [activeTab, setActiveTab] = useState<'FORMAT_RULES' | 'CONTENT_RULES'>('FORMAT_RULES');
    const [openNewSettingsDialog, setOpenNewSettingDialog] = useState<boolean>(false);
    const [adSettingToEdit, setAdSettingToEdit] = useState<AdSetting | undefined>(undefined);
    const [ads, setAds] = useState<AdSetting[]>([]);
    const [adSettingType, setAdSettingType] = useState<ADTypes>(AD_TYPES.FORMAT);

    const [adUnitOptions, setAdUnitOptions] = useState<{ value: string; label: JSX.Element }[]>([]);

    ///multiSelect
    const [isMultiSelectVisible, setIsMultiSelectVisible] = useState<boolean>(false);
    const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
    const [allSettingsSelected, setAllSettingsSelected] = useState<boolean>(false);

    //pagination
    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadAdSettings = async (
        projectId: string,
        type: ADTypes,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string
    ) => {
        await dispatch(fetchAdSettings({ projectId, type, pageSize, pageNumber, orderBy, searchTerm })).unwrap();
    };

    const loadAdUnits = async () => {
        await dispatch(fetchAdUnits());
    };

    const createSettings = async (settings: AdSetting) => {
        await dispatch(createAdSettings(settings)).unwrap();
        loadAdSettings(activeProjectId || '', adSettingType, pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        resetCallback();
    };
    const updateSettings = async (settings: AdSetting) => {
        await dispatch(updateAdSettings(settings)).unwrap();
        loadAdSettings(activeProjectId || '', adSettingType, pageSize, currentPage, orderBy, searchTerm);
    };

    const removeSettings = async (id: string) => {
        await dispatch(deleteAdSettings({ id })).unwrap();
        loadAdSettings(activeProjectId || '', adSettingType, pageSize, currentPage, orderBy, searchTerm);
    };

    const removeSettingsList = async (items: string[]) => {
        await dispatch(deleteAdSettingsList(items)).unwrap();
        loadAdSettings(activeProjectId || '', adSettingType, pageSize, currentPage, orderBy, searchTerm);
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.currentPage && setCurrentPage(1);
        !keep?.searchTerm && setSearchTerm(undefined);
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
    };

    const loadServices = async () => {
        return await dispatch(fetchServices(activeProjectId || '')).unwrap();
    };

    const onSearchTermOrPaginationChange = (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        adType: ADTypes,
        searchTerm?: string
    ) => {
        setIsMultiSelectVisible(false);
        loadAdSettings(activeProjectId || '', adType, pageSize, currentPage, orderBy, searchTerm);
    };

    const handleDeleteIconClick = (id?: string) => {
        const values = {
            title: `Remove ${id ? 'Ad Setting' : 'multiple Ad Settings'} `,
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                id ? removeSettings(id) : removeSettingsList(multiSelectedIds);
            },
            values,
            <RemoveModuleWrapper>
                <SVGInline src={icons.warningIcon} />
                <p>
                    <strong>{`Are you sure you want to remove this  ${id ? 'Ad Setting' : 'Multiple list of Ad Settings'}?`}</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            undefined,
            undefined,
            true
        );
    };

    const handleSortIconClick = (field: AcceptedSortField, specifiedDirection?: 'asc' | 'desc', currentPage?: number) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = specifiedDirection || 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc' && !specifiedDirection) {
            direction = 'desc';
        }

        const config = {
            direction,
            field
        };

        const orderBy = `${config.field}[${config.direction}]`;

        setSortConfig(config);
        loadAdSettings(activeProjectId || '', adSettingType, pageSize, currentPage || 1, orderBy, searchTerm);

        setShowSortArrows(false);
        setIsMultiSelectVisible(false);
    };

    useEffect(() => {
        setTotalPages(totalResults !== undefined ? Math.ceil(totalResults / pageSize) : 1);
    }, [pageSize, totalResults]);

    useEffect(() => {
        if (!ads?.length) return;
        setAllSettingsSelected(multiSelectedIds.length === ads.length);
    }, [multiSelectedIds, ads]);

    useEffect(() => {
        if (!activeProjectId) return;
        loadAdSettings(activeProjectId, adSettingType, pageSize, currentPage, orderBy, searchTerm);
    }, [activeProjectId, adSettingType]);

    useEffect(() => {
        loadAudiences();
        loadConditions();
        loadServices();
    }, [activeProjectId]);

    useEffect(() => {
        loadAdUnits();
    }, []);

    useEffect(() => {
        setAdUnitOptions(
            adUnits.map((unit) => ({
                value: unit.id,
                valueForSearch: `${unit.name} ${unit.id}`,
                label: (
                    <CampaignIdWrapper>
                        <SVGInline src={icons.campaignIdIcon} />
                        <span>
                            {unit.name} / ID: {unit.id}
                        </span>
                    </CampaignIdWrapper>
                )
            }))
        );
    }, [adUnits]);

    useEffect(() => {
        setAdSettingType(activeTab === 'CONTENT_RULES' ? AD_TYPES.CONTENT : AD_TYPES.FORMAT);
        resetCallback();
    }, [activeTab]);

    useEffect(() => {
        setAds(adSettings);
    }, [adSettings]);

    const buildTableColumns = (type: ADTypes) => {
        const columns = [
            <SortableTableCell
                key={`name_cell`}
                text={'Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={adSettingsTableSizes.name}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <WidthTableCell key={'ad_format_cell'} {...adSettingsTableSizes.adFormat}>
                {'Ad Format'}
            </WidthTableCell>,
            <WidthTableCell key={'conditions_cell'} {...adSettingsTableSizes.conditions}>
                {'Target Conditions'}
            </WidthTableCell>,
            <WidthTableCell key={'ad_unit_cell'} {...adSettingsTableSizes.adUnit}>
                {'Ad Unit'}
            </WidthTableCell>,
            <SortableTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={adSettingsTableSizes.lastModified}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        const contentTableCell = (
            <WidthTableCell key={'content_format_cell'} {...adSettingsTableSizes.adFormat}>
                {'Content Format'}
            </WidthTableCell>
        );
        type === AD_TYPES.CONTENT && columns.splice(1, 0, contentTableCell);

        columns.push(<TableCell key={`empty_cell_modules`} />);

        ads?.length &&
            columns.push(
                <>
                    <SelectAllTableCell
                        onDelete={handleDeleteIconClick}
                        onSelectAll={(selected) => {
                            setMultiSelectedIds(selected ? ads.map((ad) => ad._id) : []);
                        }}
                        allValuesSelected={allSettingsSelected}
                        isMultiSelectVisible={isMultiSelectVisible}
                        setIsMultiSelectVisible={(arg) => setIsMultiSelectVisible(arg)}
                        isDeleteDisabled={!multiSelectedIds.length}
                    />
                </>
            );
        return columns;
    };

    const buildTableBody = (type: ADTypes) => {
        const rows: JSX.Element[] = ads.map((ad, index) => {
            const lastModified = generateDateStringForTables(ad?.lastModified || 0);

            return (
                <>
                    <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => {}}>
                        <WidthTableCell {...adSettingsTableSizes.name}>
                            <TruncatedText>
                                <BlueObjectName>{ad.name || EMPTY_WORD_STRING}</BlueObjectName>{' '}
                            </TruncatedText>
                        </WidthTableCell>
                        {type === AD_TYPES.CONTENT && (
                            <WidthTableCell {...adSettingsTableSizes.adFormat}>
                                <Labels
                                    withTranslationTooltip
                                    values={
                                        (ad.contentFormat || []).map((ad) => ({
                                            name: services.find((service) => service?.key === ad)?.title || ad
                                        })) || []
                                    }
                                    type={MoreInfoTypes.PLACED}
                                    noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                                />
                            </WidthTableCell>
                        )}

                        <WidthTableCell {...adSettingsTableSizes.adFormat}>
                            <Labels
                                withTranslationTooltip
                                values={ad.adFormat.map((ad) => ({ name: ad })) || []}
                                type={MoreInfoTypes.PLACED}
                                noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                            />
                        </WidthTableCell>

                        <WidthTableCell {...adSettingsTableSizes.conditions}>
                            <Labels
                                values={ad?.conditions || []}
                                type={MoreInfoTypes.TARGETS}
                                noOfLabels={isLargeDesktop ? 2 : isMobile ? 0 : 1}
                                onClickLabel={(obj) => {
                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                    navigate(
                                        buildPathWithProjectId(
                                            activeProjectId,
                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                        ),
                                        {
                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                        }
                                    );
                                }}
                            />
                        </WidthTableCell>
                        <WidthTableCell {...adSettingsTableSizes.adUnit}>
                            <CampaignIdWrapper>
                                {!!ad.adUnit && <SVGInline src={icons.campaignIdIcon} />}
                                <span>{ad.adUnit}</span>
                            </CampaignIdWrapper>
                        </WidthTableCell>
                        <WidthTableCell {...adSettingsTableSizes.lastModified}>{lastModified}</WidthTableCell>
                        <ImageTableCell
                            um={'px'}
                            shape="round"
                            src={ad?.modifiedByUser?.icon || avatarIcon}
                            toolTipName={ad?.modifiedByUser?.name}
                            imageSize={{ width: 32, height: 32 }}
                        />

                        <WidthTableCell {...adSettingsTableSizes.actions}>
                            <ActionsTableCell
                                actions={
                                    isMultiSelectVisible
                                        ? [tableActions.MULTI_SELECT]
                                        : [tableActions.COPY, tableActions.EDIT, tableActions.DUPLICATE, tableActions.REMOVE]
                                }
                                onEdit={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    setOpenNewSettingDialog(true);
                                    setAdSettingToEdit(ad);
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    handleDeleteIconClick(ad._id);
                                }}
                                tooltipTexts={{
                                    edit: 'adSettings_icon_edit',
                                    duplicate: 'adSettings_icon_duplicate',
                                    delete: 'adSettings_icon_delete'
                                }}
                                onMultiSelect={() => {
                                    const settingIds = ads.map((ad) => ad._id);
                                    const newValues = select(settingIds, multiSelectedIds, ad._id);
                                    setMultiSelectedIds([...newValues]);
                                }}
                                selected={multiSelectedIds.includes(ad._id)}
                            />
                        </WidthTableCell>
                    </TableRow>
                </>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderContent = () => {
        return (
            <>
                {!!adSettings?.length && <GenericTable body={buildTableBody(adSettingType)} columns={buildTableColumns(adSettingType)} />}
                <PageActionsWrapper>
                    <PageActionButton
                        type={'BLUE'}
                        label={'Create Settings'}
                        onClick={() => {
                            setOpenNewSettingDialog(true);
                        }}
                    />
                </PageActionsWrapper>
            </>
        );
    };

    const renderSectionTabs = () => {
        return (
            <GroupFieldsContainer withBottomMargin>
                <GroupField onClick={() => setActiveTab('FORMAT_RULES')} $active={activeTab === 'FORMAT_RULES'}>
                    Ad Format Rules
                </GroupField>
                <GroupField onClick={() => setActiveTab('CONTENT_RULES')} $active={activeTab === 'CONTENT_RULES'}>
                    Content Ad Rules
                </GroupField>
            </GroupFieldsContainer>
        );
    };
    return (
        <ApplicationWrapper>
            {error && <BackendErrorDialog error={error} />}
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={loading}
                    title={'Ad Rules'}
                    withProfile
                    withAddButton={!isMonetizeRestricted}
                    onAdd={() => {
                        setOpenNewSettingDialog(true);
                    }}
                    addLabel={'Create Settings'}
                />

                {isMonetizeRestricted ? (
                    <CapabilitiesScreen type={Capabilities.MONETIZE} disabled />
                ) : (
                    <>
                        {renderSectionTabs()}

                        <PaginationWrapper
                            orderBy={orderBy}
                            totalPages={totalPages}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            onSearchTermOrPaginationChange={(pageSize, currentPage, orderBy, searchTerm, _filter, adType) => {
                                onSearchTermOrPaginationChange(pageSize, currentPage, orderBy, adType || adSettingType, searchTerm);
                            }}
                            tooltipText={'adSettings_icon_search'}
                            resetCallback={resetCallback}
                            type={ObjectTypes.SETTINGS}
                            adType={adSettingType}
                        >
                            {loading ? <Loader /> : renderContent()}
                        </PaginationWrapper>
                    </>
                )}
            </MainContentWrapper>

            <NewAdSettingsDialog
                open={openNewSettingsDialog}
                onClose={() => {
                    setOpenNewSettingDialog(false);
                    setAdSettingToEdit(undefined);
                }}
                onSave={(settings) => {
                    adSettingToEdit ? updateSettings(settings) : createSettings(settings);
                }}
                adSetting={adSettingToEdit}
                adUnitOptions={adUnitOptions}
                type={adSettingType}
            />
        </ApplicationWrapper>
    );
};

export default AdSettings;
