import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import { PrimeAndDisneyParamNames, Service, ServiceParamOptionLabels, SortOptionsMap } from '../../../utils/Services';
import { DialogDropdownMultiple, DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';
import { ReleaseYearContainer } from '../Sources.css';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { PreviewItemsState } from '../../../redux/slices/previewItemsSlice';
import { useAppSelector } from '../../../hooks/redux';
import { removeEmptyParams } from '../../../utils/fnData';
import icons from '../../../assets/images/icons';
import { OptionSection, OptionsToggle } from '../../Modules/Dialogs/NewModule.css';
import usePrevious from '../../../hooks/usePrevious';
import { contentSourceTypes } from '../../../types/Item';

type PrimeVideoSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        pathParamsValues: any;
        bodyParamsValues: any;
        errors: { method?: string; name?: string; param?: string; bodyParams?: any; pathParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
        setBodyParamsValues: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

export const PrimeAndDisneyMethods = {
    amazon_search: {
        name: 'Amazon Search',
        filters: ['id', 'genres.title', 'releaseYear'],
        sort: true,
        hideFilters: true
    },
    amazon_filter: {
        name: 'Amazon Filter',
        filters: ['genres.title', 'releaseYear'],
        sort: true,
        hideFilters: false
    },
    disney_search: {
        name: 'Disney Search',
        filters: ['id', 'genres.title', 'releaseYear'],
        sort: true,
        hideFilters: true
    },
    disney_filter: {
        name: 'Disney Filter',
        filters: ['genres.title', 'releaseYear'],
        sort: true,
        hideFilters: false
    }
};

export const PrimeAndDisneyMethodKeys = {
    AMAZON_SEARCH: 'amazon_search',
    AMAZON_FILTER: 'amazon_filter',
    DISNEY_SEARCH: 'disney_search',
    DISNEY_FILTER: 'disney_filter'
};

const PrimeAndDisneySourceFields: FC<PrimeVideoSourceFieldsProps> = ({
    service,
    dropdownOptions: { methodOptions },
    fieldValues: { selectedMethod, bodyParamsValues, pathParamsValues, errors },
    fieldSetters: { setSelectedMethod, setQueryParamsValues, setPathParamsValues, setBodyParamsValues, setErrors }
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState<{ value: string; label: string }[]>([]);
    const [showFiltersAlways, setShowFiltersAlways] = useState(false);

    const previousPathParams = usePrevious(pathParamsValues);

    const { items }: PreviewItemsState = useAppSelector((state) => state.preview);

    useEffect(() => {
        if (!selectedMethod || !bodyParamsValues) return;
        if (!bodyParamsValues.sort) {
            const defaultSort = [PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)
                ? 'notSorted'
                : service.methods.find((method) => method.key === selectedMethod)?.params.body?.sort?.[0];
            if (defaultSort) {
                setBodyParamsValues((values: any) => ({ ...values, sort: defaultSort }));
            }
        }

        if (!bodyParamsValues.languages?.length) {
            setSearchTerm('');
        }
    }, [bodyParamsValues]);

    useEffect(() => {
        setSearchTerm('');
        setSearchOptions([]);
    }, [selectedMethod]);

    useEffect(() => {
        if (searchTerm.length >= 2) {
            handleSearch(searchTerm, removeEmptyParams(bodyParamsValues), pathParamsValues);
        } else {
            handleSearch.cancel();
            if (!searchTerm) {
                const selectedIds = [...(bodyParamsValues?.['filter.id'] || [])].map((id: string) => decodeURIComponent(id));
                setSearchOptions(searchOptions.filter((opt) => selectedIds.includes(decodeURIComponent(opt.value))));
                setSearchTerm('');
            }
        }
    }, [searchTerm, bodyParamsValues]);

    useEffect(() => {
        if (!items.length || !bodyParamsValues?.['filter.id']?.length || searchOptions.length === items.length || !!searchTerm) return;

        const ids = [...bodyParamsValues['filter.id']];
        setSearchOptions(
            ids
                .map((data: string) => ({
                    value: encodeURIComponent(data),
                    label: items.find((i) => i.id === data)?.title || ''
                }))
                .filter((item) => item.label)
        );
    }, [items]);

    useEffect(() => {
        setSearchTerm('');
        if (pathParamsValues && previousPathParams && pathParamsValues.type !== previousPathParams.type) {
            setBodyParamsValues({});
            setSearchOptions([]);
        }
    }, [pathParamsValues]);

    const handleSearch = useMemo(
        () =>
            _.debounce(async (searchTerm: string, bodyParamsValues, pathParamsValues) => {
                setSearchLoading(true);
                let result: any;
                try {
                    result = await configServiceAPI.assetstoreSearch(
                        service.key === 'prime_video' ? contentSourceTypes.PRIME_VIDEO : contentSourceTypes.DISNEY_PLUS,
                        pathParamsValues.type,
                        bodyParamsValues.languages,
                        searchTerm,
                        bodyParamsValues['filter.genres.title'],
                        bodyParamsValues['filter.releaseYear.start'],
                        bodyParamsValues['filter.releaseYear.end']
                    );
                } catch (ex) {
                    console.error(ex);
                }
                setSearchLoading(false);
                if (!result?.response) return;

                setSearchOptions((searchOptions) => {
                    const newResults = result.response.map((data: any) => ({ value: encodeURIComponent(data.id), label: data.title }));
                    const decodedSelectedValues = bodyParamsValues?.['filter.id']?.length
                        ? bodyParamsValues['filter.id'].map((elem: any) => decodeURIComponent(elem))
                        : [];
                    const allValues = [
                        ...searchOptions.filter((opt: any) => decodedSelectedValues.includes(decodeURIComponent(opt.value))),
                        ...newResults
                    ];

                    return [...new Map(allValues.map((elem) => [elem.value, elem])).values()];
                });
            }, 1000),
        []
    );

    const renderTypeField = () => {
        const typeOptions = service.methods[0].params.path?.type?.map((type: string) => ({
            value: type.toLowerCase().replace(/\s/g, ''),
            label: type === 'Event' ? 'Live Events' : type
        }));

        return (
            <DialogDropdownSingle
                value={typeOptions?.find((opt: any) => opt.value === pathParamsValues?.type) || ''}
                options={typeOptions}
                placeholder={'Select Asset Type'}
                onChange={(value: any) => {
                    const newValue = value.value;
                    setErrors(_.omit(errors, ['pathParams', 'type']));
                    setPathParamsValues((values: any) => ({ ...values, type: newValue }));
                    if (!newValue) {
                        setBodyParamsValues({});
                    }
                }}
                error={errors.pathParams?.type}
                dataCy={'prime-asset-type-field'}
            />
        );
    };

    const renderLanguagesAndCountry = () => {
        const countryOptions = service.methods[0].params.body?.country?.map((country: string) => ({
            value: country,
            label: _.get(ServiceParamOptionLabels, country, country)
        }));

        const languagesOptions = service.methods[0].params.body?.languages?.map((lang: string) => {
            const twoLetterCode = lang.includes('-') ? lang.split('-')?.[0] || '' : lang;
            return {
                value: lang,
                label: _.get(ServiceParamOptionLabels, twoLetterCode, twoLetterCode)
            };
        });

        let countrySelect = null;

        if (countryOptions.length > 1) {
            countrySelect = (
                <DialogDropdownSingle
                    value={countryOptions?.find((opt: any) => opt.value === bodyParamsValues?.country) || ''}
                    options={countryOptions}
                    placeholder={'Select Country'}
                    onChange={(value: any) => {
                        setErrors(_.omit(errors, ['bodyParams', 'country']));
                        setBodyParamsValues((values: any) => ({ ...values, country: value.value }));
                    }}
                    error={errors.bodyParams?.country}
                    dataCy={'prime-method-country-field'}
                />
            );
        }

        const languageSelect = (
            <DialogDropdownSingle
                value={languagesOptions?.find((opt: any) => bodyParamsValues?.languages?.includes(opt.value)) || ''}
                options={languagesOptions}
                placeholder={'Select Language'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'languages']));
                    setBodyParamsValues((values: any) => ({ ...values, languages: [value.value] }));
                }}
                error={errors.bodyParams?.languages}
                dataCy={'prime-method-languages-field'}
            />
        );

        return (
            <>
                {countrySelect}
                {languageSelect}
            </>
        );
    };

    const renderSortField = () => {
        if (!_.get(PrimeAndDisneyMethods, selectedMethod)?.sort) {
            return <></>;
        }
        const sortOptions = service.methods
            .find((method) => method.key === selectedMethod)
            ?.params.body?.sort?.map((sortString: string) => ({
                value: sortString,
                label: _.get(SortOptionsMap, sortString, sortString)
            }));

        return (
            <DialogDropdownSingle
                value={sortOptions?.find((opt: any) => opt.value === bodyParamsValues?.sort)}
                options={sortOptions}
                placeholder={'Select Sorting'}
                onChange={(selectedSortOpt: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'sort']));
                    setBodyParamsValues((values: any) => ({ ...values, sort: selectedSortOpt.value }));
                    // in case of notSorted selected(or re-selected), populate the orderBy field
                    if (selectedSortOpt.value === 'notSorted') {
                        bodyParamsValues['filter.id'] &&
                            setQueryParamsValues((values: any) => ({ ...values, orderBy: bodyParamsValues['filter.id'].join(',') }));
                    } else {
                        // in case of a new kind of sorting is selected, remove the orderBy field so the previews are correct
                        setQueryParamsValues((values: any) => _.omit(values, 'orderBy'));
                    }
                }}
                error={errors.bodyParams?.sort}
                dataCy={'prime-method-sort-field'}
            />
        );
    };

    const renderFilterFields = () => {
        const methodFilters: string[] = _.get(PrimeAndDisneyMethods, [selectedMethod, 'filters'], []);
        const methodParams = service.methods.find((m) => m.key === selectedMethod)?.params?.body;
        if (!methodFilters?.length || !methodParams) return null;

        const fields: JSX.Element[] = [];

        const renderSearchFields = (filter: string, key: string) => {
            const searchFields: JSX.Element[] = [];
            if (filter !== 'id') return searchFields; // to be extended when more search fields will be added

            searchFields.push(
                <DialogTextField
                    key={`search-${filter}-field`}
                    value={searchTerm}
                    label={`Search assets by title`}
                    placeholder={`Insert title`}
                    toolTipText={`Please insert at least 2 characters`}
                    onChange={(evt: any) => {
                        setSearchTerm(evt.target.value);
                    }}
                />
            );

            const decodedValues = bodyParamsValues?.[key]?.length ? bodyParamsValues[key].map((elem: any) => decodeURIComponent(elem)) : [];

            searchFields.push(
                <DialogDropdownMultiple
                    key={`search-options-field`}
                    value={searchOptions.filter((opt: any) => decodedValues.includes(decodeURIComponent(opt.value)))}
                    options={searchOptions}
                    placeholder={searchLoading ? 'Loading...' : `Select ${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    labelText={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    toolTipText={'Use the field above to search assets. The results will appear here.'}
                    onChange={(value: any) => {
                        const newValue = value.map((elem: any) => elem.value);
                        // set orderBy only when noSorting is selected
                        if (key === 'filter.id' && newValue?.length > 1 && bodyParamsValues.sort === 'notSorted') {
                            setQueryParamsValues((values: any) => ({ ...values, orderBy: newValue.join(',') }));
                        }
                        setBodyParamsValues((values: any) => ({ ...values, [key]: newValue }));
                        setErrors((errors: any) => {
                            const newErrors = { ...errors };
                            delete newErrors.bodyParams?.['filter.id'];
                            return newErrors;
                        });
                    }}
                    allowSelectAll
                    notSorted
                    withLoadingIndicator={searchLoading}
                    error={errors?.bodyParams?.['filter.id']}
                />
            );

            return searchFields;
        };

        const renderArrayFields = (
            filterObj: any,
            filter: string,
            key: string,
            index: number,
            allFields?: any[],
            hideFilters?: boolean
        ) => {
            const arrayFields: JSX.Element[] = [...renderSearchFields(filter, key)];
            // if we have search fields already or if we need to hide the filters, we move on
            if (arrayFields.length || hideFilters) {
                arrayFields.push(renderShowFiltersToggle(allFields));
                return arrayFields;
            }

            const options = filterObj.map((elem: any) => ({
                value: elem,
                label: _.get(SortOptionsMap, elem, _.capitalize(elem))
            }));

            arrayFields.push(
                <DialogDropdownMultiple
                    key={`array-field-${index}`}
                    value={options?.filter((opt: any) => bodyParamsValues?.[key]?.includes(opt.value))}
                    options={options}
                    placeholder={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    labelText={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    onChange={(value: any) => {
                        setBodyParamsValues((values: any) => ({ ...values, [key]: value.map((elem: any) => elem.value) }));
                    }}
                    toolTipText={
                        [PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)
                            ? 'Just a helper field for the title search'
                            : undefined
                    }
                    optional
                    allowSelectAll
                />
            );

            return arrayFields;
        };

        const renderYearFields = (filter: string, key: string, index: number, hideFilters?: boolean) => {
            const yearFields: JSX.Element[] = [];
            // if we need to hide the filters, we move on
            if (hideFilters) return yearFields;

            const startKey = `${key}.start`;
            const endKey = `${key}.end`;
            const startDate = bodyParamsValues?.[startKey];
            const endDate = bodyParamsValues?.[endKey];
            const currentYear = new Date().getFullYear();
            const startOptions: { label: string; value: number }[] = [];
            const endOptions: { label: string; value: number }[] = [];
            for (let i = currentYear; i >= 1950; i--) {
                if (!endDate || endDate >= i) {
                    startOptions.push({
                        value: i,
                        label: i.toString()
                    });
                }
                if (!startDate || startDate <= i) {
                    endOptions.push({
                        value: i,
                        label: i.toString()
                    });
                }
            }
            yearFields.push(
                <ReleaseYearContainer>
                    <DialogDropdownSingle
                        key={`date-field-${index}-start`}
                        value={startOptions?.find((opt) => bodyParamsValues?.[startKey] === opt.value)}
                        options={startOptions}
                        placeholder={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)} Start`}
                        labelText={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)} Start`}
                        toolTipText={
                            'Date will be calculated from the start of the selected year' +
                            ([PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)
                                ? '. Just a helper field for the title search'
                                : '')
                        }
                        onChange={(value: any) => setBodyParamsValues((values: any) => ({ ...values, [startKey]: value.value }))}
                        optional
                        clearable
                        notSorted
                    />
                    <DialogDropdownSingle
                        key={`date-field-${index}-end`}
                        value={endOptions?.find((opt) => bodyParamsValues?.[endKey] === opt.value)}
                        options={endOptions}
                        placeholder={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)} End`}
                        labelText={`Select ${_.get(PrimeAndDisneyParamNames, filter, filter)} End`}
                        toolTipText={
                            'Date will be calculated to the end of the selected year' +
                            ([PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)
                                ? '. Just a helper field for the title search'
                                : '')
                        }
                        onChange={(value: any) => setBodyParamsValues((values: any) => ({ ...values, [endKey]: value.value }))}
                        optional
                        clearable
                        notSorted
                    />
                </ReleaseYearContainer>
            );

            return yearFields;
        };

        const renderShowFiltersToggle = (allFields?: JSX.Element[]) => {
            // if we don't need to hide the filters or we already have an options section, we move on
            if (!_.get(PrimeAndDisneyMethods, selectedMethod)?.hideFilters || allFields?.find((el) => el.key === 'options-section'))
                return <></>;

            return (
                <OptionSection key={'options-section'}>
                    <OptionsToggle onClick={() => setShowFiltersAlways(!showFiltersAlways)}>
                        {!showFiltersAlways ? 'Show' : 'Hide'} Optional Filters{' '}
                        <SVGInline src={showFiltersAlways ? icons.arrowUpIcon : icons.arrowDownIcon} />
                    </OptionsToggle>
                </OptionSection>
            );
        };

        const hideFilters = _.get(PrimeAndDisneyMethods, selectedMethod)?.hideFilters && !showFiltersAlways;
        methodFilters.forEach((filter, index) => {
            const key = `filter.${filter}`;
            const filterObj = _.get(methodParams, key, '');
            if (!filterObj) return;

            if (Array.isArray(filterObj)) {
                fields.push(...renderArrayFields(filterObj, filter, key, index, fields, hideFilters));
                return;
            }

            if (filter.toLocaleLowerCase().includes('year')) {
                fields.push(...renderYearFields(filter, key, index, hideFilters));
                return;
            }

            if (hideFilters) return;

            fields.push(
                <DialogTextField
                    key={`text-field-${index}`}
                    value={bodyParamsValues?.[key] || ''}
                    label={`Insert $${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    placeholder={`Insert ${_.get(PrimeAndDisneyParamNames, filter, filter)}`}
                    onChange={(evt: any) => {
                        setBodyParamsValues((values: any) => ({ ...values, [key]: evt.target.value }));
                    }}
                    toolTipText={
                        [PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)
                            ? 'Just a helper field for the title search'
                            : undefined
                    }
                    optional
                />
            );
        });

        return fields;
    };

    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source: any) => source.value === selectedMethod) || ''}
                options={methodOptions}
                placeholder={'Select Method'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, 'method'));
                    setSelectedMethod(value.value);
                    setQueryParamsValues({});
                    setPathParamsValues({});
                    setBodyParamsValues({});
                }}
                error={errors.method}
                dataCy={'prime-method-source-field'}
            />
            {selectedMethod && (
                <>
                    {renderTypeField()}
                    {renderLanguagesAndCountry()}
                    {bodyParamsValues?.languages?.length && pathParamsValues?.type ? (
                        <>
                            {renderSortField()}
                            {renderFilterFields()}
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default PrimeAndDisneySourceFields;
