export type RenamedKeysType = {
    pages: {
        [key: string]: string;
    };
    modules: {
        [key: string]: string;
    };
    items: {
        [key: string]: string;
    };
};

export const validateVersionFormat = (version: string) => {
    const parts = version.split('.');
    if (parts.length > 4) return false;

    for (const part of parts) {
        if (!/^-?\d+$/.test(part) && part !== 'x') return false;
    }

    // after x in the format, only x is accepted (no numbers, for ex: 1.2.x.2 is incorrect)
    let firstXIndex = parts.indexOf('x');
    if (firstXIndex >= 0) return !parts.some((part, i) => i >= firstXIndex && part !== 'x');
    return true;
};

export const capitalizeAndSplitCamelCaseString = (s: string) => {
    let str = `${s[0].toUpperCase()}${s.substring(1)}`;
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const sanitizeFileName = (name: string) => {
    return name.trim().replace(/\s+/g, '_');
};

const renamedKeys: RenamedKeysType = {
    pages: {
        name: 'Page Name',
        intent: 'Page Intent',
        pageStyleId: 'Page Style',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        slug: 'Slug',
        canonical: 'Canonical',
        description: 'Description',
        moduleIds: 'Modules',
        backgroundImage: 'Page Background Image',
        increasedTopMargin: 'Increased Top Margin',
        locked: 'Locked',
        publishAt: 'Scheduled Publishing'
    },
    modules: {
        name: 'Module Name',
        template: 'UI Template',
        itemIds: 'Items',
        pinnable: 'Pinnable',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        includeProviderLogoCard: 'Provider Logo Card',
        invisible: 'Hide Module on Page',
        hideContentMarker: 'Hide Content Marker',
        contentWorld: 'Content World',
        preview: 'Preview',
        topTen: 'Top X',
        autoCollection: 'Auto-Collection',
        autoScroll: 'Auto-Scroll',
        placed: 'Placed'
    },
    items: {
        name: 'Item Name',
        placed: 'Placed',
        conditionIds: 'Target Condition',
        contentWorldType: 'Content World Type',
        videoForAutoplay: 'Video For Auto-Play',
        logo: 'Logo',
        title: 'Title',
        subtitle: 'Subtitle',
        description: 'Description',
        actionName: 'Action Name',
        action: 'Action',
        dynamicSourceId: 'Source',
        maxNumberOfItems: 'Maximum Number of Items',
        alterateBehaviour: 'Alternate Behaviour',
        singleAsset: 'Single Asset',
        videoAutoplay: 'Video Auto-Play'
    }
};

export const formatObjectActivityKey = (objectType: keyof RenamedKeysType, key: string) => {
    return renamedKeys[objectType][key] || key;
};
