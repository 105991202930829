import styled, { CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { TemplateCard } from '../../common/Dialog/CreateResourceDialog.css';
import { FileField, FileFieldContainer, FileItem, StyledDialog } from '../../common/Dialog/GenericDialog.css';
import { SelectWrapper } from '../../common/Select/Select.css';
import { DragAndDropWrapper } from '../../../HOCs/DragAndDrop/DragAndDrop.css';

const theme: any = getTheme();

export const HeaderValueInput = styled.div<{ $twoInputs?: boolean; $error?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    ${({ $twoInputs, $error }) =>
        $twoInputs &&
        `background: ${theme.palette.white.main}; border: 1px solid ${
            $error ? theme.palette.error.main : theme.palette.background.border
        }; border-radius: 4px;`}
    & > div,
    .MuiFormControl-root {
        min-height: unset;
        margin: 0;
        width: auto;
        .MuiInputBase-root {
            ${({ $twoInputs }) => $twoInputs && 'border: none;'}
        }
    }
    .MuiFormControl-root .MuiInputBase-root input {
        padding-right: 0;
    }
    .MuiIconButton-root {
        padding-left: 2px;
    }
    .MuiInputAdornment-positionEnd,
    .MuiInputAdornment-positionStart {
        svg {
            width: 14px;
            height: 14px;
        }
    }
    p.MuiFormHelperText-root {
        display: none;
    }
    ${SelectWrapper} {
        width: 208px;
        label {
            display: none;
        }
    }

    ${FileFieldContainer} {
        width: 208px;
        div {
            ${({ $error }) => $error && `border-color: ${theme.palette.error.main};`}
        }
    }
    ${FileField} {
        height: 36px;
        justify-content: start;
        svg {
            width: 14px;
            height: 14px;
        }
    }
    ${FileItem} {
        border: 1px solid ${theme.palette.background.border};
        background: unset;
        padding: 0 8px;
        margin: 0;
        svg {
            width: 14px;
            height: 14px;
        }
    }
    ${DragAndDropWrapper} {
        border: 1px solid ${theme.palette.background.border};
        height: 36px;
        width: 208px;
    }
`;

export const BaseHeaderValueCard = styled(TemplateCard)<{ $checked?: boolean }>`
    position: relative;
    border: 1px solid ${({ $checked }) => ($checked ? theme.palette.black.main : theme.palette.background.border)};
    flex-grow: 1;
    max-width: calc(33% - 8px);
    height: unset;
`;
export const HeaderValueCardName = styled.div`
    ${fontSizesCSS.Caption2}
`;

export const HeaderValueCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-height: 320px;
    overflow: auto;
    padding-bottom: 10px;
    padding-right: 10px;
    width: calc(100% + 8px);
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.background.border};
        width: 8px;
        height: 72px;
        border-radius: 8px;
    }
`;

export const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AudienceNameWrapper = styled.div`
    width: 340px;
`;

export const KeyFilterDropdown = styled.div`
    width: 200px;
`;

export const HeaderValueCardSubtitle = styled.div<{ $error?: boolean }>`
    ${fontSizesCSS.Caption3};
    ${({ $error }) => $error && `color: ${theme.palette.error.main};`}
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
    overflow: hidden;
`;
export const HeaderValueIcon = styled.div<{ $backgroundColor?: string }>`
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;

    border-radius: 4px;
    background: ${({ $backgroundColor }) => $backgroundColor || theme.palette.primary.main};
`;
export const HeaderValueCheckboxContainer = styled.div`
    margin-left: auto;
    a {
        margin: 0;
        svg {
            padding: 0;
            width: 24px;
            height: 24px;
        }
    }
`;

export const WarningNoteWrapper = styled.div`
    margin-top: 16px;
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.background.hovered};
`;
