import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Audience } from '../../types/Audience';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchAudiencesByTargetGroupId = createAsyncThunk<
    { audiences: Audience[]; error: { message: string; code: string } | null },
    any | undefined
>('audiences/fetchAudiencesByTargetGroupId', async (id, thunkApi) => {
    const result = await ConfigServiceAPI.getAudiencesByTargetGroupId(id);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { audiences: result.response as any, error: null, permissions: result.permissions };
});

export const fetchAudiences = createAsyncThunk<
    { audiences: Audience[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; projectId?: string; orderBy?: string; searchTerm?: string }
>('audiences/fetchAudiences', async ({ addPermissions, projectId, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllAudiences(addPermissions, projectId, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { audiences: result.response as any, error: null, permissions: result.permissions };
});

export const fetchAudience = createAsyncThunk<{ audience: Audience; error: { message: string; code: string } | null }, string>(
    'audiences/fetchAudience',
    async (audienceId, thunkApi) => {
        const result = await ConfigServiceAPI.getAudienceById(audienceId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { audience: result.response as any as Audience, error: null };
    }
);

export const createAudience = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { audience: Audience; addAudienceToGroupObjects?: boolean }
>('audiences/createAudience', async ({ audience, addAudienceToGroupObjects }, thunkApi) => {
    const result = await ConfigServiceAPI.createAudience(audience, addAudienceToGroupObjects);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any as string, error: null };
});

export const updateAudience = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { audience: Audience; shouldUnlockAfterSave?: boolean; addAudienceToGroupObjects?: boolean }
>('audiences/updateAudience', async ({ audience, shouldUnlockAfterSave, addAudienceToGroupObjects }, thunkApi) => {
    const result = await ConfigServiceAPI.updateAudience(audience, shouldUnlockAfterSave, addAudienceToGroupObjects);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteAudience = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'audiences/deleteAudience',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteAudience(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface audiencesState {
    audiences: Audience[];
    groupAudiences: Audience[];
    loading: boolean;
    groupAudiencesLoading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: audiencesState = {
    audiences: [],
    groupAudiences: [],
    loading: false,
    groupAudiencesLoading: false,
    error: null
};

const slice = createSlice({
    name: 'audiences',
    initialState,
    reducers: {
        unsetAudiences(state) {
            state.audiences = [];
        },
        unsetAudienceError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAudiencesByTargetGroupId.fulfilled, (state, action: any) => {
                state.groupAudiences = action.payload.audiences;
                state.error = null;
                state.groupAudiencesLoading = false;
            })
            .addCase(fetchAudiencesByTargetGroupId.rejected, (state, action: any) => {
                state.groupAudiences = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.groupAudiencesLoading = false;
            })
            .addCase(fetchAudiencesByTargetGroupId.pending, (state, _action) => {
                state.groupAudiencesLoading = true;
            })
            .addCase(fetchAudiences.fulfilled, (state, action: any) => {
                state.audiences = action.payload.audiences;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAudiences.rejected, (state, action: any) => {
                state.audiences = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAudiences.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAudience.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createAudience.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAudience.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateAudience.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateAudience.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateAudience.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAudience.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteAudience.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAudience.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAudiences, unsetAudienceError } = slice.actions;

export default slice.reducer;
