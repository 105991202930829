import React, { FC, useEffect, useState } from 'react';
import { DialogCheckbox, DialogDropdownSingle, DialogTextField, DialogToggleButton } from '../../../common/Dialog/GenericDialog';
import { Template, TemplateDefaultValues, templateType, templateTypes } from '../../../../types/Template';
import { generateUID } from '../../../../utils/fnGenerator';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { ContentSourceLabelContainer } from '../../../Items/Items.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../style';
import { contentSourceTypes, itemTypes } from '../../../../types/Item';
import { validator } from '../../../../utils/fnValidator';
import _ from 'lodash';
import { createTemplate, fetchTemplates, templatesState } from '../../../../redux/slices/templatesSlice';
import { DIALOG_NAMES, ToastAlert } from '../../../../utils/fnDialogs';
import { ItemState, fetchContentSourceTypes } from '../../../../redux/slices/itemSlice';
import ObjectNameTooltip from '../../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../../../../types/Object';
import { renderAutoCollectionAlertDialog, renderIncludeProviderCardLogoAlertDialog } from '../../../Items/Dialogs/NewItem';
import { OptionEntry, OptionsContainer, OptionSection, OptionsToggle, SingleAssetContainer } from '../../../Modules/Dialogs/NewModule.css';
import { NewTemplate } from '../Common/NewTemplate';
import { NewSourceTemplateDialog } from '../Sources/NewSource';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';

export type NewTemplateProps = {
    open: boolean;
    templateType?: templateType;
    template?: Template;
    onClose: () => void;
    onSave: (arg: Template, removeAutoCollection?: boolean, removeIncludeProviderCardLogo?: boolean) => void;
    onRelease: (id: string) => void;
    onRetract: (id: string) => void;
};

type NewItemTemplateProps = NewTemplateProps & {
    item?: Template;
    module?: Template;
    isCollection?: boolean;
    collectionType?: itemTypes;
    includeProviderLogoCardProviderName?: string;
    isContentWorldModule?: boolean;
};

export const NewItemTemplate: FC<NewItemTemplateProps> = ({
    open,
    item,
    module,
    isCollection,
    collectionType,
    includeProviderLogoCardProviderName,
    isContentWorldModule,
    onSave,
    onClose,
    onRelease,
    onRetract
}) => {
    const dispatch = useDispatch();
    const { contentSourceTypes, loading: contentSourceTypesLoading }: ItemState = useAppSelector((state) => state.items);
    const { templateTypeLoading, templates }: templatesState = useAppSelector((state) => state.templates);
    const { dynamicSource: sourcesLoading } = templateTypeLoading;
    const { dynamicSource: sources } = templates;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [contentSourceType, setContentSourceType] = useState<contentSourceTypes | undefined>(undefined);
    const [contentSourceTypeOptions, setContentSourceTypeOptions] = useState<any[]>([]);
    const [itemType, setItemType] = useState<itemTypes | undefined>(undefined);
    const [errors, setErrors] = useState<{ name?: string; icon?: string; contentSourceType?: string; singleAsset?: string }>({});
    const [showSingleAsset, setShowSingleAsset] = useState<boolean>(false);
    const [videoAutoplay, setVideoAutoplay] = useState<boolean>(false);
    const [singleAsset, setSingleAsset] = useState<string>('');
    const [dynamicSourceTemplateId, setDynamicSourceTemplateId] = useState<string>('');
    const [maxNumberOfItems, setMaxNumberOfItems] = useState<string>('');
    const [dynamicSourceOptions, setDynamicSourceOptions] = useState<any[]>([]);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [newSourceDialogOpen, setNewSourceDialogOpen] = useState<boolean>(false);
    const [newSourceId, setNewSourceId] = useState<string>('');

    const moduleItemsNumber = module?.itemTemplates?.length || 0;
    const shouldRenderAutoCollectionAlert =
        (moduleItemsNumber > (item ? 1 : 0) && module?.values?.autoCollection) ||
        (moduleItemsNumber === 0 && module?.values?.autoCollection && itemType === itemTypes.EDITORIAL);

    const shouldRenderIncludeProviderCardLogoAlert =
        !!includeProviderLogoCardProviderName &&
        itemType === itemTypes.DYNAMIC &&
        contentSourceType !== includeProviderLogoCardProviderName;

    useEffect(() => {
        const options =
            (collectionType && collectionType === itemTypes.DYNAMIC) || (item && item.values.itemType === itemTypes.DYNAMIC)
                ? []
                : [
                      {
                          value: 'editorial',
                          label: (
                              <ContentSourceLabelContainer>
                                  <SVGInline src={icons.editorialIcon} />
                                  <span>Editorial</span>
                              </ContentSourceLabelContainer>
                          ),
                          valueForSearch: 'Editorial'
                      }
                  ];

        if (sources?.length && contentSourceTypes?.length && collectionType !== itemTypes.EDITORIAL && !isContentWorldModule) {
            options.push(
                ...contentSourceTypes.map((source) => ({
                    value: source.value,
                    label: (
                        <ContentSourceLabelContainer>
                            <SVGInline src={icons.dynamicIcon} />
                            <span>{source.title}</span>
                        </ContentSourceLabelContainer>
                    ),
                    valueForSearch: source.title
                }))
            );
        }
        setContentSourceTypeOptions(options);
        setIsOpen(open);
    }, [open, contentSourceTypes, item, sources, isContentWorldModule]);

    useEffect(() => {
        if (!open) return;
        !contentSourceTypes?.length && loadContentSourceTypes();
        !sources?.length && loadSources();
    }, [open]);

    useEffect(() => {
        if (!sources) return setDynamicSourceOptions([]);
        const sourcesToShow = sources.filter((source) => source.values?.service === contentSourceType);

        setDynamicSourceOptions(
            sourcesToShow.map((source) => {
                return {
                    value: source._id,
                    valueForSearch: source.values.name,
                    label: <ObjectNameTooltip id={source._id} name={source.values.name} type={ObjectTypes.DYNAMIC_SOURCES} />
                };
            })
        );
        if (newSourceId && sourcesToShow.find((source) => source._id === newSourceId)) {
            setDynamicSourceTemplateId(newSourceId);
        }
    }, [sources, contentSourceType]);

    useEffect(() => {
        if (!item) return;

        const sourceType =
            contentSourceTypes?.find((sourceType) => sourceType.value === item.values?.contentSourceType)?.value || undefined;
        setName(item.values?.name || '');
        setItemType(item.values?.itemType || itemTypes.EDITORIAL);
        setContentSourceType(sourceType);
        setDynamicSourceTemplateId(item.values?.dynamicSourceTemplateId || '');
        setMaxNumberOfItems(item.values?.maxNumberOfItems ? `${item.values?.maxNumberOfItems}` : '');
        setShowSingleAsset(!!item.values.singleAsset || false);
        setVideoAutoplay(!!item.values?.videoAutoplay);
        setSingleAsset(item.values.singleAsset || '');
        setShowOptions(!!item.values?.singleAsset || !!item.values?.videoAutoplay);
    }, [item, contentSourceTypes]);

    const loadSources = async () => {
        await dispatch(fetchTemplates({ type: templateTypes.DYNAMIC_SOURCE })).unwrap();
    };

    const loadContentSourceTypes = async () => {
        return await dispatch(fetchContentSourceTypes({ projectId: '' }));
    };

    const createSourceTemplate = async (source: Template) => {
        const response = await dispatch(createTemplate(source)).unwrap();
        if (response.id) {
            setNewSourceId(response.id);
            loadSources();
        }
    };

    const onReleaseDialog = () => {
        if (!item) return;
        onRelease(item._id);
    };

    const onRetractDialog = () => {
        if (!item) return;
        onRetract(item._id);
    };

    const validateNewItem = (values: TemplateDefaultValues) => {
        const { icon } = values;
        const newErrors = { ...errors };
        newErrors.contentSourceType = validator(
            { required: true },
            `${contentSourceType !== undefined ? contentSourceType : itemType === itemTypes.EDITORIAL ? '-1' : ''}`
        );
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.icon = validator({ required: true, minLength: 3 }, icon);
        if (showSingleAsset) {
            newErrors.singleAsset = validator({ required: true }, singleAsset);
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const onSaveDialog = (values: TemplateDefaultValues) => {
        const { icon, iconBackground, tags, tenantIds, projectIds } = values;
        if (!validateNewItem(values)) return;

        if ((collectionType && itemType !== collectionType) || (isContentWorldModule && itemType === itemTypes.DYNAMIC)) return;

        if (shouldRenderAutoCollectionAlert) {
            ToastAlert(
                'critical_warning',
                '',
                '',
                undefined,
                DIALOG_NAMES.DYNAMIC_ITEM_AUTOCOLLECTION,
                () => {
                    renderAutoCollectionAlertDialog();
                },
                shouldRenderIncludeProviderCardLogoAlert
                    ? () => {
                          ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                              renderIncludeProviderCardLogoAlertDialog();
                          });
                      }
                    : undefined
            );
        }

        if (shouldRenderIncludeProviderCardLogoAlert && !shouldRenderAutoCollectionAlert) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                renderIncludeProviderCardLogoAlertDialog();
            });
        }

        const newItem: Template = {
            _id: item?._id || generateUID(),
            type: templateTypes.ITEM,
            tenantIds,
            projectIds,
            icon,
            iconBackground,
            values: {
                ...item?.values,
                name: name,
                itemType,
                videoAutoplay,
                ...(itemType === itemTypes.DYNAMIC && {
                    maxNumberOfItems: maxNumberOfItems ? parseInt(maxNumberOfItems) : undefined,
                    contentSourceType,
                    dynamicSourceTemplateId
                }),
                ...(itemType === itemTypes.DYNAMIC && showSingleAsset ? { singleAsset: singleAsset } : { singleAsset: '' })
            },
            audienceValues: item?.audienceValues || [],
            lastModified: item?.lastModified,
            tags
        };

        onSave(newItem, shouldRenderAutoCollectionAlert, shouldRenderAutoCollectionAlert);
        //only on modules page we should close the dialog after save
        module && onCloseDialog();
    };

    const onCloseDialog = () => {
        setName('');
        setErrors({});
        setDynamicSourceTemplateId('');
        setContentSourceType(undefined);
        setMaxNumberOfItems('');
        setShowSingleAsset(false);
        setVideoAutoplay(false);
        setSingleAsset('');
        setShowOptions(false);
        setItemType(undefined);
        setNewSourceId('');
        onClose();
    };

    if (!isOpen) return null;

    const disabledContentSource = item && item.values.itemType === itemTypes.EDITORIAL;

    const warnings: any[] = [];
    if (shouldRenderAutoCollectionAlert) {
        warnings.push({ icon: icons.warningIcon, critical: true, onClick: () => renderAutoCollectionAlertDialog() });
    }

    if (shouldRenderIncludeProviderCardLogoAlert) {
        warnings.push({ icon: icons.infoIcon, onClick: () => renderIncludeProviderCardLogoAlertDialog() });
    }

    const renderOptions = () => {
        return (
            <OptionsContainer>
                <OptionEntry>
                    <DialogCheckbox
                        onClick={() => setVideoAutoplay(!videoAutoplay)}
                        text={'Video Auto-Play'}
                        value={videoAutoplay}
                        active={videoAutoplay}
                    />
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'item_video_autoplay_checkbox')}
                </OptionEntry>
                {itemType === itemTypes.DYNAMIC && (
                    <>
                        <OptionEntry>
                            <DialogToggleButton
                                checked={showSingleAsset}
                                toggleCallback={() => {
                                    const newValue = !showSingleAsset;
                                    // if single asset is turned on, set max number of items to nothing, otherwise set singleAsset id to empty
                                    newValue ? setMaxNumberOfItems('') : setSingleAsset('');
                                    setErrors(_.omit(errors, 'singleAsset'));
                                    setShowSingleAsset(newValue);
                                }}
                                text={'Single Asset'}
                                tooltipText={'item_single_asset_checkbox'}
                            />
                            {showSingleAsset && (
                                <SingleAssetContainer>
                                    <DialogTextField
                                        placeholder={'Enter Asset id for the single asset'}
                                        label={'Select Asset Id'}
                                        value={singleAsset}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setSingleAsset(e.target.value);
                                            setErrors(_.omit(errors, 'singleAsset'));
                                        }}
                                        error={errors.singleAsset}
                                    />
                                </SingleAssetContainer>
                            )}
                        </OptionEntry>
                    </>
                )}
            </OptionsContainer>
        );
    };

    const children = (
        <>
            <DialogTextField
                value={name}
                error={errors.name}
                onChange={(e: any) => {
                    setName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                placeholder={'Name'}
                label={'Name'}
            />
            <DialogDropdownSingle
                value={
                    contentSourceTypeOptions.find((opt) => opt.value === contentSourceType) ||
                    (itemType === itemTypes.EDITORIAL && contentSourceTypeOptions.find((option) => option.value === itemTypes.EDITORIAL))
                }
                options={contentSourceTypeOptions}
                onChange={(value: any) => {
                    setContentSourceType(value.value === itemTypes.EDITORIAL ? undefined : value.value);
                    setItemType(value.value === itemTypes.EDITORIAL ? itemTypes.EDITORIAL : itemTypes.DYNAMIC);
                    setDynamicSourceTemplateId('');
                    setShowSingleAsset(false);
                    setSingleAsset('');
                    setErrors(_.omit(errors, 'contentSourceType'));
                }}
                placeholder={'Content Source'}
                labelText={'Content Source'}
                isDisabled={contentSourceTypesLoading || disabledContentSource}
                toolTipText={
                    isCollection
                        ? 'The collection can be composed of either one item with a Dynamic Source or multiple Editorial items'
                        : disabledContentSource
                        ? `An Item's Content Source can not be changed from editorial to a dynamic one`
                        : undefined
                }
                error={errors?.contentSourceType}
            />

            {!!contentSourceType && itemType !== itemTypes.EDITORIAL && (
                <>
                    <DialogDropdownSingle
                        placeholder={'Select Source'}
                        options={dynamicSourceOptions}
                        value={dynamicSourceOptions.find((source) => source.value === dynamicSourceTemplateId) || ''}
                        onChange={(value: any) => {
                            setShowSingleAsset(false);
                            setSingleAsset('');
                            setDynamicSourceTemplateId(value.value);
                        }}
                        isDisabled={sourcesLoading}
                        newOption={{ name: 'Dynamic Source Template', onClick: () => setNewSourceDialogOpen(true) }}
                    />

                    <DialogTextField
                        value={maxNumberOfItems}
                        onChange={(e: any) => {
                            if (e.target.value.length > 3) return;
                            setMaxNumberOfItems(e.target.value.replace(/[^0-9]/, ''));
                        }}
                        label={'Maximum Number of Items: (Unlimited if nothing set)'}
                        isDisabled={showSingleAsset}
                    />
                </>
            )}

            <OptionSection $notLastElement>
                <OptionsToggle onClick={() => setShowOptions(!showOptions)}>
                    Options <SVGInline src={showOptions ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </OptionsToggle>
                {showOptions && renderOptions()}
            </OptionSection>
        </>
    );

    const extraValues = {
        name,
        videoAutoplay,
        ...(itemType === itemTypes.DYNAMIC && { dynamicSourceTemplateId, contentSourceType, singleAsset, maxNumberOfItems })
    };

    return (
        <NewTemplate
            template={item}
            templateType={templateTypes.ITEM}
            onRelease={onReleaseDialog}
            onRetract={onRetractDialog}
            onClose={onCloseDialog}
            onSave={onSaveDialog}
            open={open}
            extraValues={extraValues}
            errors={errors}
            setErrors={setErrors}
            warnings={warnings}
        >
            {children}

            <NewSourceTemplateDialog
                open={newSourceDialogOpen}
                onClose={() => {
                    setNewSourceDialogOpen(false);
                }}
                onSave={(template) => {
                    createSourceTemplate(template);
                }}
                onRelease={(_id) => {}}
                onRetract={(_id) => {}}
                preselectedService={contentSourceType}
            />
        </NewTemplate>
    );
};
