import styled, { CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';

const theme: any = getTheme();

// TODO: Remove this file and use the one from the common folder
export const TemplateHeaderStyle: CSSProperties = {};

export const ContentTemplateLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        width: 40px;
        height: 16px;
    }
    div {
        ${fontSizesCSS.Caption4};
        white-space: nowrap;
    }
`;

export const ContentTemplateName = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const TooltipDivider = styled.span`
    width: 1px;
    height: 16px;
    margin: 0 10px;
    background-color: ${theme.palette.background.innerBorder};
`;

export const NameWrapper = styled.div`
    color: ${theme.palette.primary.main};
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
`;

export const RemoveModuleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
    svg {
        width: 40px;
        height: 40px;
    }
`;
