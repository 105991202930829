import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { ObjectActivity, ObjectType } from '../../types/Object';

export const getObjectActivity = createAsyncThunk<
    { objectActivity: ObjectActivity[]; error: { message: string; code: string } | null },
    { objectIds: string[]; objectType: ObjectType; withObjectName?: boolean }
>('objectActivity/getObjectActivity', async ({ objectIds, objectType, withObjectName }, thunkApi) => {
    let result;

    result = await ConfigServiceAPI.getObjectActivity(objectIds, objectType, withObjectName);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { objectActivity: result.response as any, error: null };
});

export interface ObjectActivityState {
    loading: boolean;
    objectActivity: ObjectActivity[];

    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: ObjectActivityState = {
    loading: false,
    error: null,
    objectActivity: []
};

const slice = createSlice({
    name: 'objectActivity',
    initialState,
    reducers: {
        unsetObjectActivity: (state) => {
            state.objectActivity = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getObjectActivity.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
                state.objectActivity = _action.payload.objectActivity;
            })
            .addCase(getObjectActivity.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.objectActivity = [];
                state.loading = false;
            })
            .addCase(getObjectActivity.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetObjectActivity } = slice.actions;
export default slice.reducer;
