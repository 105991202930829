import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const config = JSON.parse(atob(`${process.env.REACT_APP_FIREBASE_CONFIG}`));

const app = initializeApp(config);
const analytics = getAnalytics(app);

export default {
    app,
    analytics
};
