import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { WidthTableCell } from '../../../common/Table/Table.css';
import GenericTable, { ActionsTableCell, SortableTableCell, tableActions } from '../../../common/Table/Table';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import Sidebar from '../../Sidebar/Sidebar';
import { ResourceCard } from '../../../Cards/ResourceCard/ResourceCard';
import { itemTypes } from '../../../../types/Item';
import { GroupField, GroupFieldsContainer } from '../../../TargetGroups/GroupEdit.css';
import { NewItemTemplate } from './NewItem';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    createTemplate as _createTemplate,
    deleteTemplate as _deleteTemplate,
    fetchTemplates,
    fetchTemplatesTags,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { parseItemTemplate, Template, templateTypes } from '../../../../types/Template';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import { PUBLISHED_STATUS } from '../../../../utils/fnPublish';
import { EditorialTableRow } from '../../../Items/EditorialView.css';
import { Actions, EditorialView } from '../../../Items/EditorialView';
import { TemplateImage, TemplateImageWrapper, TemplateName, TemplatesWrapper } from '../Templates.css';
import { DIALOG_NAMES, dialogConfirm } from '../../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import { Hint, HINT_TYPES } from '../../../common/Hints/Hint';
import useScreenSize from '../../../../hooks/useScreenSize';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../../../utils/fnSort';
import { Loader } from '../../../common/Loader/Loader';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import circleSlugs from '../../../../utils/circle_slugs.json';
import { MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';
import { openDocumentation } from '../../../../utils/parsers';
import _ from 'lodash';
import { PageRoutes } from '../../../../types/RouteTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ItemsTemplatesTableSizes, PagesTableSizes } from '../../../../types/TableSizes';
import Labels from '../../../common/Labels/Labels';
import PaginationWrapper, { ResetCallbackProps } from '../../../PaginationWrapper/PaginationWrapper';
import { ObjectFilter } from '../../../../utils/fnFilter';
import { fetchContentSourceTypes, ItemState } from '../../../../redux/slices/itemSlice';
import { toggleOpenSearchBar, unsetSearchTerm } from '../../../../redux/slices/searchSlice';

export const itemsTemplates: FC = () => {
    const {
        templates: storeTemplates,
        templateTypeLoading,
        error,
        totalResults
    }: templatesState = useAppSelector((state) => state.templates);

    const { contentSourceTypes }: ItemState = useAppSelector((state) => state.items);
    const { isMobile, isDesktop } = useScreenSize();

    const [itemsTemplates, setItemsTemplates] = useState<any[]>([]);
    const [openNewTemplate, setOpenNewTemplate] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<'SYSTEM_TEMPLATES' | 'USER_SUGGESTIONS'>('SYSTEM_TEMPLATES');
    const [openEditViewItemId, setOpenEditViewItemId] = useState<string>('');
    const [itemToEdit, setItemToEdit] = useState<Template | undefined>(undefined);
    const [itemToUpdate, setItemToUpdate] = useState<any>(null);
    const [showHintScreen, setShowHintScreen] = useState<boolean>(false);
    const [newId, setNewId] = useState<string>('');

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);
    const loading = templateTypeLoading.item;
    const orderBy = calculateOrderByFromSortConfig(sortConfig);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.currentPage && setCurrentPage(1);
        !keep?.searchTerm && setSearchTerm(undefined);
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        if (!keep?.visualEditor) {
            setOpenEditViewItemId('');
        }
        setActiveObjectFilter(undefined);
    };

    const onSearchTermOrPaginationChange = (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setOpenEditViewItemId('');
        loadTemplates(pageSize, currentPage, orderBy, searchTerm, filter);
    };

    const loadTemplates = async (pageSize: number, pageNumber: number, orderBy: string, searchTerm?: string, filter?: ObjectFilter) => {
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm(undefined);
        }
        await dispatch(
            fetchTemplates({
                type: templateTypes.ITEM,
                pageSize,
                pageNumber,
                orderBy,
                ...(!isRedirected && { searchTerm }),
                ...(!isRedirected && { filter })
            })
        ).unwrap();
    };

    const loadTemplatesTags = async () => {
        await dispatch(fetchTemplatesTags()).unwrap();
    };

    const loadContentSourceTypes = async () => {
        return await dispatch(fetchContentSourceTypes({ projectId: '' }));
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (response.id) {
            resetCallback();
            loadTemplates(pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
            setNewId(response.id);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            //after updating an item we have to reset the sorting to default
            resetCallback({ visualEditor: true, searchTerm: true });
            loadTemplates(pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), searchTerm);
        }
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.ITEM })).unwrap();
        if (response) {
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true });
            loadTemplates(pageSize, currentPage, orderBy, searchTerm);
        }
    };

    const releaseTemplate = async (id: string) => {
        const response = await dispatch(_releaseTemplate({ templateId: id, type: templateTypes.ITEM })).unwrap();
        if (response) {
            resetCallback();
            loadTemplates(pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        }
    };

    const retractTemplate = async (id: string) => {
        const response = await dispatch(_retractTemplate({ templateId: id, type: templateTypes.ITEM })).unwrap();
        if (response) {
            resetCallback();
            loadTemplates(pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        }
    };

    useEffect(() => {
        const _totalResults = totalResults?.item;
        setTotalPages(_totalResults !== undefined ? Math.ceil(_totalResults / pageSize) : 1);
    }, [pageSize, totalResults]);

    useEffect(() => {
        let filter: ObjectFilter = {};
        const itemId = !_.isEmpty(location.state) && location.state?.itemId;
        if (itemId) {
            filter._id = [itemId];
            setActiveObjectFilter(filter);
        }

        loadTemplates(pageSize, currentPage, orderBy, searchTerm, filter);
        loadTemplatesTags();
        !contentSourceTypes?.length && loadContentSourceTypes();

        return () => {
            dispatch(unsetSearchTerm());
            dispatch(toggleOpenSearchBar(false));
        };
    }, []);

    useEffect(() => {
        !isDesktop && setOpenEditViewItemId('');
    }, [isDesktop]);

    const renderEditingAlertDialog = () => {
        return dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => updateTemplate(itemToUpdate),
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                setOpenEditViewItemId('');
                setItemToUpdate(null);
            },
            true
        );
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Item Template',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                deleteTemplate(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Item Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    useEffect(() => {
        if (loading) return;

        setItemsTemplates(storeTemplates.item || []);

        const itemId = location?.state?.itemId;
        const item = storeTemplates.item?.find((ci) => ci._id === itemId);
        if (itemId && storeTemplates.item?.length && item) {
            const itemName = item?.values.name;
            setOpenEditViewItemId(itemId);
            setSearchTerm(itemName);
            navigate(location.pathname, { state: { itemId: undefined }, replace: true });
        }
    }, [loading]);

    useEffect(() => {
        if (!storeTemplates?.item?.length) return;
        if (itemToEdit) return setItemToEdit(storeTemplates?.item?.find((template) => template._id === itemToEdit._id));

        if (newId && openNewTemplate) {
            setItemToEdit(storeTemplates?.item?.find((template) => template._id === newId));
            setNewId('');
        }
    }, [storeTemplates?.item]);

    const handleSortIconClick = (field: AcceptedSortField) => {
        setShowSortArrows(false);
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        const orderBy = calculateOrderByFromSortConfig(config);
        setSortConfig(config);
        setCurrentPage(1);
        loadTemplates(pageSize, 1, orderBy, searchTerm);
        // make sure sort arrows don't remain open after reloading
        setShowSortArrows(false);
        setOpenEditViewItemId('');
    };

    const renderVisualEditor = (item: Template) => {
        const { _id } = item;

        if (openEditViewItemId !== _id) return;
        const _item = parseItemTemplate(item);
        return (
            <EditorialTableRow>
                <TableCell colSpan={9}>
                    <EditorialView
                        type={_item.itemType}
                        item={_item.itemType === itemTypes.EDITORIAL ? item.values : _item}
                        onEdit={() => {
                            setOpenNewTemplate(true);
                            setItemToEdit(item);
                        }}
                        onRemove={() => {
                            handleDeleteClick(item._id);
                        }}
                        onSave={() => {
                            if (!itemToUpdate) return;
                            updateTemplate(itemToUpdate);
                        }}
                        onCancel={() => {
                            !_.isEmpty(itemToUpdate?.values) ? renderEditingAlertDialog() : setOpenEditViewItemId('');
                        }}
                        setItemToSave={(arg) => {
                            setItemToUpdate({ ...item, values: { ...arg } });
                        }}
                        isTemplate
                        itemActions={[Actions.EDIT, Actions.REMOVE]}
                    />
                </TableCell>
            </EditorialTableRow>
        );
    };

    const buildTableColumns = () => {
        const columns = [
            <WidthTableCell key={`icon_cell`} {...ItemsTemplatesTableSizes['icon']}>
                {'Icon'}
            </WidthTableCell>,
            <SortableTableCell
                key={`name_cell`}
                text={'List Or Item Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ItemsTemplatesTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <SortableTableCell
                key={`content_source_cell`}
                text={'Content Source'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.contentSourceType}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.contentSourceType)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ItemsTemplatesTableSizes['source']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.contentSourceType && sortConfig?.direction) || 'asc'}
            />,
            <WidthTableCell key={'placed_cell'} {...ItemsTemplatesTableSizes.placed}>
                {'Connected To'}
            </WidthTableCell>,
            <SortableTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={PagesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        isMobile && columns.splice(4, 1); // remove last modified column if is mobile view
        return columns;
    };

    const buildTableBody = () => {
        const rows = itemsTemplates.map((item) => {
            const lastModified = generateDateStringForTables(item?.lastModified || 0);
            const publishedStatus = item?.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED;
            const contentSource =
                item.values.itemType === itemTypes.DYNAMIC
                    ? contentSourceTypes?.find(
                          (source) =>
                              source.value === item?.dynamicSourceTemplate?.values?.service || source.value === item.contentSourceType
                      )
                    : undefined;

            return (
                <>
                    <TableRow
                        onClick={() => {
                            if (!isDesktop) {
                                return setShowHintScreen(true);
                            }
                            navigate(location.pathname, { state: { itemId: undefined }, replace: true });
                            setOpenEditViewItemId(item._id);
                        }}
                    >
                        {/* ICON TABLE CELL */}
                        <WidthTableCell {...ItemsTemplatesTableSizes.icon}>
                            <TemplateImageWrapper $background={item?.iconBackground}>
                                <TemplateImage src={item?.icon} />
                            </TemplateImageWrapper>
                        </WidthTableCell>

                        {/*  ITEM NAME TABLE CELL */}
                        <WidthTableCell {...ItemsTemplatesTableSizes.name}>
                            <TemplateName>
                                <TruncatedText>{item.values?.name}</TruncatedText>
                            </TemplateName>
                        </WidthTableCell>

                        {/* CONTENT SOURCE TABLE CELL */}
                        <WidthTableCell {...ItemsTemplatesTableSizes.source}>
                            <TruncatedText>{contentSource?.title || _.capitalize(item.values.itemType)}</TruncatedText>
                        </WidthTableCell>

                        {/* CONNECTED TO TABLE CELL */}
                        <WidthTableCell {...ItemsTemplatesTableSizes.placed}>
                            <Labels
                                values={item.placed || []}
                                type={MoreInfoTypes.CONNECTED_TO}
                                noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                                onClickLabel={(obj) => {
                                    navigate(PageRoutes.SUPERADMIN_MODULES, { state: { moduleId: obj._id } });
                                }}
                            />
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        {!isMobile && <WidthTableCell {...ItemsTemplatesTableSizes.lastModified}>{lastModified}</WidthTableCell>}

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell {...ItemsTemplatesTableSizes.actions}>
                            <ActionsTableCell
                                actions={[tableActions.EDIT, tableActions.REMOVE]}
                                onEdit={(e: React.MouseEvent<any>) => {
                                    setOpenNewTemplate(true);
                                    setItemToEdit(item);
                                    e.stopPropagation();
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    handleDeleteClick(item._id);
                                    e.stopPropagation();
                                }}
                                tooltipTexts={{
                                    edit: 'superadmin_items_templates_icon_edit',
                                    delete: 'superadmin_items_templates_icon_delete',
                                    published: item?.released
                                        ? 'superadmin_items_templates_icon_released'
                                        : 'superadmin_items_templates_icon_unreleased'
                                }}
                                publishedStatus={publishedStatus}
                            />
                        </WidthTableCell>
                    </TableRow>
                    {renderVisualEditor(item)}
                </>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderItemsTable = () => {
        return <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'superAdmin-items-table'} />;
    };

    const renderNoItems = () => {
        return (
            <NoResourcesContainer>
                <ResourceCard
                    title={'Create a new Item Template'}
                    subtitle={'Create a new item template from scratch'}
                    primaryButtonLabel={'Create Item Template'}
                    secondaryButtonLabel={'Learn More'}
                    onPrimaryButtonClick={() => setOpenNewTemplate(true)}
                    onSecondaryButtonClick={() => openDocumentation(circleSlugs.templates)}
                />
            </NoResourcesContainer>
        );
    };

    const renderSystemTemplates = () => {
        return (
            <TemplatesWrapper>
                {itemsTemplates.length ? (
                    <>
                        {renderItemsTable()}{' '}
                        <PageActionsWrapper>
                            <PageActionButton onClick={() => setOpenNewTemplate(true)} label={'Create Item Template'} type={'BLUE'} />
                        </PageActionsWrapper>{' '}
                    </>
                ) : (
                    renderNoItems()
                )}
            </TemplatesWrapper>
        );
    };

    const renderUserSuggestions = () => {
        return <></>;
    };

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        title={'Items Templates'}
                        withProfile
                        withAddButton
                        withoutSearch
                        addLabel={'Create Item Template'}
                        loading={templateTypeLoading.item}
                        onAdd={() => {
                            setOpenNewTemplate(true);
                        }}
                    />
                    <GroupFieldsContainer withBottomMargin>
                        <GroupField onClick={() => setActiveTab('SYSTEM_TEMPLATES')} $active={activeTab === 'SYSTEM_TEMPLATES'}>
                            System Templates
                        </GroupField>
                        <GroupField
                            onClick={() => {
                                setActiveTab('USER_SUGGESTIONS');
                            }}
                            $active={activeTab === 'USER_SUGGESTIONS'}
                            $disabled
                        >
                            User Suggestions
                        </GroupField>
                    </GroupFieldsContainer>
                    {loading ? (
                        <Loader />
                    ) : (
                        <PaginationWrapper
                            orderBy={orderBy}
                            totalPages={totalPages}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            activeObjectFilter={activeObjectFilter}
                            onSearchTermOrPaginationChange={onSearchTermOrPaginationChange}
                            resetCallback={resetCallback}
                            extraPadding
                        >
                            {activeTab === 'SYSTEM_TEMPLATES' ? renderSystemTemplates() : renderUserSuggestions()}
                        </PaginationWrapper>
                    )}

                    <NewItemTemplate
                        open={openNewTemplate}
                        item={itemToEdit}
                        onClose={() => {
                            setOpenNewTemplate(false);
                            setItemToEdit(undefined);
                        }}
                        onSave={(template) => (itemToEdit ? updateTemplate(template) : createTemplate(template))}
                        onRelease={(id) => {
                            releaseTemplate(id);
                        }}
                        onRetract={(id) => {
                            retractTemplate(id);
                        }}
                    />
                    <Hint type={HINT_TYPES.SCREEN_SIZE_CIS} showHint={showHintScreen} onHide={() => setShowHintScreen(false)} />
                </MainContentWrapper>
            </ApplicationWrapper>{' '}
        </>
    );
};
